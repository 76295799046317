import {
  __,
  ceil,
  compact,
  compose,
  constant,
  defaults,
  equals,
  filter,
  find,
  findIndex,
  first,
  flatMap,
  get,
  groupBy,
  includes,
  isEmpty,
  isNull,
  isUndefined,
  keyBy,
  keys,
  last,
  map,
  max,
  min,
  omit,
  omitBy,
  partition,
  pick,
  propEq,
  reject,
  round,
  set,
  split,
  startsWith,
  trim,
  values,
  isNil,
  uniq,
  defer,
  reduce,
} from 'lodash/fp';
import {
  AccountType,
  ConflictTaskScreeningResult,
  CountByStatus,
  DomainData,
  Flatten,
  FTScreeningCriteria,
  InclusionExclusionCriteriaFormData,
  InclusionStatus,
  ScreeningCriteriaResult,
  ScreeningForm,
  ScreeningResult,
  ScreeningTask,
  ScreeningTaskResult,
  ScreeningTaskType,
  Stage,
  StageType,
} from '../common/types';
import {
  DecisionFilter,
  TActiveKeywordFilters,
  TActiveKeywordVariable,
  TFilterTarget,
  TPdfFilter,
  TSearchOperator,
  YearFilters,
} from '../apollo/screening_state';
import {
  TDistributedReferencesSearchVariables,
  TExclusionReason,
  TReferenceData,
  TReferencesCountsQueryData,
  TReferencesCountsQueryVariables,
  TReferencesSearchQueryData,
  TUpdateTaskResultArgs,
} from '../components/screening';
import { uid, updateArrayItem } from './utils';
import { gql, loader } from 'graphql.macro';
import ApolloClient, { MutationUpdaterFn } from 'apollo-client';
import { User } from '@sentry/browser';
import { useState } from 'react';
import useActionLogger from '../components/hooks/use_action_logger';
import i18n from '../i18n';
import { t } from '@lingui/macro';
import { GQLType } from '../apollo/local_states';
import { updateReferenceCommentsCache } from '../components/references/helpers';
import type { ReferenceCommentFragmentType } from '../graphql/reference_comment_fragment';

const ReferencesSearchQuery = loader('../graphql/distributed_references_search_query.gql');
const ReferencesCountsQuery = loader('../graphql/distributed_references_counts_query.gql');

export type StageStats = Pick<Stage, 'id' | 'tasks_by_status' | 'completed'> & {
  tasks_aggregate: {
    aggregate: {
      count: number;
    };
  };
  conflicts_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type TTaskCounts = {
  total: number;
  toReview: number;
  included: number;
  excluded: number;
  postponed: number;
  progress: number;
  conflict?: number;
};

export function getTaskCounts(totalCount: number, tasksByStatus: CountByStatus[]): TTaskCounts {
  const stats = keyBy('inclusion_status', tasksByStatus);
  const included = stats.included?.count ?? 0;
  const excluded = stats.excluded?.count ?? 0;
  const postponed = stats.postponed?.count ?? 0;

  return {
    total: totalCount,
    toReview: totalCount - included - excluded - postponed,
    included,
    excluded,
    postponed,
    progress: totalCount === 0 ? 0 : ceil(((included + excluded) / totalCount) * 100),
  };
}

export function getScreeningTaskCounts(referencesCountsData?: TReferencesCountsQueryData) {
  const all = get('all.aggregate.count', referencesCountsData) ?? 0;
  const included = get('included.aggregate.count', referencesCountsData) ?? 0;
  const excluded = get('excluded.aggregate.count', referencesCountsData) ?? 0;
  const postponed = get('postponed.aggregate.count', referencesCountsData) ?? 0;

  return {
    total: all,
    included,
    excluded,
    toReview: all - included - excluded - postponed,
    postponed,
    progress: all === 0 ? 0 : round(((included + excluded) / all) * 100),
  };
}

export function getStageTaskCounts(stageStats: StageStats | null | undefined): TTaskCounts | null {
  if (stageStats == null) return null;

  return getTaskCounts(stageStats.tasks_aggregate.aggregate.count, stageStats.tasks_by_status);
}

export function getProjectTaskCounts(allStagesStats: StageStats[]) {
  // TODO: if there are multiple stages, combine the results
  return getStageTaskCounts(allStagesStats[0]);
}

function ensureCriteriaAreDefined(result: any, formDomains: DomainData[]) {
  const criteria = formDomains.map(({ id }) => ({ id }));
  return defaults({ criteria }, result);
}

export function includeStudy(result: any, formDomains: DomainData[]): ScreeningResult {
  const newResult = ensureCriteriaAreDefined(result, formDomains);
  newResult.inclusionStatus = 'included';
  newResult.criteria = map(omit('answer'), newResult.criteria);
  return newResult;
}

export function postponeStudy(
  existingResult: ScreeningResult,
  _formDomains: DomainData[]
): ScreeningResult {
  const newResult: ScreeningResult = {
    ...existingResult,
    inclusionStatus: InclusionStatus.Postponed,
  };

  return newResult;
}

export function excludeStudy(reasonCode: string) {
  return (result: any, formDomains: DomainData[]): ScreeningResult => {
    const newResult = ensureCriteriaAreDefined(result, formDomains);
    newResult.inclusionStatus = 'excluded';
    find(propEq('id', reasonCode), newResult.criteria).answer = 'no';
    return newResult;
  };
}

export function unreviewStudy(result: any, formDomains: DomainData[]) {
  const newResult = ensureCriteriaAreDefined(result, formDomains);
  newResult.inclusionStatus = null;
  return newResult;
}

export function getDomainVariablePath(domainId: string, variableId: string): string {
  return `${domainId}::${variableId}`;
}

export type TSearchWithOperatorObject = {
  operator: TSearchOperator | null;
  keywords: string[];
};

export type TSearchQueryArgs = {
  project_id: string;
  stage_id?: string;
  order_by_col?:
    | 'first_author'
    | 'year'
    | 'title'
    | 'accession_number'
    | 'reference_number'
    | 'comment'
    | 'last_changed'
    | 'last_changed_and_study_score'
    | 'preliminary_decision'
    | 'tiab_decision'
    | 'ft_decision'
    | 'batch'
    | 'pdf';
  order_desc?: boolean;
  active_document_types?: string[];
  active_tags?: string[];
  active_preliminary_decisions?: string[];
  active_preliminary_decision_codes?: string[];
  active_tiab_decisions?: string[];
  active_tiab_decision_codes?: string[];
  active_ft_decisions?: string[];
  active_ft_decision_codes?: string[];
  min_year?: number | null;
  max_year?: number | null;
  search?: string[];
  non_ft_search?: string[];
  search_with_operator?: TSearchWithOperatorObject[];
  is_title_search_operator?: boolean;
  is_abstract_search_operator?: boolean;
  is_first_author_search_operator?: boolean;
  is_authors_search_operator?: boolean;
  is_accession_number_search?: boolean;
  is_record_number_search?: boolean;
  is_comments_search?: boolean;
  is_doi_search?: boolean;
  only_with_comments?: boolean;
  has_pdf?: boolean;
  only_without_abstract?: boolean;
  is_year_text_search?: boolean;
  is_duplicates_list?: boolean;
};

export type TDistributedReferencesSearchArgs = {
  project_id: string;
  stage_id: string;
  screener_user_id?: string;
  task_type?: ScreeningTaskType;
  task_completed?: boolean;
  inclusion_status?:
    | InclusionStatus.Included
    | InclusionStatus.Excluded
    | InclusionStatus.Postponed
    | 'to_review';
  order_by_col?:
    | 'year'
    | 'last_changed'
    | 'decision'
    | 'document_type'
    | 'first_author'
    | 'title'
    | 'study_score'
    | 'last_changed_and_study_score'
    | 'accession_number'
    | 'reference_number';
  order_desc?: boolean;
  active_document_types?: string[];
  active_decision_codes?: string[];
  active_screening_tags?: string[];
  min_year?: number | null;
  max_year?: number | null;
  search?: string[];
  non_ft_search?: string[];
  search_with_operator?: TSearchWithOperatorObject[];
  is_title_search_operator?: boolean;
  is_abstract_search_operator?: boolean;
  is_include_decision_active?: boolean;
  is_first_author_search_operator?: boolean;
  is_authors_search_operator?: boolean;
  is_record_number_search_operator?: boolean;
  is_doi_search?: boolean;
  is_accession_number_search?: boolean;
  is_comments_search?: boolean;
  is_year_text_search?: boolean;
};

export enum STAGE_PREFIXES {
  preliminary = 'preliminary_',
  titlesAndAbstracts = 'tiab_',
  fullText = 'ft_',
}

export const preliminaryDecisionIn = `${STAGE_PREFIXES.preliminary}included`;
export const preliminaryDecisionConflict = `${STAGE_PREFIXES.preliminary}conflict`;
export const preliminaryDecisionToReview = `${STAGE_PREFIXES.preliminary}toReview`;
export const tiabDecisionIn = `${STAGE_PREFIXES.titlesAndAbstracts}included`;
export const tiabDecisionConflict = `${STAGE_PREFIXES.titlesAndAbstracts}conflict`;
export const tiabDecisionToReview = `${STAGE_PREFIXES.titlesAndAbstracts}toReview`;
export const ftDecisionConflict = `${STAGE_PREFIXES.fullText}conflict`;
export const ftDecisionToReview = `${STAGE_PREFIXES.fullText}toReview`;

function getActiveDecisionsForStage(stagePrefix: STAGE_PREFIXES, activeDecisions: string[]) {
  return compose(
    map((decision: string) => decision.replace(stagePrefix, '')),
    filter(startsWith(stagePrefix))
  )(activeDecisions);
}

export function searchTokensToSearchQueryArgs(payload: {
  projectId: string;
  activeDocumentTypes: string[];
  activeDecisions: string[];
  activeDecisionCodes: string[];
  activeScreeningTags: string[];
  activeYears: YearFilters;
  searchTokens: string[];
  searchTokensWithOperator: TSearchWithOperatorObject[];
  filtersTarget: TFilterTarget;
  commentRequired: boolean;
  pdfFilter: TPdfFilter;
  emptyAbstractRequired: boolean;
  orderedBy: any;
  stageId?: string;
  isDuplicatesList?: boolean;
}): TSearchQueryArgs {
  const {
    projectId,
    activeDocumentTypes,
    activeDecisions,
    activeDecisionCodes,
    activeScreeningTags,
    activeYears,
    searchTokens,
    searchTokensWithOperator,
    filtersTarget,
    commentRequired,
    pdfFilter,
    emptyAbstractRequired,
    orderedBy,
    stageId,
    isDuplicatesList,
  } = payload;
  const searchTokensWithoutSpacesAndHyphens = map(
    (token) => token.replace(/[\s\-]+/, ' '),
    escapeTokens(searchTokens)
  );
  const active_preliminary_decisions = getActiveDecisionsForStage(
    STAGE_PREFIXES.preliminary,
    activeDecisions
  );
  const active_tiab_decisions = getActiveDecisionsForStage(
    STAGE_PREFIXES.titlesAndAbstracts,
    activeDecisions
  );
  const active_ft_decisions = getActiveDecisionsForStage(STAGE_PREFIXES.fullText, activeDecisions);

  // adds word boundary asterisks to the search token at the beginning or end (or both) of string
  // so users can find relevant results in non-ft-search columns without typing asterisks on both sides
  const non_ft_search = reduce<string, string[]>((acc, token) => {
    acc.push(
      ...map(
        (elem: string) => elem.replace(/^\*|\*$/g, '%%'),
        [
          token,
          `${token.startsWith('*') ? '' : '* '}${token}${token.endsWith('*') ? '' : ' *'}`,
          `${token}${token.endsWith('*') ? '' : ' *'}`,
          `${token.startsWith('*') ? '' : '* '}${token}`,
        ]
      )
    );
    return acc;
  }, [])(searchTokens);

  const result: TSearchQueryArgs = {
    project_id: projectId,
    stage_id: stageId,
    active_document_types: values(activeDocumentTypes),
    active_preliminary_decisions,
    active_preliminary_decision_codes: values(activeDecisionCodes),
    active_tiab_decisions,
    active_tiab_decision_codes: values(activeDecisionCodes),
    active_ft_decisions,
    active_ft_decision_codes: values(activeDecisionCodes),
    active_tags: values(activeScreeningTags),
    min_year: activeYears?.minYear ?? null,
    max_year: activeYears?.maxYear ?? null,
    search: searchTokensWithoutSpacesAndHyphens,
    non_ft_search,
    search_with_operator: searchTokensWithOperator,
    is_title_search_operator:
      filtersTarget === 'all_fields' ||
      filtersTarget === 'text_fields' ||
      filtersTarget === 'title',
    is_abstract_search_operator: filtersTarget === 'all_fields' || filtersTarget === 'text_fields',
    is_first_author_search_operator:
      filtersTarget === 'all_fields' ||
      filtersTarget === 'text_fields' ||
      filtersTarget === 'author',
    is_authors_search_operator:
      filtersTarget === 'all_fields' ||
      filtersTarget === 'text_fields' ||
      filtersTarget === 'authors',
    only_with_comments: commentRequired,
    is_comments_search: filtersTarget === 'all_fields' || filtersTarget === 'comments',
    is_accession_number_search:
      filtersTarget === 'all_fields' || filtersTarget === 'accession_number',
    is_record_number_search: filtersTarget === 'all_fields' || filtersTarget === 'record_number',
    is_doi_search: filtersTarget === 'all_fields' || filtersTarget === 'doi',
    only_without_abstract: emptyAbstractRequired,
    is_year_text_search: filtersTarget === 'all_fields',
    is_duplicates_list: isDuplicatesList,
  };

  if (pdfFilter !== 'all') {
    result['has_pdf'] = pdfFilter === 'with_pdf';
  }

  if (orderedBy && 'column' in orderedBy && 'order' in orderedBy) {
    result['order_by_col'] = orderedBy.column;
    result['order_desc'] = orderedBy.order === 'desc';
  }

  return result;
}

export function getDistributedReferencesSearchQueryArgs(payload: {
  projectId: string;
  stageId: string;
  userId?: string;
  taskType?: ScreeningTaskType;
  taskCompleted?: boolean;
  activeDocumentTypes?: string[];
  activeDecisionCodes?: string[];
  activeScreeningTags?: string[];
  activeYears?: YearFilters;
  searchTokens?: string[];
  searchTokensWithOperator?: TSearchWithOperatorObject[];
  filtersTarget?: TFilterTarget;
  inclusionStatus?:
    | InclusionStatus.Included
    | InclusionStatus.Excluded
    | InclusionStatus.Postponed
    | 'to_review';
  orderedBy?: any;
}): TDistributedReferencesSearchArgs {
  const {
    projectId,
    stageId,
    userId,
    taskType,
    taskCompleted,
    activeDocumentTypes,
    activeDecisionCodes,
    activeScreeningTags,
    activeYears,
    searchTokens,
    searchTokensWithOperator,
    filtersTarget,
    inclusionStatus,
    orderedBy,
  } = payload;
  const resultingArgs: TDistributedReferencesSearchArgs = {
    project_id: projectId,
    stage_id: stageId,
  };

  if (orderedBy && 'column' in orderedBy && 'order' in orderedBy) {
    resultingArgs['order_by_col'] = orderedBy.column;
    resultingArgs['order_desc'] = orderedBy.order === 'desc';
  }

  if (userId !== undefined) {
    resultingArgs['screener_user_id'] = userId;
  }
  if (taskType != null) {
    resultingArgs['task_type'] = taskType;
  }
  if (taskCompleted != null) {
    resultingArgs['task_completed'] = taskCompleted;
  }
  if (inclusionStatus != null) {
    resultingArgs['inclusion_status'] = inclusionStatus;
  }
  if (activeDocumentTypes) {
    resultingArgs['active_document_types'] = values(activeDocumentTypes);
  }
  if (activeDecisionCodes) {
    resultingArgs['active_decision_codes'] = values(activeDecisionCodes);
  }
  if (activeScreeningTags) {
    resultingArgs['active_screening_tags'] = values(activeScreeningTags);
  }
  if (activeYears) {
    resultingArgs['min_year'] = activeYears?.minYear ?? null;
    resultingArgs['max_year'] = activeYears?.maxYear ?? null;
  }
  if (searchTokens) {
    const searchTokensWithoutSpacesAndHyphens = compose(
      map((token) => token.replace(/[\s\-]+/, ' ')),
      escapeTokens
    )(searchTokens);

    resultingArgs['search'] = searchTokensWithoutSpacesAndHyphens;

    // convert full text search wildcards into like-based search ones
    // adds word boundary asterisks to the search token at the beginning or end (or both) of string
    // so users can find relevant results in non-ft-search columns without typing asterisks on both sides
    resultingArgs['non_ft_search'] = reduce<string, string[]>((acc, token) => {
      acc.push(
        ...map(
          (elem: string) => elem.replace(/^\*|\*$/g, '%%'),
          [
            token,
            `${token.startsWith('*') ? '' : '* '}${token}${token.endsWith('*') ? '' : ' *'}`,
            `${token}${token.endsWith('*') ? '' : ' *'}`,
            `${token.startsWith('*') ? '' : '* '}${token}`,
          ]
        )
      );
      return acc;
    }, [])([
      ...searchTokens,
      ...(searchTokensWithOperator?.flatMap((elem) => elem.keywords) ?? []),
    ]);
  }
  if (searchTokensWithOperator) {
    const escapedTokensWithOperator: TSearchWithOperatorObject[] = map(
      (searchObject: TSearchWithOperatorObject) => {
        return {
          ...searchObject,
          keywords: escapeTokens(searchObject.keywords),
        };
      },
      searchTokensWithOperator
    );
    resultingArgs['search_with_operator'] = escapedTokensWithOperator;
  }
  if (filtersTarget) {
    resultingArgs['is_title_search_operator'] =
      filtersTarget === 'all_fields' ||
      filtersTarget === 'text_fields' ||
      filtersTarget === 'title';

    resultingArgs['is_abstract_search_operator'] =
      filtersTarget === 'all_fields' || filtersTarget === 'text_fields';
    resultingArgs['is_first_author_search_operator'] =
      filtersTarget === 'all_fields' ||
      filtersTarget === 'text_fields' ||
      filtersTarget === 'author';
    resultingArgs['is_authors_search_operator'] =
      filtersTarget === 'all_fields' ||
      filtersTarget === 'text_fields' ||
      filtersTarget === 'authors';
    resultingArgs['is_record_number_search_operator'] =
      filtersTarget === 'all_fields' || filtersTarget === 'record_number';
    resultingArgs['is_doi_search'] = filtersTarget === 'all_fields' || filtersTarget === 'doi';
    resultingArgs['is_accession_number_search'] =
      filtersTarget === 'all_fields' || filtersTarget === 'accession_number';
    resultingArgs['is_comments_search'] =
      filtersTarget === 'all_fields' || filtersTarget === 'comments';
    resultingArgs['is_year_text_search'] = filtersTarget === 'all_fields';
  }
  if (activeDecisionCodes) {
    resultingArgs['is_include_decision_active'] = activeDecisionCodes.includes('in');
  }

  return resultingArgs;
}

export function getSearchTokens(
  activeKeywordFilters: TActiveKeywordFilters
): TSearchWithOperatorObject[] {
  return compose(
    filter((domainKeywords: TActiveKeywordVariable) => !isEmpty(get('keywords', domainKeywords))),
    flatMap((domainKeywords: TActiveKeywordVariable) => {
      return {
        operator: get('searchOperator', domainKeywords),
        keywords: keys(omitBy(propEq('active', false), get('keywords', domainKeywords))),
      };
    }),
    values
  )(activeKeywordFilters);
}

export function getTaskResultControlId(
  taskResult: ScreeningResult,
  stageType?: StageType
): string | null {
  const { inclusionStatus, criteria } = taskResult;

  if (inclusionStatus === InclusionStatus.Included) {
    if (stageType === StageType.FullTextScreening) {
      return find(propEq('answer', 'yes'), criteria)?.id ?? null;
    }
    return 'in';
  }

  const excludedCriterion = find(propEq('answer', 'no'), criteria);

  return excludedCriterion == null ? null : excludedCriterion.id;
}

// screeningDecisionReferencesSearchCacheUpdater's helper
function getReferenceSearchAggregateRecord(
  inclusionStatus: 'included' | 'excluded' | 'postponed',
  count: number
) {
  return {
    [inclusionStatus]: {
      aggregate: {
        count,
        __typename: 'reference_aggregate_fields',
      },
      __typename: 'reference_aggregate',
    },
  };
}

const inclusionStatusToDecisionFilterMap = {
  included: 'in',
  excluded: 'out',
  postponed: 'postponed',
};

function augmentCachedRefs(
  references: TReferenceData[],
  decisionFilter: DecisionFilter,
  appliedStatus: 'included' | 'excluded' | 'postponed' | null,
  reference?: TReferenceData
): TReferenceData[] {
  if (!reference) {
    return references;
  }

  const mappedStatus = appliedStatus
    ? inclusionStatusToDecisionFilterMap[appliedStatus]
    : 'to_review';
  const referenceIds = map('id', references);

  return mappedStatus === decisionFilter && !includes(reference.id, referenceIds)
    ? [reference, ...references]
    : references;
}

/**
 * When screening decision is applied we want to perform following optimistic updates:
 *  - decrement current decision filter counter if its value isn't  "All"
 *  - if current decision counter is "All", for each affected reference: if its current status
 *    is set - decrement its counter
 *  - remove affected references from current decision filter's references collection
 *  - increment the counter of applied decision filter
 *  - add affected references to the applied decision filter's references collection
 */
export function screeningDecisionReferencesSearchCacheUpdater(
  lastReferencesFetchVariables: TDistributedReferencesSearchVariables,
  decisionFilter: DecisionFilter,
  refIdsToUpdate: string[],
  appliedInclusionStatus:
    | InclusionStatus.Included
    | InclusionStatus.Excluded
    | InclusionStatus.Postponed
    | null,
  formId: string,
  oldResults: TTaskResult[],
  // below properties are only for screening history undo/redo actions
  prevReference?: TReferenceData,
  prevDecision?: string | null
): MutationUpdaterFn {
  return (proxy, _result) => {
    const currentTaskResultsMap = keyBy(
      ({ task_id, form_id }) => getTaskResultCacheKey(task_id, form_id),
      oldResults
    );
    function getCurrentTaskResult(reference: TReferenceData) {
      const task = getTaskFromReference(reference, userId);
      if (task == null) return;

      return currentTaskResultsMap[getTaskResultCacheKey(task.id, formId)];
    }

    function maybeUpdateReferencesSearchQueryReferencesCache(
      queryVariables: TDistributedReferencesSearchVariables,
      updater: (data: TReferenceData[]) => TReferenceData[]
    ) {
      // in case applied status has never been fetched, this will throw because there will be
      // no record in cache for such query. And we can skip it, since when user clicks the applied
      // status it will fetch corresponding records for the first time and those records will contain
      // this update
      try {
        const cachedReferencesSearchData = proxy.readQuery<
          TReferencesSearchQueryData,
          TDistributedReferencesSearchVariables
        >({
          query: ReferencesSearchQuery,
          variables: queryVariables,
        });

        if (cachedReferencesSearchData == null) return;

        proxy.writeQuery({
          query: ReferencesSearchQuery,
          variables: queryVariables,
          data: {
            ...cachedReferencesSearchData,
            references: updater(cachedReferencesSearchData.references),
          },
        });
      } catch (error) {}
    }

    const cachedReferencesSearchData = proxy.readQuery<
      TReferencesSearchQueryData,
      TDistributedReferencesSearchVariables
    >({
      query: ReferencesSearchQuery,
      variables: lastReferencesFetchVariables,
    });
    const countsSearchArgs = omit(
      ['inclusion_status', 'order_by_col', 'order_desc'],
      lastReferencesFetchVariables.searchArgs
    ) as Omit<TDistributedReferencesSearchArgs, 'inclusion_status' | 'order_by_col' | 'order_desc'>;
    const lastReferencesCountsVariables: TReferencesCountsQueryVariables = {
      allSearchArgs: countsSearchArgs,
      includedSearchArgs: { ...countsSearchArgs, inclusion_status: InclusionStatus.Included },
      excludedSearchArgs: { ...countsSearchArgs, inclusion_status: InclusionStatus.Excluded },
      postponedSearchArgs: { ...countsSearchArgs, inclusion_status: InclusionStatus.Postponed },
    };
    const cachedReferencesCountsData = proxy.readQuery<
      TReferencesCountsQueryData,
      TReferencesCountsQueryVariables
    >({
      query: ReferencesCountsQuery,
      variables: lastReferencesCountsVariables,
    });

    if (cachedReferencesSearchData == null || cachedReferencesCountsData == null) return;
    const userId = lastReferencesFetchVariables.searchArgs.screener_user_id ?? null;

    const refsToAugment = get('references', cachedReferencesSearchData) ?? [];

    // append reference on undo/redo if it isn't in the cachedReferencesSearchData already
    const cachedRefs = augmentCachedRefs(
      refsToAugment,
      decisionFilter,
      appliedInclusionStatus,
      prevReference
    );

    const [affectedReferences, otherReferences] = partition((ref) => {
      const isAffected = includes(ref.id, refIdsToUpdate);
      // don't compute the task result if this is not affected ref;
      if (!isAffected) return false;

      const currentTaskResult = getCurrentTaskResult(ref);
      const currentReferenceInclusionStatus = get('result.inclusionStatus', currentTaskResult);

      return currentReferenceInclusionStatus != appliedInclusionStatus;
    }, cachedRefs);

    // edge case - on undo screening task, so the counters change properly
    const updatedAffectedReferences =
      affectedReferences.length === 0 && prevReference ? [prevReference] : affectedReferences;

    const updatedCounters = updatedAffectedReferences.reduce(
      (acc, reference) => {
        // TODO: DRY violation - we have this task result computation 3 times (above, here and below)
        const currentTaskResult = getCurrentTaskResult(reference);
        const currentReferenceInclusionStatus = get('result.inclusionStatus', currentTaskResult);

        // this ref status is being changed due to undo/redo action
        if (
          prevReference &&
          !isUndefined(prevDecision) &&
          prevDecision !== appliedInclusionStatus
        ) {
          if (!isNull(prevDecision)) {
            acc[prevDecision] -= 1;
          }
          if (!isNull(appliedInclusionStatus)) {
            acc[appliedInclusionStatus] += 1;
          }
          return acc;
        }

        // this is "To review" reference, just increment the applied status counter
        if (currentReferenceInclusionStatus == null) {
          acc[appliedInclusionStatus!] += 1;
          return acc;
        }

        // this ref has a different status than one being applied, decrement its current status
        // counter and increment the applied status's one
        if (currentReferenceInclusionStatus !== appliedInclusionStatus) {
          acc[currentReferenceInclusionStatus] -= 1;
          acc[appliedInclusionStatus!] += 1;
        }

        return acc;
      },
      {
        included: cachedReferencesCountsData.included.aggregate.count,
        excluded: cachedReferencesCountsData.excluded.aggregate.count,
        postponed: cachedReferencesCountsData.postponed.aggregate.count,
      } as { included: number; excluded: number; postponed: number }
    );

    // do not remove reference from "All" references collection
    const updatedLastQueryReferences: TReferenceData[] =
      decisionFilter === 'all' ? cachedReferencesSearchData.references : otherReferences;

    const updatedLastQueryData = {
      ...cachedReferencesSearchData,
      references: updatedLastQueryReferences,
    };

    proxy.writeQuery({
      query: ReferencesSearchQuery,
      variables: lastReferencesFetchVariables,
      data: updatedLastQueryData,
    });

    proxy.writeQuery({
      query: ReferencesCountsQuery,
      variables: lastReferencesCountsVariables,
      data: {
        ...cachedReferencesCountsData,
        ...getReferenceSearchAggregateRecord('included', updatedCounters.included),
        ...getReferenceSearchAggregateRecord('excluded', updatedCounters.excluded),
        ...getReferenceSearchAggregateRecord('postponed', updatedCounters.postponed),
      },
    });

    // we also need to update a destination (status that was applied) status references collection:
    // add new items
    const appliedStatusQueryVariables = {
      ...lastReferencesFetchVariables,
      searchArgs: {
        ...lastReferencesFetchVariables.searchArgs,
        inclusion_status: (appliedInclusionStatus ??
          'to_review') as TDistributedReferencesSearchArgs['inclusion_status'],
      },
    };
    maybeUpdateReferencesSearchQueryReferencesCache(
      appliedStatusQueryVariables,
      // TODO: here we simply prepend affected references to the collection. What about sorting?
      (appliedStatusRefs) => affectedReferences.concat(appliedStatusRefs)
    );

    // also there is a special case when decision is applied on "All" references list for references
    // already having the inclusion decision. We need to remove then from their current status's
    // references collection
    if (decisionFilter === 'all') {
      const affectedReferencesByCurrentStatus = groupBy((ref) => {
        const currentTaskResult = getCurrentTaskResult(ref);
        const currentReferenceInclusionStatus = get('result.inclusionStatus', currentTaskResult);

        return currentReferenceInclusionStatus == null
          ? 'to_review'
          : currentReferenceInclusionStatus;
      }, affectedReferences);

      keys(affectedReferencesByCurrentStatus).forEach((status) => {
        const refIdsToRemove = map(get('id'), affectedReferencesByCurrentStatus[status]);
        const statusQueryVariables = {
          ...lastReferencesFetchVariables,
          searchArgs: {
            ...lastReferencesFetchVariables.searchArgs,
            inclusion_status: status as TDistributedReferencesSearchArgs['inclusion_status'],
          },
        };
        maybeUpdateReferencesSearchQueryReferencesCache(statusQueryVariables, (cachedRefs) =>
          reject(compose(includes(__, refIdsToRemove), get('id')), cachedRefs)
        );
      });
    }
  };
}

const SCREENING_TASK_TYPENAME = 'task';

export function combineMutationUpdaterFns<
  T = {
    [key: string]: any;
  }
>(...updaters: MutationUpdaterFn<T>[]): MutationUpdaterFn<T> {
  return (proxy, result) => {
    updaters.forEach((updater) => updater(proxy, result));
  };
}

export const taskResultGraphqlCacheUpdate: MutationUpdaterFn = (proxy, mutationResult): void => {
  const { data } = mutationResult;
  const updatedResults = get('insert_task_result.returning', data);
  const taskFragment = gql`
    fragment TaskFragment on task {
      task_results {
        task_id
        form_id
        result
        updated_at
      }
    }
  `;

  updatedResults.forEach((updatedResult) => {
    const taskCacheId = `${SCREENING_TASK_TYPENAME}:${updatedResult.task_id}`;

    const cachedTask: any = proxy.readFragment({
      id: taskCacheId,
      fragment: taskFragment,
    });

    if (cachedTask == null) return;

    const { task_results: existingResults } = cachedTask;
    let updatedTaskResults = [updatedResult];

    if (!isEmpty(existingResults)) {
      const resultIdx = findIndex(
        (result: any) =>
          result.task_id === updatedResult.task_id && result.form_id === updatedResult.form_id,
        existingResults
      );

      updatedTaskResults =
        resultIdx > -1
          ? updateArrayItem(resultIdx, constant(updatedResult), existingResults)
          : [...existingResults, updatedResult];
    }

    return proxy.writeFragment({
      id: taskCacheId,
      fragment: taskFragment,
      data: {
        task_results: updatedTaskResults,
        __typename: SCREENING_TASK_TYPENAME,
      },
    });
  });
};

export type TTaskResult = Pick<
  ScreeningTaskResult,
  'task_id' | 'form_id' | 'result' | 'comment_id' | 'updated_at'
>;

export function getTaskResult(task: Pick<ScreeningTask, 'task_results'>, formId: string) {
  return compose(find(propEq('form_id', formId)), get('task_results'))(task);
}

export function getConflictTasksFromReference(
  reference: TReferenceData,
  stageId: string
): ConflictTaskScreeningResult[] {
  return compose(
    flatMap('screening_results'),
    filter({ stage_id: stageId, task_type: ScreeningTaskType.ConflictResolution }),
    get('study.tasks')
  )(reference);
}

export function getTaskResultFromReference(
  reference: TReferenceData,
  formId: string,
  userId: string | null,
  stageId?: string
): TTaskResult | undefined {
  const task = getTaskFromReference(reference, userId, stageId);

  if (task) return getTaskResult(task, formId);
}

export function getTaskFromReference(
  reference: TReferenceData,
  userId: string | null,
  stageId?: string
): ScreeningTask | undefined {
  // TODO: This returns the first task found. If one reference may have more than 1 task for a
  // given team member this will need to be refactored

  return compose(
    find(
      (task: ScreeningTask) =>
        // stage matches if provided
        (stageId == null || task.stage_id === stageId) &&
        // no user assigned or user matches the requested one
        (userId === null
          ? task.team_member === null
          : equals(userId, get('team_member.user_id', task)))
    ),
    get('study.tasks')
  )(reference);
}

export function getReferenceComment(
  reference: TReferenceData,
  referenceResult?: TTaskResult
): Flatten<typeof reference['reference_comments']> | undefined {
  if (referenceResult?.comment_id == null) return;

  return find({ id: referenceResult.comment_id }, reference.reference_comments);
}

export function getSelectedFTScreeningCriteria(
  form: ScreeningForm,
  referenceResult?: TTaskResult
): FTScreeningCriteria[] {
  if (referenceResult == null) return [];
  const criteriaMap = keyBy('id', [...form.form.inclusion, ...form.form.exclusion]);

  return ((referenceResult.result as ScreeningResult).criteria ?? []).map(
    ({ id }) => criteriaMap[id]
  );
}

export function keywordsFromInputValue(
  value: string,
  separator: string | RegExp = /[,\n\r]/
): string[] {
  return compose(compact, map(trim), split(separator ?? /[,\n\r]/))(value);
}

export function overwriteTemplateItemsIds(
  template: InclusionExclusionCriteriaFormData
): InclusionExclusionCriteriaFormData {
  return {
    domains: template.domains.map((domain) => ({
      ...domain,
      id: uid(),
      variables: domain.variables.map((variable) => ({
        ...variable,
        id: uid(),
      })),
    })),
  };
}

export function getReferencesSearchPaginationParams(
  rowsToFetch: number[],
  batchSize: number
): {
  offset: number;
  limit: number;
} {
  if (isEmpty(rowsToFetch)) return { offset: 0, limit: batchSize };

  const minRowIdx = min(rowsToFetch)!;
  const maxRowIdx = max(rowsToFetch)!;
  const limit = Math.max(ceil((maxRowIdx - minRowIdx) / batchSize), batchSize);

  return { offset: minRowIdx, limit };
}

export function getTaskInclusionStatus(
  reference: TReferenceData,
  formId: string,
  userId: string,
  exclusionReasons: TExclusionReason[]
) {
  const taskResult = getTaskResultFromReference(reference, formId, userId);
  const result = taskResult?.result;
  const inclusionStatus = result?.inclusionStatus;
  const exclusionReasonId = result ? getTaskResultControlId(result) : null;
  const exclusionReason =
    inclusionStatus === 'excluded'
      ? compose(
          first,
          flatMap((elem: TExclusionReason) => elem.label),
          filter((elem: TExclusionReason) => elem.id === exclusionReasonId)
        )(exclusionReasons)
      : null;
  return {
    inclusionStatus,
    exclusionReason,
    exclusionReasonId,
  };
}

export function isAdminUser(user: Pick<User, 'role' | 'hasAccessToAllProjects'>): boolean {
  return user.role === AccountType.TechAdmin && user.hasAccessToAllProjects;
}

export interface ScreeningDecision {
  reference: TReferenceData;
  previousDecision: {
    inclusionStatus: InclusionStatus | null | undefined;
    exclusionCode: string | null;
  };
  currentDecision: {
    inclusionStatus: InclusionStatus | null | undefined;
    exclusionCode: string | null;
  };
}

interface ScreeningHistory {
  undoHistory: ScreeningDecision[];
  redoHistory: ScreeningDecision[];
}

const EMPTY_HISTORY: ScreeningHistory = {
  undoHistory: [],
  redoHistory: [],
};

export function useScreeningHistory(
  userId: string,
  formId: string,
  formDomains: DomainData[],
  handleTaskResultUpdate: (payload: TUpdateTaskResultArgs) => Promise<any>
) {
  const insertActionLog = useActionLogger();
  const [{ undoHistory, redoHistory }, setHistory] = useState<ScreeningHistory>(EMPTY_HISTORY);

  function getInclusionFunc(
    inclusionStatus: InclusionStatus | null | undefined,
    exclusionCode: string | null
  ) {
    switch (inclusionStatus) {
      case InclusionStatus.Included:
        return includeStudy;
      case InclusionStatus.Excluded:
        return excludeStudy(exclusionCode!);
      default:
        return unreviewStudy;
    }
  }

  function applyDecision(
    decisionFn,
    reference: TReferenceData,
    prevDecision: string | null | undefined
  ) {
    const task = getTaskFromReference(reference, userId);
    const currentResult = getTaskResultFromReference(reference, formId, userId);
    const newResult = {
      task_id: task!.id,
      form_id: formId,
      comment_id: currentResult?.comment_id ?? null,
      result: decisionFn(
        currentResult == null ? {} : pick(['inclusionStatus', 'comment'], currentResult.result),
        formDomains
      ),
      updated_at: new Date().toISOString(),
    };
    handleTaskResultUpdate({
      newResults: [newResult],
      oldResults: currentResult ? [currentResult] : [],
      refIdsToUpdate: [reference.id],
      undoReference: reference,
      prevDecision,
    }).then(() =>
      insertActionLog('screening decision applied with screening history', {
        userId,
        formId,
        reference,
        prevDecision,
        newResult,
      })
    );
  }

  function updateHistory(isUndoHistoryUpdate: boolean) {
    const lastHistoryItem = last(isUndoHistoryUpdate ? undoHistory : redoHistory);
    if (!lastHistoryItem) return;

    const newHistoryItem: ScreeningDecision = {
      ...lastHistoryItem,
      previousDecision: lastHistoryItem.currentDecision,
      currentDecision: lastHistoryItem.previousDecision,
    };
    const { inclusionStatus, exclusionCode } = newHistoryItem.currentDecision;
    const decisionFn = getInclusionFunc(inclusionStatus, exclusionCode);
    applyDecision(
      decisionFn,
      newHistoryItem.reference,
      newHistoryItem.previousDecision.inclusionStatus
    );

    setHistory({
      undoHistory: isUndoHistoryUpdate
        ? undoHistory.slice(0, -1)
        : [...undoHistory, newHistoryItem],
      redoHistory: isUndoHistoryUpdate
        ? [...redoHistory, newHistoryItem]
        : redoHistory.slice(0, -1),
    });

    return newHistoryItem.reference;
  }

  function undo() {
    return updateHistory(true);
  }

  function redo() {
    return updateHistory(false);
  }

  function push(screeningDecision: ScreeningDecision) {
    setHistory({
      undoHistory: [...undoHistory, screeningDecision],
      redoHistory: [],
    });
  }

  function getLength() {
    return {
      undoLength: undoHistory.length,
      redoLength: redoHistory.length,
    };
  }

  return {
    undo,
    redo,
    push,
    getLength,
  };
}

export function escapeTokens(tokens: string[]) {
  return tokens.map((token) => token.replace(/[.+?^${}()|[\]\\]/g, '\\$&'));
}

export function getTaskCountForDecisionFilter(
  decisionFilter: DecisionFilter,
  taskCounts: TTaskCounts
) {
  switch (decisionFilter) {
    case 'in':
      return taskCounts.included;
    case 'out':
      return taskCounts.excluded;
    case 'to_review':
      return taskCounts.toReview;
    case 'postponed':
      return taskCounts.postponed;
    default:
      return taskCounts.total;
  }
}

export function getDecisionFilterLabel(decisionFilter: DecisionFilter): string {
  switch (decisionFilter) {
    case 'in':
      return i18n._(t`in`);
    case 'out':
      return i18n._(t`out`);
    case 'to_review':
      return i18n._(t`to review`);
    case 'postponed':
      return i18n._(t`postponed`);
    case 'resolved':
      return i18n._(t`resolved`);
    case 'all':
      return i18n._(t`all`);
    default:
      return '-';
  }
}

export function getInclusionStatusLabel(inclusionStatus: InclusionStatus) {
  switch (inclusionStatus) {
    case InclusionStatus.Included:
      return i18n._(t`included`);
    case InclusionStatus.Excluded:
      return i18n._(t`excluded`);
    case InclusionStatus.Postponed:
      return i18n._(t`postponed`);
    case InclusionStatus.Conflict:
      return i18n._(t`conflict`);
    default:
      return;
  }
}

export function getTasksUpdateAndDeleteStrategiesForRemovedTeamMember(
  teamMemberId: string,
  sendDecisions: boolean
): { tasksDeleteStrategy: object; taskUpdateStrategy: object } {
  const tasksDeleteStrategy = sendDecisions
    ? // matches unsent member's tasks which have no decision
      {
        team_member_id: { _eq: teamMemberId },
        completed: { _eq: false },
        _not: {
          task_results: {
            _or: [
              { result: { _contains: { inclusionStatus: 'included' } } },
              { result: { _contains: { inclusionStatus: 'excluded' } } },
            ],
          },
        },
      }
    : // matches all member's unsent tasks, including those with decision
      {
        team_member_id: { _eq: teamMemberId },
        completed: { _eq: false },
      };
  const taskUpdateStrategy = sendDecisions
    ? // matches unsent member's tasks which have some decision
      {
        team_member_id: { _eq: teamMemberId },
        completed: { _eq: false },
        task_results: {
          _or: [
            { result: { _contains: { inclusionStatus: 'included' } } },
            { result: { _contains: { inclusionStatus: 'excluded' } } },
          ],
        },
      }
    : // matches no task - nothing to update
      { id: { _in: [] } };

  return { taskUpdateStrategy, tasksDeleteStrategy };
}

export function getTaskResultCacheKey(taskId: string, formId: string) {
  return `task_result:${taskId}_${formId}`;
}

type TDistributedReferenceCommentInsertMutationData = {
  insert_task_result_one: {
    form_id: string;
    task_id: string;
    comment_id: string;
    reference_comment: ReferenceCommentFragmentType & GQLType;
  } & GQLType;
};

export const addNewDistributedReferenceCommentToCache: MutationUpdaterFn<TDistributedReferenceCommentInsertMutationData> =
  (proxy, { data }) => {
    if (data == null) return;
    const insertedComment = data.insert_task_result_one.reference_comment;

    updateReferenceCommentsCache(proxy, insertedComment.reference_id, (existingComments) => [
      insertedComment,
      ...existingComments,
    ]);
  };

const taskFragment = gql`
  fragment TaskLocalFragment on task {
    task_results {
      task_id
      updated_at
      form_id
      result
      comment_id
    }
  }
`;

export const updateCachedTaskResultWithNewDistributedReferenceComment: MutationUpdaterFn<TDistributedReferenceCommentInsertMutationData> =
  (proxy, { data }) => {
    if (data == null) return;
    const insertedComment = data.insert_task_result_one.reference_comment;

    // task_result may not exist at this point of time, so have to update task_result data via `task`
    // item instead of updating `task_result` directly update task_result with new comment id
    const { task_id: taskId, form_id: formId } = data.insert_task_result_one;
    const taskCacheKey = `task:${taskId}`;
    const taskData = proxy.readFragment<{ task_results: TTaskResult[] }>({
      id: taskCacheKey,
      fragment: taskFragment,
    });

    if (taskData) {
      const taskResultIdx = findIndex({ task_id: taskId, form_id: formId }, taskData.task_results);

      const updatedTaskResults =
        taskResultIdx >= 0
          ? updateArrayItem(
              taskResultIdx,
              set('comment_id', insertedComment.id),
              taskData.task_results
            )
          : [
              ...taskData.task_results,
              {
                task_id: taskId,
                form_id: formId,
                comment_id: insertedComment.id,
                result: {},
                updated_at: new Date().toISOString(),
                __typename: 'task_result',
              },
            ];

      proxy.writeFragment({
        id: taskCacheKey,
        fragment: taskFragment,
        data: {
          ...taskData,
          task_results: updatedTaskResults,
        },
      });
    }
  };

const TaskResultFragment = gql`
  fragment TaskResultLocalFragment on task_result {
    comment_id
  }
`;

export function getDistributedReferenceCommentUpdateOptimisticResponse(data: {
  taskId: string;
  formId: string;
  referenceId: string;
  commentId: string;
  comment: string;
  stageId: string;
  teamMemberId: string;
  userId: string;
  userName: string;
}) {
  return {
    insert_task_result_one: {
      task_id: data.taskId,
      form_id: data.formId,
      comment_id: data.commentId,
      reference_comment: {
        id: data.commentId,
        stage_id: data.stageId,
        task_id: data.taskId,
        form_id: data.formId,
        reference_id: data.referenceId,
        comment: data.comment,
        updated_at: new Date().toISOString(),
        team_member: {
          id: data.teamMemberId,
          user: {
            id: data.userId,
            name: data.userName,
            __typename: 'User',
          },
          __typename: 'team_member',
        },
        __typename: 'reference_comment',
      },
      __typename: 'task_result',
    },
  };
}

export const resetCachedTaskResultCommentId: MutationUpdaterFn<{
  delete_reference_comment_by_pk: {
    id: string;
    task_id: string | null;
    form_id: string | null;
    reference_id: string;
  } & GQLType;
}> = (proxy, { data }) => {
  if (data == null) return;
  const { delete_reference_comment_by_pk: removedCommentData } = data;

  // reset comment_id on task_result
  if (removedCommentData.task_id && removedCommentData.form_id) {
    const taskResultCacheKey = getTaskResultCacheKey(
      removedCommentData.task_id,
      removedCommentData.form_id
    );
    proxy.writeFragment({
      id: taskResultCacheKey,
      fragment: TaskResultFragment,
      data: {
        comment_id: null,
        __typename: 'task_result',
      },
    });
  }
};

export function getStatusCodesFromTaskResults(
  exclusionReasons: TExclusionReason[],
  taskResult?: ScreeningResult
): string[] {
  return compose(
    uniq,
    map(
      (criterion: ScreeningCriteriaResult) =>
        find(propEq('id', criterion.id), exclusionReasons)?.code
    ),
    reject((criterion: ScreeningCriteriaResult) => isNil(criterion.answer)),
    get('criteria')
  )(taskResult);
}

type UnsentTaskInclusionStatus = {
  task_id: string;
  task_results: { form_id: string; inclusionStatus: InclusionStatus }[];
};

const TaskResultResultFragment = gql`
  fragment TaskResultResultFragment on task_result {
    result
  }
`;

export function shouldRefetchUpdatedTasks(
  currentTaskStatuses: UnsentTaskInclusionStatus[],
  prevTasksStatuses: UnsentTaskInclusionStatus[],
  client: ApolloClient<object>
): boolean {
  const prevTasksMap = keyBy('task_id', prevTasksStatuses);

  const updatedTasks = currentTaskStatuses.filter(
    ({ task_id, task_results }) =>
      prevTasksMap[task_id]?.task_results[0].inclusionStatus !== task_results[0].inclusionStatus
  );
  let shouldRefetch = false;

  for (let { task_id, task_results } of updatedTasks) {
    const { form_id, inclusionStatus: updatedInclusionStatus } = task_results[0];

    try {
      const localTaskData = client.readFragment<{ task_results: TTaskResult[] }>({
        id: `task:${task_id}`,
        fragment: taskFragment,
      });
      const maybeLocalTaskResult = find({ form_id }, localTaskData?.task_results);

      // no local result for this task (and/or form) found, need to refetch the local data (task was
      // screened by another user)
      if (maybeLocalTaskResult == null) {
        shouldRefetch = true;
        continue;
      }

      // finally compare the inclusion status
      if (maybeLocalTaskResult.result.inclusionStatus !== updatedInclusionStatus) {
        shouldRefetch = true;

        // have to update the cache with the changed status manually since we cannot rely on it
        // being updated during refetch because if during refetch some task gets filtered out (list
        // is filtered by status and status applied is different from the one the list is filtered
        // by) its task_result object is not updated in cache. We defer this operation not to block
        // the UI update
        defer(() => {
          client.writeFragment({
            id: getTaskResultCacheKey(task_id, form_id),
            fragment: TaskResultResultFragment,
            data: {
              result: {
                ...maybeLocalTaskResult.result,
                inclusionStatus: updatedInclusionStatus,
              },
              __typename: 'task_result',
            },
          });
        });
      }
    } catch (error) {
      // task data is not present in local cache, it means we need to refetch
      shouldRefetch = true;
    }
  }

  return shouldRefetch;
}
