// TODO: use standard Typescript types declaration approach (via .d.ts files)

import { IconName, IMenuItemProps } from '@blueprintjs/core';
import { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StageStats } from '../lib/study_helpers';

export type Timestamp = string;

export enum AccountType {
  ItAdmin = 'gba-app:itadmin',
  TechAdmin = 'gba-app:techadmin',
  User = 'gba-app:user',
}

export enum WorkerStatus {
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Skipped = 'SKIPPED',
  Retry = 'RETRY',
}

export enum ProjectStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  InTrash = 'IN_TRASH',
}

export enum ScreeningListMode {
  Completed = 'Completed',
  ToScreen = 'ToScreen',
}

export enum QcStrategyType {
  Single = 'Single',
  Double = 'Double',
  SingleExtractorSingleQA = 'SingleExtractorSingleQA',
}

export interface UpdatedStatusByUser {
  id: String;
  name: String;
}

export enum ScreeningTaskType {
  Screening = 'screening',
  ConflictResolution = 'conflict_resolution',
}

export enum StageType {
  PreliminaryScreening = 'preliminary_screening',
  TitlesAbstractScreening = 'titles_abstract_screening',
  FullTextScreening = 'full_text_screening',
  DataExtraction = 'data_extraction',
}

export interface ConflictTaskScreeningResult {
  conflict_task_id: string;
  project_id: string;
  stage_id: string;
  study_id: string;
  team_member_id: string;
  team_member: TeamMember;
  conflict_task: ScreeningTask;
  result: ScreeningResult;
  form_id: string;
  comment_id: string | null;
}

export interface ScreeningTask {
  id: string;
  project_id: string;
  study_id: string;
  stage_id: string;
  study_pool_id: string;
  team_member_id: string;
  stage: Stage;
  study: Study;
  study_pool: StudyPool;
  team_member: TeamMember;
  task_results: ScreeningTaskResult[];
  task_type: ScreeningTaskType;
  is_draft: boolean;
  completed: boolean;
  screening_results: null | ConflictTaskScreeningResult[];
}

export interface ScreeningCriteriaResult {
  id: string;
  answer: 'yes' | 'no';
}

export enum ConflictType {
  Status = 'status',
  InclusionReason = 'inclusionReason',
  ExclusionReason = 'exclusionReason',
}

export enum ResolutionStrategy {
  Merge = 'Merge',
  InclusionStatus = 'InclusionStatus',
  ExclusionReasonHierarchy = 'ExclusionReasonHierarchy',
  InclusionReasonHierarchy = 'InclusionReasonHierarchy',
  DisagreementReconciliation = 'DisagreementReconciliation',
}

export interface ScreeningResult {
  criteria?: ScreeningCriteriaResult[];
  inclusionStatus?: InclusionStatus.Included | InclusionStatus.Excluded | InclusionStatus.Postponed;
  comment?: string;
  tags?: string[];
  conflictResolutionMethod?: ResolutionStrategy;
}

export interface ScreeningTaskResult<T = ScreeningResult> {
  form_id: string;
  task_id: string;
  task: ScreeningTask;
  form: ScreeningForm;
  result: T;
  updated_at: Timestamp;
  comment_id: string | null;
  reference_comment: ReferenceComment | null;
}

export interface ScreeningType {
  id: string;
  name: string;
  screeners_no: number;
}

export interface CountByStatus {
  inclusion_status: InclusionStatus;
  count: number;
}

export interface StageReferencesCount {
  reference_count: number;
  reference_with_pdf_count: number;
}

export interface StageResult {
  project_id: string;
  stage_id: string;
  study_id: string;
  study_pool_id: string;
  inclusion_status: InclusionStatus;
  stage_order_number: number;
  status_reason_codes: string[];
  comment: string;
  tags: string[];
}

export interface StageResultsCounts extends CountByStatus {
  stage_id: string;
  status_reason_codes: string[];
}

export type ConflictsResolutionSettings = {
  [key in ConflictType]: {
    automated: boolean;
    strategy: ResolutionStrategy;
    strategyDetails?: any;
  };
};

export interface Stage {
  id: string;
  name: string;
  project: Project;
  screening_type: ScreeningType;
  forms: ScreeningForm[];
  studies: Study[];
  study_pools: StudyPool[];
  tasks: ScreeningTask[];
  tasks_by_status: CountByStatus[];
  studies_by_status: CountByStatus[];
  completed: boolean;
  type: StageType;
  order_number?: number;
  reference_count: GraphQLAggregateData;
  reference_with_pdf_count: GraphQLAggregateData;
  tasks_aggregate?: GraphQLAggregateData;
  conflicts_resolution_strategies: ConflictsResolutionSettings;
  stage_results_counts: StageResultsCounts[];
}

export interface FormTemplate {
  id: string;
  name: string;
  template: any;
}

export interface ScreeningForm {
  id: string;
  form: any;
  stage_id: string;
  template_id: string | null;
  template: FormTemplate | null;
  stage: Stage;
  task_results: ScreeningTaskResult[];
  task_results_aggregate: GraphQLAggregateData;
}

export interface Question {
  id: string;
  question: string;
  type: string;
  project: Project;
}

export enum StudyDistribution {
  Individual = 'INDIVIDUAL',
  Proportional = 'PROPORTIONAL',
}

export interface StudyPoolStudy {
  project_id: string;
  study_id: string;
  study_pool_id: string;
  inclusion_status: InclusionStatus;
  status_reason_codes: string[];
  comment: string;
  tags: string[];
  deleted_at: Timestamp | null;
  study: Study;
  study_pool: StudyPool;
}

export interface StudyPoolTeamMemberCompoundId {
  study_pool_id: string;
  team_member_id: string;
}

export interface StudyPoolTeamMember extends StudyPoolTeamMemberCompoundId {
  task_count: number;
  team_member: TeamMember;
  study_pool: StudyPool;
}

export interface StudyPool {
  id: string;
  stage: Stage;
  project: Project;
  distribution_type: StudyDistribution | null;
  study_pool_studies: StudyPoolStudy[];
  study_pool_team_members: StudyPoolTeamMember[];
  distribution_status: WorkerStatus;
}

export interface ProjectsFolder {
  id: string;
  name: string;
  created_at: Timestamp;
  deleted_at: Timestamp | null;
  status: ProjectStatus;
  projects: ProjectData[];
  projects_aggregate: GraphQLAggregateData;
  __typename?: string;
}

export interface Project {
  id: string;
  name: string;
  created_at: Timestamp;
  updated_at: Timestamp;
  created_by_user: User | null;
  folder: ProjectsFolder | null;
  references: Reference[];
  team_members: TeamMember[];
  status: ProjectStatus;
  studies: Study[];
  due_date: Timestamp;
  questions: Question[];
  study_pools: StudyPool[];
  study_pools_aggregate: GraphQLAggregateData;
  screening_type: ScreeningType;
  stages: Stage[];
  completed: boolean;
  references_import_tasks: ReferencesImportTask[];
}

export enum Role {
  Manager = 'manager',
  Screener = 'screener',
}

export interface UserAction {
  id: number;
  user_id: string;
  url: string;
  event_type: string;
  client_timestamp: Timestamp;
  server_timestamp: Timestamp;
  event_data: null | object;
  project_id: null | string;
}

export interface TeamMember {
  id: string;
  deleted_at?: string | null;
  project: Project;
  user_id: string;
  user: User | null;
  role: Role;
  tasks: ScreeningTask[];
  tasks_aggregate: GraphQLAggregateData;
  study_pool_team_members: StudyPoolTeamMember[];
  created_at: Timestamp;
  user_actions_in_project: UserAction[];
  tasks_by_status: {
    count: number;
    inclusion_status: string;
    stage_id: string;
  }[];
}

// Below we accept string, undefined and nulls.
// This is because GraphQL endpoint returns nulls,
// however in access token the values will be undefined.
export interface UserAttributes {
  salutation?: string | null;
  title?: string | null;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
  institutionName?: string | null;
  institutionWebsite?: string | null;
  institutionAreaCode?: string | null;
  institutionPhone?: string | null;
  institutionAddress?: string | null;
  institutionCity?: string | null;
  institutionPostalCode?: string | null;
  institutionCountry?: string | null;
  autoDeleteEnabled?: boolean | null;
  hasAccessToAllProjects?: boolean | null;
}

export interface User extends UserAttributes {
  id: string;
  username?: string;
  name: string;
  firstName: string;
  lastName: string;
  enabled?: boolean;
  email: string;
  role: AccountType;
  createdAt?: Timestamp;
}

export interface ReferencesImportTask {
  key: string;
  filename: string;
  project_id: string;
  created_by: string;
  status: WorkerStatus;
  created_at: Timestamp;
  error_message: string | null;
  failed_records: { record_string: string; error: string }[];
  refs_count: number | null;
  project: Project;
  references: Reference[];
  label: string;
  deleted_at: Timestamp | null;
  deleted_by: string | null;
}

export interface Reference {
  id: string;
  title: string;
  attrs: any;
  study_id: string;
  study: Study;
  project: Project;
  stage_study_statuses: StageStudyStatus[];
  import_task_key: string;
  import_task: ReferencesImportTask;
  reference_attributes: { year: number; document_type: string };
  reference_attachments: ReferenceAttachment[];
  reference_attachments_aggregate: GraphQLAggregateData;
  screener_comments: {
    reference_id: string;
    screener_comments: string;
  };
  screener_tags: {
    reference_id: string;
    tags: string[];
  } | null;
  screening_decisions: {
    id: string;
    latest_inclusion_status: InclusionStatus | null;
    latest_status_reason_codes: string[];
    preliminary_inclusion_status: InclusionStatus | null;
    preliminary_status_reason_codes: string[];
    ta_inclusion_status: InclusionStatus | null;
    ta_status_reason_codes: string[];
    ft_inclusion_status: InclusionStatus | null;
    ft_status_reason_codes: string[];
  };
  reference_comments: ReferenceComment[];
  deleted_by: string | null;
  deleted_at: Timestamp | null;
  removal_reason: string | null;
  no_report: boolean;
}

export interface ReferenceAttachment {
  key: string;
  filename: string;
}

export interface Study {
  id: string;
  project: Project;
  current_stage_id: string | null;
  current_stage: Stage;
  references: Reference[];
  study_pool_studies: StudyPoolStudy[];
  tasks: ScreeningTask[];
  deleted_at: Timestamp | null;
}

export enum TaskType {
  Extract = 'Extract',
  Review = 'Review',
}

export interface Task {
  id: number;
  type: TaskType;
  project: Project;
  user: User;
  study: Study;
  completed: boolean;
  result?: any;
  ready_to_claim?: boolean;
  created_at?: Timestamp;
  updated_at?: Timestamp;
  started: boolean;
}

export enum ExtractionModelTag {
  TestArticle = 'TestArticle',
  Species = 'Species',
  Strain = 'Strain',
  Sex = 'Sex',
  Endpoint = 'Endpoint',
}

export enum FormAttributeType {
  Text = 'Text',
  Number = 'Number',
}

export interface FormTextBlock {
  content: string;
}

export interface FormAttribute {
  caption: string;
  tag: ExtractionModelTag | string;
  type?: ExtractionModelTag | FormAttributeType;
  // tag is kind of ID, unique within one class, calculated either from ModelTag or from caption
  initialValue?: string;
  comment?: string;
  regExp?: string | null;
}

export enum FormAttributesClassRelation {
  One,
  Many,
}

export interface FormAttributesClassRelations {
  [classId: string]: FormAttributesClassRelation;
}

export interface FormAttributesClass {
  id: string;
  name: string;
  attributes: FormAttribute[];
  relations: FormAttributesClassRelations;
}

export enum FormCategoryType {
  Info,
  Data,
}

export interface FormInfoCategory {
  id?: string;
  type: FormCategoryType.Info;
  name: string;
  items: Array<FormTextBlock | FormAttribute>;
}

export interface FormDataCategory {
  id?: string;
  type: FormCategoryType.Data;
  name: string;
  items: Array<FormTextBlock | FormAttributesClass>;
}

export interface DataExtractionForm {
  id?: string;
  name: string;
  categories: Array<FormInfoCategory | FormDataCategory>;
  model_tags: { formAttributesClassId: ExtractionModelTag[] };
  regular_expressions: { [classId: string]: { [attrTag: string]: string } };
}

export enum FormItem {
  Text = 'Text',
  Object = 'Object',
  Attribute = 'Attribute',
}

export enum InclusionStatus {
  Included = 'included',
  Excluded = 'excluded',
  Conflict = 'conflict',
  Postponed = 'postponed',
}

export type KeywordsData = {
  included: string[];
  excluded: string[];
};

export type VariableData = {
  id: string;
  name: string;
  title: string;
  instructions: string | null;
  keywords: KeywordsData | null;
};

export type DomainData = {
  id: string;
  name: string;
  exclusionCode: string;
  exclusionButtonLabel: string;
  variables: VariableData[];
};

export type InclusionExclusionCriteriaFormData = {
  domains: DomainData[];
  tags?: ScreeningTag[];
};

export interface InclusionExclusionCriteriaForm {
  id: string;
  form: InclusionExclusionCriteriaFormData;
  form_team_member_keywords?: ScreenerInclusionExclusionCriteriaForm[];
}

export type ScreenerInclusionExclusionCriteriaDomainData = {
  [variableId: string]: KeywordsData;
};

export type ScreenerInclusionExclusionCriteriaFormKeywords = {
  [domainId: string]: ScreenerInclusionExclusionCriteriaDomainData;
};

export interface ScreenerInclusionExclusionCriteriaForm {
  id: string;
  form_id: string;
  team_member_id: string;
  keywords: ScreenerInclusionExclusionCriteriaFormKeywords;
}

export interface FTScreeningCriteria {
  id: string;
  name: string;
  inclusionStatus: InclusionStatus.Included | InclusionStatus.Excluded;
  code: string;
  instruction?: string;
  keywords?: KeywordsData;
  variableId?: string; // needed for legacy compatibility to be able to convert from FT to TiAb form
}

export interface FTScreeningFormData {
  inclusion: FTScreeningCriteria[];
  exclusion: FTScreeningCriteria[];
  tags: ScreeningTag[];
}

export interface DomainItem {
  domain: string;
  variables: string[];
}

export interface UserKeyword {
  domain: string | null;
  excluded: string[];
  id?: string;
  included: string[];
  title: string;
  user_id?: string | null;
  variable: string | null;
}

export enum PrismaExportFormat {
  SVG = 'SVG',
  PNG = 'PNG',
}

export type StageStudyStatus = {
  project_id: string;
  stage_id: string;
  study_id: string;
  reference_id: string;
  inclusion_status: string;
};

export type StatusColorSettings = {
  neutralPrimary: string;
  neutralSecondary: string;
  acceptedPrimary: string;
  acceptedSecondary: string;
  rejectedPrimary: string;
  rejectedSecondary: string;
  conflictsPrimary: string;
  conflictsSecondary: string;
};

export type UserSettings = {
  user_id: string;
  settings: {
    statusColorsMode?: 'default' | 'custom';
    statusColors?: StatusColorSettings;
  };
};

export type TReferenceLogType =
  | 'reference_uploaded'
  | 'attachment_assigned'
  | 'attachment_removed'
  | 'status_changed'
  | 'tags_changed'
  | 'comments_updated'
  | 'comments_removed'
  | 'reviewers_assigned'
  | 'reviewer_removed'
  | 'reviewer_replaced'
  | 'reviewer_decision_applied'
  | 'conflict_resolved'
  | 'primary_extraction_completed'
  | 'extraction_qa_review_completed'
  | 'removed_as_duplicate'
  | 'marked_with_no_report'
  | 'restored'
  | 'no_report_mark_removed'
  | 'screening_status_overwrite';

export type ReferenceLog = {
  id: string;
  reference_id: string;
  data: {
    type: TReferenceLogType;
    userId?: string | null;
    [key: string]: any;
  };
  created_at: Timestamp;
};

export interface PrismaEditorItem {
  id: string;
  title: string;
  value: number;
}

export interface PrismaData {
  recordsFromDatabases: PrismaSection;
  recordsFromAdditionalResources: PrismaSection;
  duplicatesRemoved: PrismaSection;
  recordsScreenedPreliminary: PrismaSection;
  recordsScreenedAgainstTitles: PrismaSection;
  recordsNotRetrievedFullText: PrismaSection;
  recordsScreenedFullText: PrismaSection;
  newStudiesIncludedInReview: PrismaSection;
}

export interface PrismaSection {
  checked?: boolean;
  title: string;
  itemHeaderTitle?: string;
  records: PrismaEditorItem[];
  value?: number;
  isInvalid?: boolean;
}

export type ReferenceTaskResultsTypeTmp = {
  updated_at: Timestamp;
  reference: Pick<
    Reference,
    | 'id'
    | 'title'
    | 'attrs'
    | 'deleted_at'
    | 'deleted_by'
    | 'removal_reason'
    | 'reference_attributes'
  > & {
    study: Pick<Study, 'id' | 'tasks' | 'deleted_at'>;
    import_task: Pick<ReferencesImportTask, 'key' | 'created_at'>;
    reference_attachments: Pick<ReferenceAttachment, 'key'>[];
  };
  __typename?: string;
};

export interface ReferenceClaim {
  id: string;
  project_id: string;
  stage_id: string;
  claimed_by: string;
  completed: boolean;
  status: WorkerStatus;
  error_message: string | null;
  created_at: string;
  all_claimed_references_aggregate: GraphQLAggregateData;
  completed_claimed_references_aggregate: GraphQLAggregateData;
}

export interface GraphQLAggregateData {
  aggregate: {
    count: number;
    __typename?: string;
  };
  __typename?: string;
}

export type RecursivePartial<T> = {
  [Property in keyof T]?: T[Property] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[Property] extends object
    ? RecursivePartial<T[Property]>
    : T[Property];
};

export interface GenPDFTextRanges<T> {
  [pageNum: number]: {
    [itemIdx: number]: T[];
  };
}

export interface ScreeningTag {
  id: string;
  tag: string;
  copied?: boolean;
}

export interface MatchParams {
  projectId?: string;
  referenceStatus?: string;
  referenceId?: string;
}

export interface ProjectMatchParams extends MatchParams {
  projectId: string;
}

export interface StageMatchParams extends MatchParams {
  projectId: string;
  stageId: string;
  referenceId?: string;
}

export interface AdminReferenceDetailsMatchParams extends MatchParams {
  projectId: string;
  referenceId: string;
}

export interface ActionProps extends IMenuItemProps {
  tooltipMessage?: string;
}

export interface CategoryWithActions {
  name: string;
  actions: ActionProps[];
}

export interface IAppLocationState {
  prevLocation?: Pick<Location, 'pathname' | 'search'>;
}

export interface AppContentProps<T = MatchParams> extends RouteComponentProps<T> {}

export enum ReferenceRemovalReason {
  IsDuplicate = 'is_duplicate',
  ImportTaskRemoved = 'import_task_removed',
}

export interface ReferenceComment {
  id: string;
  stage_id: string | null;
  task_id: string | null;
  form_id: string | null;
  reference_id: string;
  team_member_id: string;
  comment: string;
  created_at: Timestamp;
  updated_at: Timestamp;
  team_member: TeamMember;
  reference: Reference;
}

export type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;

export interface ProjectsListBatchAction {
  title: string;
  icon: IconName;
  handler: () => void;
}

export type ProjectData = Project & {
  stages: Stage[];
  stageStats: StageStats[];
  my_team_member: Pick<TeamMember, 'id' | 'role'>[];
  claims_counts: {
    refs_without_attachments_count: number;
    claims_in_progress_count: number;
  };
};

export type ProjectCounts = {
  [ProjectStatus.Active]: number;
  [ProjectStatus.Archived]: number;
  [ProjectStatus.InTrash]: number;
  SCREENING: number;
};

export enum ManagerTabIds {
  Active = 'projects:active',
  Archived = 'projects:archived',
  Deleted = 'projects:deleted',
  Screening = 'projects:screening',
  Users = 'users',
}

export interface IManagerTab {
  id: ManagerTabIds;
  title: ReactNode;
  titleIcon: IconName;
  projectsStatus: ProjectStatus;
}

export type GetProjectsQueryVariables = {
  userId: string;
  currentStatus: ProjectStatus;
  filterBy: object;
  orderBy: object;
  foldersFilterBy?: object;
  foldersOrderBy?: object;
};

export type TSelectedFolderAction = 'delete' | 'showSettings';

export interface ISelectedProjects {
  main: number[];
  [name: string]: number[];
}

export enum SelectedProjectAction {
  MoveToFolder = 'move_to_folder',
  Archive = 'archive',
  Restore = 'restore',
  Delete = 'delete',
}

export type TProjectsListSortOrder = 'latest_first' | 'alphabetical';

export interface IFoldersCollapsed {
  main: boolean;
  [name: string]: boolean;
}

export type TReferencesExportFormat = 'ris' | 'csv';
