/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { find, isEmpty, kebabCase } from 'lodash/fp';
import { Button, Classes, NonIdealState, Colors, Text } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { fixedHeaderTable, defaultTableCss } from '../../common/styles';
import { Project, TeamMember } from '../../common/types';
import { useCurrCallback } from '../../lib/utils';
import { formatDateDistance, getLocalizedDateTime, getRoleLabel } from '../project/helpers';
import { t, Trans } from '@lingui/macro';
import i18n from '../../i18n';
import { get } from 'lodash/fp';

const usersTableCss = css`
  ${fixedHeaderTable}
  ${defaultTableCss}
`;

interface IProjectMembersListProps {
  project: Project;
  onUserSelect: (userId: string) => void;
}

const headerCss = css`
  background-color: ${Colors.LIGHT_GRAY4};
`;

const goToProjectCss = css`
  line-height: 30px;
`;

const COL_NAMES = {
  name: i18n._(t`Name`),
  role: i18n._(t`Role`),
  joinedAt: i18n._(t`Invited to project`),
  lastSeen: i18n._(t`Last activity`),
};

const PROJECT_MEMBERS_COLS = ['name', 'role', 'joinedAt', 'lastSeen'];

const ProjectMembersList: React.FC<IProjectMembersListProps> = ({ project, onUserSelect }) => {
  const history = useHistory();

  const getCellContent = useCallback(
    (colId: string, member: TeamMember) => {
      const memberDetails = find((data) => data.id === member.id, project.team_members);

      switch (colId) {
        case 'name':
          return member.user?.name ?? <Trans>User removed</Trans>;
        case 'lastSeen':
          const serverTimestamp = get('user_actions_in_project[0].server_timestamp', memberDetails);
          return memberDetails && serverTimestamp ? formatDateDistance(serverTimestamp) : '-';
        case 'role':
        case 'joinedAt':
          if (colId === 'role') {
            return getRoleLabel(memberDetails?.role);
          } else {
            return getLocalizedDateTime(memberDetails?.created_at);
          }
        default:
          return '-';
      }
    },
    [project.team_members]
  );

  const goToProject = useCallback(() => {
    history.push(['/projects', project.id].join('/'));
  }, [history, project]);

  const handleRowClick = useCurrCallback(
    (userId, _evt) => {
      onUserSelect(userId);
    },
    [onUserSelect]
  );

  return (
    <div className="h-full flex flex-col overflow-auto">
      <div css={headerCss} className={`p-5 ${Classes.ELEVATION_0}`}>
        <div className="flex flex-row flex-no-wrap justify-between">
          <Text className="text-lg">{project.name}</Text>
          <Button minimal css={goToProjectCss} onClick={goToProject}>
            <Trans>Go to the project page</Trans>
            {' >'}
          </Button>
        </div>
      </div>
      <div className="flex flex-col overflow-auto flex-1 px-5">
        {isEmpty(project.team_members) ? (
          <NonIdealState icon={IconNames.HEART_BROKEN} title={<Trans>No team members</Trans>} />
        ) : (
          <table className="w-full table-fixed border-collapse" css={usersTableCss}>
            <thead>
              <tr>
                {PROJECT_MEMBERS_COLS.map((colId) => (
                  <th key={colId} className={kebabCase(colId)}>
                    {i18n._(t`${COL_NAMES[colId]}`)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={Classes.ELEVATION_1}>
              {project.team_members.map((member) => (
                <tr key={member.id} onClick={handleRowClick(member.user_id)}>
                  {PROJECT_MEMBERS_COLS.map((colId) => (
                    <td key={colId} className={kebabCase(colId)}>
                      {getCellContent(colId, member)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ProjectMembersList;
