/** @jsx jsx */
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React, { MouseEvent, useCallback } from 'react';
import { Flatten, Reference, ScreeningTag } from '../../../common/types';
import { useSetState } from '../../../lib/utils';
import BibliographicalDataEditDialog from './bibliographical_data_edit_dialog';
import DecisionOverwriteDialog from './decision_overwrite_dialog';
import DuplicatesRemoveConfirmation from './duplicates_remove_confirmation';
import MarkNoReportConfirmation from './mark_no_report_confirmation';
import { TReferenceDetailsData } from './reference_details';
import ReferenceTagsEditDialog from './reference_tags_edit_dialog';
import RemoveNoReportStatusConfirmation from './remove_no_report_status_confirmation';
import RestoreRemovedDuplicatesConfirmation from './restore_removed_duplicates_confirmation';
import type { TDecisionOverwriteHandler } from './decision_overwrite_dialog';
import type { TEditedTagsHandler } from './reference_tags_edit_dialog';
import { useKeycloak } from '../../../keycloak';

enum MenuAction {
  EditTags = 'edit-tags',
  EditBibliographicalData = 'edit-bibliographical-data',
  ReportNotRetrieved = 'report-not-retrieved',
  RemoveAsDuplicate = 'remove-as-duplicate',
  OverwriteDecision = 'overwrite-decision',
  RestorePDFStatus = 'restore-pdf-status',
  RestoreReference = 'restore-reference',
}

interface IModifyReferenceMenuProps {
  reference: TReferenceDetailsData;
  screeningTags: ScreeningTag[];
  onSaveBibliographicData: (referenceData: Pick<Reference, 'id' | 'title' | 'attrs'>) => void;
  onRemoveAsDuplicate: (referenceId: string) => void;
  onSetNoReport: (referenceId: string, noReport: boolean) => void;
  onRestore: (referenceId: string) => void;
  onOverwriteScreeningResult: TDecisionOverwriteHandler;
  onUpdateTags: TEditedTagsHandler;
}

interface IModifyReferenceMenuState {
  menuOpen: boolean;
  editBibliographicalDataOpen: boolean;
  reportNotRetrievedConfirmationOpen: boolean;
  removeAsDuplicateConfirmationOpen: boolean;
  editTagsOpen: boolean;
  overwriteDecisionOpen: boolean;
  restoreConfirmationOpen: boolean;
  removeNoReportConfirmationOpen: boolean;
}

const INITIAL_STATE: IModifyReferenceMenuState = {
  menuOpen: false,
  editBibliographicalDataOpen: false,
  editTagsOpen: false,
  reportNotRetrievedConfirmationOpen: false,
  removeAsDuplicateConfirmationOpen: false,
  overwriteDecisionOpen: false,
  restoreConfirmationOpen: false,
  removeNoReportConfirmationOpen: false,
};

const ModifyReferenceMenu: React.FC<IModifyReferenceMenuProps> = ({
  reference,
  screeningTags,
  onSaveBibliographicData,
  onRemoveAsDuplicate,
  onSetNoReport,
  onRestore,
  onOverwriteScreeningResult,
  onUpdateTags,
}) => {
  const [state, setState] = useSetState(INITIAL_STATE);
  const { hasAccessToAllProjects: hasFullAccess } = useKeycloak();
  const {
    menuOpen,
    editBibliographicalDataOpen,
    editTagsOpen,
    reportNotRetrievedConfirmationOpen,
    removeAsDuplicateConfirmationOpen,
    restoreConfirmationOpen,
    removeNoReportConfirmationOpen,
    overwriteDecisionOpen,
  } = state;

  const handleMenuItemClick = useCallback(
    (evt: MouseEvent) => {
      const actionId = (evt.currentTarget as HTMLElement).dataset.actionId as MenuAction;
      switch (actionId) {
        case MenuAction.EditTags:
          return setState({
            menuOpen: false,
            editTagsOpen: true,
          });
        case MenuAction.EditBibliographicalData:
          return setState({
            menuOpen: false,
            editBibliographicalDataOpen: true,
          });
        case MenuAction.RemoveAsDuplicate:
          return setState({
            menuOpen: false,
            removeAsDuplicateConfirmationOpen: true,
          });
        case MenuAction.ReportNotRetrieved:
          return setState({
            menuOpen: false,
            reportNotRetrievedConfirmationOpen: true,
          });
        case MenuAction.RestorePDFStatus:
          return setState({
            menuOpen: false,
            removeNoReportConfirmationOpen: true,
          });
        case MenuAction.RestoreReference:
          return setState({
            menuOpen: false,
            restoreConfirmationOpen: true,
          });
        case MenuAction.OverwriteDecision:
          return setState({
            menuOpen: false,
            overwriteDecisionOpen: true,
          });
        default:
          setState({ menuOpen: false });
      }
    },
    [setState, onSetNoReport, onRestore]
  );

  const toggleMenu = useCallback(() => {
    setState((current) => ({ ...current, menuOpen: !current.menuOpen }));
  }, [setState]);

  const handleMenuClose = useCallback(() => {
    setState({ menuOpen: false });
  }, [setState]);

  const closeTagsEditDialog = useCallback(() => {
    setState({ editTagsOpen: false });
  }, [setState]);

  const closeBibliographicalDataEditDialog = useCallback(() => {
    setState({ editBibliographicalDataOpen: false });
  }, [setState]);

  const handleSaveEditedBibliographicData = useCallback(
    (editedData: Pick<Reference, 'id' | 'title' | 'attrs'>) => {
      if (!hasFullAccess) return;
      setState({ editBibliographicalDataOpen: false });
      onSaveBibliographicData(editedData);
    },
    [setState, onSaveBibliographicData, hasFullAccess]
  );

  const handleRemoveAsDuplicate = () => {
    if (!hasFullAccess) return;
    onRemoveAsDuplicate(reference.id);
    setState({ removeAsDuplicateConfirmationOpen: false });
  };

  const handleNoReportConfirm = () => {
    if (!hasFullAccess) return;
    onSetNoReport(reference.id, !reference.no_report);
    setState({ reportNotRetrievedConfirmationOpen: false });
  };

  const handleRemoveNoReportStatus = () => {
    if (!hasFullAccess) return;
    onSetNoReport(reference.id, false);
    setState({ removeNoReportConfirmationOpen: false });
  };

  const handleRestore = () => {
    if (!hasFullAccess) return;
    onRestore(reference.id);
    setState({ restoreConfirmationOpen: false });
  };

  const handleSaveUpdatedTags = useCallback(
    (newTags: string[], currentResult: Flatten<TReferenceDetailsData['stage_results']>) => {
      if (!hasFullAccess) return;
      onUpdateTags(newTags, currentResult);
      setState({ editTagsOpen: false });
    },
    [setState, onUpdateTags, hasFullAccess]
  );

  const isRemovedReference = reference.deleted_at != null;

  return (
    <React.Fragment>
      <Popover
        isOpen={menuOpen}
        onClose={handleMenuClose}
        target={
          <Button
            text={<Trans>Modify</Trans>}
            rightIcon={IconNames.CARET_DOWN}
            onClick={toggleMenu}
          />
        }
        content={
          <Menu>
            {isRemovedReference ? (
              <MenuItem
                data-action-id={MenuAction.RestoreReference}
                onClick={handleMenuItemClick}
                text={<Trans>Restore</Trans>}
              />
            ) : (
              <React.Fragment>
                {hasFullAccess && (
                  <MenuItem
                    data-action-id={MenuAction.EditTags}
                    onClick={handleMenuItemClick}
                    text={<Trans>Edit structured comments</Trans>}
                  />
                )}
                {hasFullAccess && (
                  <MenuItem
                    data-action-id={MenuAction.EditBibliographicalData}
                    onClick={handleMenuItemClick}
                    text={<Trans>Edit bibliographical data</Trans>}
                  />
                )}
                {reference.no_report && hasFullAccess && (
                  <MenuItem
                    data-action-id={MenuAction.RestorePDFStatus}
                    onClick={handleMenuItemClick}
                    text={<Trans>Restore PDF status</Trans>}
                    disabled={isRemovedReference}
                  />
                )}
                {hasFullAccess && (
                  <MenuItem
                    data-action-id={MenuAction.RemoveAsDuplicate}
                    onClick={handleMenuItemClick}
                    text={<Trans>Remove as duplicate</Trans>}
                  />
                )}
                <MenuItem
                  data-action-id={MenuAction.OverwriteDecision}
                  onClick={handleMenuItemClick}
                  text={<Trans>Overwrite decision</Trans>}
                />
              </React.Fragment>
            )}
          </Menu>
        }
      />
      <ReferenceTagsEditDialog
        tags={screeningTags}
        stages={reference.project.stages}
        stageResults={reference.stage_results}
        isOpen={editTagsOpen}
        onClose={closeTagsEditDialog}
        onApply={handleSaveUpdatedTags}
      />
      <BibliographicalDataEditDialog
        isOpen={editBibliographicalDataOpen}
        reference={reference}
        onClose={closeBibliographicalDataEditDialog}
        onApply={handleSaveEditedBibliographicData}
      />
      <DuplicatesRemoveConfirmation
        isOpen={removeAsDuplicateConfirmationOpen}
        onClose={() => setState({ removeAsDuplicateConfirmationOpen: false })}
        onConfirm={handleRemoveAsDuplicate}
      />
      <MarkNoReportConfirmation
        isOpen={reportNotRetrievedConfirmationOpen}
        onClose={() => setState({ reportNotRetrievedConfirmationOpen: false })}
        onConfirm={handleNoReportConfirm}
      />
      <RemoveNoReportStatusConfirmation
        isOpen={removeNoReportConfirmationOpen}
        onClose={() => setState({ removeNoReportConfirmationOpen: false })}
        onConfirm={handleRemoveNoReportStatus}
      />
      <RestoreRemovedDuplicatesConfirmation
        isOpen={restoreConfirmationOpen}
        onClose={() => setState({ restoreConfirmationOpen: false })}
        onConfirm={handleRestore}
      />
      <DecisionOverwriteDialog
        isOpen={overwriteDecisionOpen}
        stages={reference.project.stages}
        stageResults={reference.stage_results}
        onClose={() => setState({ overwriteDecisionOpen: false })}
        onApply={onOverwriteScreeningResult}
      />
    </React.Fragment>
  );
};

export default ModifyReferenceMenu;
