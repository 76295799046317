/** @jsx jsx */
import {
  Button,
  Classes,
  Colors,
  Divider,
  Drawer,
  Icon,
  Intent,
  NonIdealState,
  Spinner,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { TExclusionReason, TReferenceData } from '../index';
import {
  InclusionStatus,
  ScreeningForm,
  ScreeningTaskType,
  Stage,
  StageType,
  Timestamp,
} from '../../../common/types';
import { compose, find, get, isEmpty, noop, propEq } from 'lodash/fp';
import { DecisionFilter } from '../../../apollo/screening_state';
import {
  EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS,
  SearchAndDesiredUndesiredKeywords,
} from '../../../lib/criteria_utils';
import {
  getTaskResultControlId,
  TTaskCounts,
  getTaskResultFromReference,
  getTaskCountForDecisionFilter,
  getTaskFromReference,
} from '../../../lib/task_helpers';
import DecisionsColumn from '../decisions_column';
import ReferencesList from './../references_list';
import { RowSelectionColumn, TableCol } from '../../common/gba_table';
import {
  alignStartCss,
  fancyScrollCss,
  lightGray4bg,
  lightGray5bg,
  screeningListColHeaderHeight,
} from '../../../common/styles';
import i18n from '../../../i18n';
import TextWithHighlights from '../../common/text_with_highlights';
import { getShortCitation } from '../../references/helpers';
import useReferencesListSort from '../../hooks/use_references_list_sort';
import ReferencePreview from './../reference_preview';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import CountTag from '../../common/count_tag';
import useSetDecisionFilter from '../../hooks/use_set_decision_filter';
import { useCurrCallback } from '../../../lib/utils';
import DecisionTag from '../decision_tag';
import SendDecisionsButton from '../send_decisions_button';
import ScreeningTags from '../screening_tags';
import TiabConflictResolutionControls from './tiab_conflict_resolution_controls';
import { useKeycloak } from '../../../keycloak';
import MyReferenceComment from '../../references/my_reference_comment';
import ReferenceCommentsByStage from '../../references/reference_comments_by_stage';
import ReferencesSearchWidget from '../references_search_widget';
import { formatDate } from '../../project/helpers';
import {
  CollapsibleSection,
  CollapsibleSections,
  useCollapsibleSections,
} from '../../common/collapsible_sections';
import CriteriaScreener from '../../criteria/criteria_screener';
import NavAndDecisionControls from '../navigation_and_decision_controls';

const EMPTY_LIST = [];

const SetActiveAndSelectedReferencesMutation = loader(
  '../../../graphql/local/set_active_and_selected_references.gql'
);

const CONFLICTS_MODE_COLS: TableCol<TReferenceData>[] = [
  RowSelectionColumn,
  {
    id: 'decision',
    label: i18n._(t`Decision`),
    width: 60,
  },
  {
    id: 'reference',
    label: i18n._(t`Reference`),
    headerCellCss: alignStartCss,
    cellCss: css([alignStartCss, { justifyContent: 'flex-start' }]),
  },
  {
    id: 'lastChanged',
    label: i18n._(t`Last changed`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    width: 120,
    sortable: true,
  },
];

interface ITiAbConflictsListProps {
  references: TReferenceData[];
  openFocusMode: () => void;
  selectedReferences: number[];
  setSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  handleInclude: () => void;
  handleExclude: (reasonCode: string) => void;
  taskCounts: TTaskCounts;
  exclusionReasons: TExclusionReason[];
  searchingReferences: boolean;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  highlightsVisible: boolean;
  decisionFilter: DecisionFilter;
  form: ScreeningForm;
  loadingReferences: boolean;
  stageId: string;
  stageType: StageType;
  onHighlightsToggle: () => void;
  unsentTasksCount: number;
  onSendDecisions: (taskIds: string[]) => Promise<any>;
  onUpdateResultTags: (newTags: string[]) => void;
  onUpdateComment: (data: { id?: string; comment: string }) => void;
  onDeleteComment: (commentId: string) => void;
  projectStages: Pick<Stage, 'id' | 'name' | 'order_number'>[];
  totalReferencesCount: number;
  projectId: string;
  shiftActiveReference: (direction: -1 | 1) => void;
  activeReferenceResult?: { task_id: string; form_id: string; result: any; updated_at: Timestamp };
  onLoadMoreReferences?: () => void;
  activeReference?: number;
  loadingCounts?: boolean;
  filtersApplied?: boolean;
}

const TiAbConflictsList: React.FC<ITiAbConflictsListProps> = ({
  references,
  openFocusMode,
  activeReference,
  selectedReferences,
  setSelectedReferences,
  handleInclude,
  handleExclude,
  taskCounts,
  exclusionReasons,
  searchingReferences,
  keywordsData,
  highlightsVisible,
  decisionFilter,
  form,
  activeReferenceResult,
  onLoadMoreReferences,
  onHighlightsToggle,
  onSendDecisions,
  onUpdateResultTags,
  onUpdateComment,
  onDeleteComment,
  loadingReferences,
  stageId,
  unsentTasksCount,
  stageType,
  loadingCounts,
  totalReferencesCount,
  projectStages,
  filtersApplied,
  projectId,
  shiftActiveReference,
}) => {
  const { onClearSort, onSortBy, sortedBy } = useReferencesListSort(stageType);
  const [criteriaOpen, setCriteriaOpen] = useState(false);
  const setDecisionFilter = useSetDecisionFilter();
  const { expandedSections, toggleSection } = useCollapsibleSections(['conflict']);
  const { user } = useKeycloak();
  const [setActiveAndSelectedReferences] = useMutation(SetActiveAndSelectedReferencesMutation);
  const activeReferenceData = activeReference == null ? undefined : references[activeReference];
  const tasksCount = useMemo(() => {
    return getTaskCountForDecisionFilter(decisionFilter, taskCounts);
  }, [decisionFilter, taskCounts]);

  const screeningTags = get('form.tags', form) ?? EMPTY_LIST;

  const conflictsCellRenderer = useCallback(
    (colId: string, reference: TReferenceData) => {
      switch (colId) {
        case 'decision':
          const taskResult = getTaskResultFromReference(reference, form.id, null);
          const inclusionStatus =
            get('result.inclusionStatus', taskResult) ?? InclusionStatus.Conflict;
          const controlId = taskResult ? getTaskResultControlId(get('result', taskResult)) : null;
          const exclusionCode = controlId
            ? compose(get('code'), find(propEq('id', controlId)))(exclusionReasons)
            : null;

          return <DecisionTag decision={inclusionStatus} decisionReason={exclusionCode} />;
        case 'reference':
          return (
            <div className="text-xs">
              <div>{getShortCitation(reference)}</div>
              <TextWithHighlights
                className="truncate"
                keywordsData={
                  highlightsVisible ? keywordsData : EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
                }
                text={reference.title}
              />
            </div>
          );
        case 'lastChanged': {
          const screenedTaskResult = getTaskResultFromReference(reference, form.id, user.id);
          const conflictResolutionTaskResult = getTaskResultFromReference(
            reference,
            form.id,
            null,
            stageId
          );
          const timestamp =
            get('updated_at', conflictResolutionTaskResult) ??
            get('updated_at', screenedTaskResult) ??
            get('import_task.created_at', reference);
          return timestamp ? formatDate(timestamp, 'dd/MM/yyyy HH:mm') : '-';
        }
        default:
          return null;
      }
    },
    [highlightsVisible, keywordsData, form.id, exclusionReasons]
  );

  const setActiveReference = useCallback(
    (activeReference) => {
      setActiveAndSelectedReferences({
        variables: { activeReference: references[activeReference].id },
      });
    },
    [setActiveAndSelectedReferences, references]
  );

  const listLayout = useMemo(() => {
    return {
      cellRenderer: conflictsCellRenderer,
      columns: CONFLICTS_MODE_COLS,
    };
  }, [conflictsCellRenderer]);

  const handleTagClick = useCurrCallback(
    (filterVal: DecisionFilter, _evt) => {
      setDecisionFilter(filterVal);
    },
    [setDecisionFilter]
  );

  const selectedRefsCounts = !isEmpty(selectedReferences) ? selectedReferences.length : undefined;
  const resolutionCompleted = taskCounts.total === 0;
  const noReferences = isEmpty(references) || tasksCount === 0;
  const conflictsResolved = resolutionCompleted || noReferences;
  const myComment = useMemo(() => {
    if (activeReferenceData == null) return;
    const task = getTaskFromReference(activeReferenceData, null, stageId);

    return activeReferenceData?.reference_comments.find(
      ({ team_member, task_id }) => team_member.user.id === user.id && task_id === task?.id
    );
  }, [activeReferenceData?.reference_comments, user, stageId]);

  const otherComments = useMemo(() => {
    return (
      activeReferenceData?.reference_comments.filter(({ id }) => id !== myComment?.id) ?? EMPTY_LIST
    );
  }, [activeReferenceData?.reference_comments, myComment]);

  return (
    <div className="h-full w-full flex flex-col overflow-auto bg-white">
      <div className="flex flex-row items-center border" css={screeningListColHeaderHeight}>
        <div className="flex flex-row flex-grow ml-4 mr-6 overflow-hidden">
          <CountTag
            active={decisionFilter === 'all'}
            className="mr-2"
            count={taskCounts.total}
            onClick={loadingReferences ? undefined : handleTagClick('all')}
            title={<Trans>All references</Trans>}
            loading={loadingCounts}
          />
          <CountTag
            active={decisionFilter === 'to_review'}
            count={taskCounts.toReview}
            onClick={loadingReferences ? undefined : handleTagClick('to_review')}
            className="mr-2"
            title={<Trans>Conflicts</Trans>}
            inclusionStatus="conflict"
            loading={loadingCounts}
          />
          <CountTag
            active={decisionFilter === 'in'}
            count={taskCounts.included}
            onClick={loadingReferences ? undefined : handleTagClick('in')}
            className="mr-2"
            title={<Trans>In</Trans>}
            inclusionStatus="included"
            loading={loadingCounts}
          />
          <CountTag
            active={decisionFilter === 'out'}
            className="mr-2"
            count={taskCounts.excluded}
            onClick={loadingReferences ? undefined : handleTagClick('out')}
            inclusionStatus="excluded"
            title={<Trans>Out</Trans>}
            loading={loadingCounts}
          />
        </div>
        <Tooltip
          className="flex h-full"
          content={
            highlightsVisible ? (
              <Trans>Click to hide highlighted keywords</Trans>
            ) : (
              <Trans>Click to highlight keywords</Trans>
            )
          }
        >
          <Button
            className="h-full ml-2"
            minimal
            intent={highlightsVisible ? Intent.PRIMARY : Intent.NONE}
            icon={highlightsVisible ? IconNames.EYE_OPEN : IconNames.EYE_OFF}
            text={<Trans>Keywords</Trans>}
            onClick={onHighlightsToggle}
          />
        </Tooltip>

        <SendDecisionsButton
          stageId={stageId}
          onSend={onSendDecisions}
          taskType={ScreeningTaskType.ConflictResolution}
          className="mr-4"
        />
        <Divider className="mx-3 h-full" />
        <Tooltip content={<Trans>Inclusion and Exclusion Instructions</Trans>}>
          <Button
            className="mr-3"
            color={Colors.GRAY3}
            icon={IconNames.MANUAL}
            minimal
            onClick={() => setCriteriaOpen(true)}
          />
        </Tooltip>
      </div>
      <div className="h-full flex flex-row flex-no-wrap overflow-auto">
        <div className="w-1/3 flex flex-col overflow-auto`" css={lightGray5bg}>
          <div className="flex-none p-3 border-b bg-white overflow-auto h12">
            <div className="text-xl mb-4 text-gray-700">
              <Trans>Search</Trans>
            </div>
            <ReferencesSearchWidget className="w-full" />
          </div>
          {searchingReferences ? (
            <Spinner className="h-full" />
          ) : noReferences && decisionFilter === 'to_review' && unsentTasksCount > 0 ? (
            <NonIdealState
              title={<Trans>All conflicts have been resolved</Trans>}
              action={
                <SendDecisionsButton
                  className="px-4"
                  stageId={stageId}
                  taskType={ScreeningTaskType.ConflictResolution}
                  onSend={onSendDecisions}
                />
              }
            />
          ) : noReferences && decisionFilter === 'to_review' && !filtersApplied ? (
            <NonIdealState title={<Trans>Thank you!</Trans>} icon={IconNames.ENDORSED}>
              <Trans>All done</Trans>.
            </NonIdealState>
          ) : noReferences ? (
            <NonIdealState
              icon={<Icon icon={IconNames.DOCUMENT} color={Colors.GRAY1} iconSize={30} />}
              title={
                <span className="text-xs font-normal">
                  {filtersApplied ? (
                    <Trans>
                      No references found matching your criteria <br />
                      in selected category
                    </Trans>
                  ) : (
                    <Trans>There are no references in selected category</Trans>
                  )}
                </span>
              }
            />
          ) : (
            <ReferencesList
              formId={form.id}
              references={references}
              activeReference={activeReference}
              selectedReferences={selectedReferences}
              onChangeSelectedReferences={setSelectedReferences}
              onChangeActiveReference={setActiveReference}
              customLayout={listLayout}
              onLoadMore={onLoadMoreReferences}
              decisionFilter={decisionFilter}
              onSortBy={onSortBy}
              sortedBy={sortedBy}
              onClearSort={onClearSort(decisionFilter)}
              openFocusMode={openFocusMode}
              keywordsData={
                highlightsVisible ? keywordsData : EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
              }
              exclusionReasons={exclusionReasons}
              loadingMore={loadingReferences}
              totalReferencesCount={totalReferencesCount}
              stageType={stageType}
              stageId={stageId}
            />
          )}
        </div>
        <div className="w-1/3 flex flex-col overflow-auto bg-white border-l">
          <div className="flex-none flex flex-row flex-no-wrap items-center border-b h-12">
            <div className="flex flex-grow flex-row justify-between">
              <span className="ml-3 text-lg text-gray-700">
                <Trans>Title and Abstract</Trans>
              </span>
            </div>
          </div>
          {searchingReferences ? (
            <Spinner className="h-full" />
          ) : (
            <div className="flex-grow overflow-auto" css={fancyScrollCss}>
              {activeReferenceData && (
                <ReferencePreview
                  keywordsData={
                    highlightsVisible ? keywordsData : EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
                  }
                  reference={activeReferenceData}
                />
              )}
            </div>
          )}
        </div>
        <div
          className="w-1/3 flex flex-col overflow-auto justify-between border-l"
          css={lightGray5bg}
        >
          {activeReferenceData && (
            <Fragment>
              <CollapsibleSections>
                <CollapsibleSection
                  id="conflict"
                  isOpen={expandedSections.includes('conflict')}
                  onToggle={toggleSection}
                  title={<Trans>Conflict</Trans>}
                  titleClass="text-gray-700 px-4 py-1 text-xl h-12 border-b"
                  titleCss={lightGray5bg}
                  collapseContainerCss={lightGray4bg}
                  collapseContainerClass="border-b"
                >
                  <DecisionsColumn
                    tasks={get('study.tasks', activeReferenceData) ?? []}
                    formId={form.id}
                    exclusionReasons={exclusionReasons}
                    referenceComments={activeReferenceData.reference_comments}
                    handleInclude={handleInclude}
                    handleExclude={handleExclude}
                    conflictsResolved={conflictsResolved}
                    screeningTags={screeningTags}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  id="comments"
                  isOpen={expandedSections.includes('comments')}
                  onToggle={toggleSection}
                  title={
                    <div className="flex items-center">
                      <span css={{ lineHeight: '1.25rem' }}>
                        <Trans>Comments</Trans> ({activeReferenceData.reference_comments.length})
                      </span>{' '}
                      <Tag className="ml-2">{activeReferenceResult?.result.tags.length ?? 0}</Tag>
                    </div>
                  }
                  titleClass="text-gray-700 px-4 py-1 text-xl h-12 border-b"
                  titleCss={lightGray5bg}
                  collapseContainerCss={lightGray4bg}
                  collapseContainerClass="border-b"
                >
                  <div className="p-4">
                    <MyReferenceComment
                      comment={myComment}
                      onDelete={onDeleteComment}
                      onUpdate={onUpdateComment}
                    />
                  </div>
                  <div className="p-4">
                    <ReferenceCommentsByStage comments={otherComments} stages={projectStages} />
                  </div>
                  <Divider className="m-0" />
                  <div className="p-4">
                    <ScreeningTags
                      tags={screeningTags}
                      selectedTagIds={activeReferenceResult?.result.tags}
                      onTagsChange={onUpdateResultTags}
                      readOnly={conflictsResolved}
                    />
                  </div>
                </CollapsibleSection>
              </CollapsibleSections>
              <div className="p-3">
                <TiabConflictResolutionControls
                  formData={form.form}
                  disabled={activeReferenceData == null || conflictsResolved}
                  onExclude={handleExclude}
                  onInclude={handleInclude}
                  selectedRefsCounts={selectedRefsCounts}
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <Drawer
        onClose={() => setCriteriaOpen(false)}
        isOpen={criteriaOpen}
        title={<Trans>Inclusion and Exclusion Criteria</Trans>}
        size="50%"
      >
        <div className={Classes.DRAWER_BODY}>
          <CriteriaScreener projectId={projectId} stageId={stageId} />
        </div>
      </Drawer>
      <NavAndDecisionControls
        onlyHotkeys
        onToggleActiveReferenceSelect={noop}
        onShiftActiveReference={shiftActiveReference}
        onInclude={noop}
        onExclude={noop}
        onPostpone={noop}
        exclusionReasons={[]}
      />
    </div>
  );
};

export default TiAbConflictsList;
