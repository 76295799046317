/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { addYears, endOfYear, format, parse, startOfDay, toDate } from 'date-fns/fp';
import {
  Button,
  Card,
  Checkbox,
  Colors,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  TextArea,
  Icon,
  MenuItem,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import React, { Fragment, ReactNode, useCallback, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { filter, get, identity, isEmpty, isNil, toUpper } from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '../../keycloak';
import { ProjectStatus, ProjectsFolder, Role, StageType } from '../../common/types';
import AppToaster from '../../lib/toaster';
import { useCurrCallback, useI18n, useSetState } from '../../lib/utils';
import useActionLogger from '../hooks/use_action_logger';
import StandardWorkflowDiagram from './assets/standard_workflow.svg';
import TitleAndAbstractWorkflowDiagram from './assets/title_and_abstract_workflow.svg';
import { IconNames } from '@blueprintjs/icons';
import { Suggest } from '@blueprintjs/select';
import { folderSelectOptionRenderer } from './helpers';

const singleStageScreeningVisible: boolean =
  window.REACT_APP_SINGLE_STAGE_SCREENING_VISIBLE === 'true' ?? false;

const activeCard = css`
  border: 1px solid ${Colors.BLUE3};
`;

const UpdateQuestionMutation = loader('../../graphql/update_question.gql');

const CreateProjectMutation = gql`
  mutation CreateProjectMutation($project: project_insert_input!) {
    insert_project_one(object: $project) {
      id
      questions {
        id
      }
      stages {
        id
      }
    }
  }
`;

const CreateFormsMutation = gql`
  mutation CreateForms($forms: [form_insert_input!]!) {
    insert_form(objects: $forms) {
      returning {
        id
      }
    }
  }
`;

const UpdateProjectMutation = gql`
  mutation UpdateProject($projectId: uuid!, $projectData: project_set_input!) {
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $projectData) {
      id
      name
      due_date
      folder_id
    }
  }
`;

const FolderSuggest = Suggest.ofType<ProjectsFolder | null>();

enum WorkflowType {
  Standard = 'standard',
  Custom = 'custom',
  SingleStage = 'single-stage',
}

interface IWorkflowCardProps {
  onClick: () => void;
  selected: boolean;
  title: ReactNode;
  showChildrenWhenNotSelected?: boolean;
}

const WorkflowCard: React.FC<IWorkflowCardProps> = ({
  children,
  onClick,
  selected,
  showChildrenWhenNotSelected = false,
  title,
}) => (
  <Card className="my-2" css={selected ? activeCard : undefined} interactive onClick={onClick}>
    <div className="flex flex-row justify-between items-center">
      <div>{title}</div>
      {selected && <Icon color={Colors.BLUE1} icon={IconNames.TICK_CIRCLE} />}
    </div>
    {(selected || showChildrenWhenNotSelected) && children}
  </Card>
);

interface ICreateProjectDialogProps {
  isOpen: boolean;
  onClose: () => void;
  folders: ProjectsFolder[];
  projectId?: string;
  projectName?: string;
  dueDate?: string;
  folderId?: string | null;
}

const DUE_DATE_FORMAT = 'dd/MM/yyyy';
interface IProjectEditState {
  projectName: string;
  dueDate: Date | null;
  folder: ProjectsFolder | null;
  selectedWorkflow: WorkflowType;
  workflowStages: Record<StageType, boolean>;
}

const getInitialState = (
  folders: ProjectsFolder[],
  projectName?: string,
  dueDate?: string,
  folderId?: string | null
): IProjectEditState => ({
  projectName: projectName ?? '',
  dueDate: dueDate ? toDate(new Date(dueDate)) : null,
  folder: folders.find((folder) => folder.id === folderId) ?? null,
  selectedWorkflow: WorkflowType.Standard,
  workflowStages: {
    [StageType.PreliminaryScreening]: false,
    [StageType.TitlesAbstractScreening]: true,
    [StageType.FullTextScreening]: true,
    [StageType.DataExtraction]: false,
  },
});

const CreateOrEditProjectDialog: React.FC<ICreateProjectDialogProps> = ({
  isOpen,
  onClose,
  folders,
  projectId,
  projectName,
  dueDate,
  folderId,
}) => {
  const insertActionLog = useActionLogger();
  const [state, setState] = useSetState<IProjectEditState>(
    getInitialState(folders, projectName, dueDate, folderId)
  );
  const {
    projectName: newProjectName,
    dueDate: newDueDate,
    folder: newFolder,
    selectedWorkflow,
    workflowStages,
  } = state;
  const [createProject, { loading: creatingProject }] = useMutation(CreateProjectMutation);
  const [createForms, { loading: creatingForms }] = useMutation(CreateFormsMutation);
  const [updateQuestion, { loading: updatingQuestions }] = useMutation(UpdateQuestionMutation);
  const [updateProject, { loading: updatingProject }] = useMutation(UpdateProjectMutation);
  const { user } = useKeycloak();
  const history = useHistory();
  const i18n = useI18n();
  const isNewProject = projectId == null;

  const handleNameInput = useCallback(
    (evt) => {
      setState({ projectName: evt.target.value.replace(/\n/g, ' ') });
    },
    [setState]
  );

  const workflowStepChanged = useCallback(
    (key) => (e) => {
      const value = e.target.checked;
      setState((state) => ({
        ...state,
        workflowStages: { ...state.workflowStages, [key]: value },
      }));
    },
    [setState]
  );

  const setSelectedWorkflow = useCallback(
    (newWorkflow: WorkflowType) => () => {
      setState({ selectedWorkflow: newWorkflow });
    },
    [setState]
  );

  const renderWorkflowCheckbox = (key: StageType, title: MessageDescriptor) => (
    <Checkbox checked={workflowStages[key]} onChange={workflowStepChanged(key)}>
      {i18n._(title)}
    </Checkbox>
  );

  const showErrorToast = useCallback((error) => {
    AppToaster.show({
      intent: Intent.WARNING,
      message: (
        <Trans>Project creation failed: {error.message || <Trans>unknown error</Trans>}</Trans>
      ),
    });
  }, []);

  const handleClose = useCallback(() => {
    setState(getInitialState(folders, projectName, dueDate, folderId));
    onClose();
  }, [folders, projectName, dueDate, onClose, setState, folderId]);

  const handleProjectUpdate = useCallback(() => {
    updateProject({
      variables: {
        projectId,
        projectData: {
          name: newProjectName,
          due_date: newDueDate ? format('yyyy-MM-dd', new Date(newDueDate)) : null,
          folder_id: newFolder ? newFolder.id : null,
        },
      },
    }).then(() => insertActionLog('updated project', { projectId, newProjectName, newDueDate }));
    handleClose();
  }, [
    updateProject,
    projectId,
    newProjectName,
    newDueDate,
    newFolder,
    handleClose,
    insertActionLog,
  ]);

  // complex action consisting of 3 mutations: project creation, screening form creation and
  // question update (with form id)
  // FIXME: combine these 3 mutations in single one. hasura will execute them sequentially within a
  // single transaction. So, if any of them fails all executed ones will get rolled back, which is
  // not the case with current implementation
  const handleProjectCreate = useCallback(async () => {
    const stageData: any[] = [];
    if (
      selectedWorkflow === WorkflowType.Custom &&
      workflowStages[StageType.PreliminaryScreening]
    ) {
      stageData.push({
        name: i18n._(t`Preliminary Screening`),
        order_number: stageData.length + 1,
        screening_type_id: 'double',
        type: StageType.PreliminaryScreening,
      });
    }
    if (
      [WorkflowType.Standard, WorkflowType.SingleStage].includes(selectedWorkflow) ||
      (selectedWorkflow === WorkflowType.Custom &&
        workflowStages[StageType.TitlesAbstractScreening])
    ) {
      stageData.push({
        name: i18n._(t`Title and Abstract Screening`),
        order_number: stageData.length + 1,
        screening_type_id: 'double',
        type: StageType.TitlesAbstractScreening,
      });
    }
    if (
      selectedWorkflow === WorkflowType.Standard ||
      (selectedWorkflow === WorkflowType.Custom && workflowStages[StageType.FullTextScreening])
    ) {
      stageData.push({
        name: i18n._(t`Full Text Screening`),
        order_number: stageData.length + 1,
        screening_type_id: 'double',
        type: StageType.FullTextScreening,
      });
    }
    if (selectedWorkflow === WorkflowType.Custom && workflowStages[StageType.DataExtraction]) {
      stageData.push({
        name: i18n._(t`Data Extraction`),
        order_number: stageData.length + 1,
        screening_type_id: 'double',
        type: StageType.DataExtraction,
      });
    }

    try {
      const { data } = await createProject({
        variables: {
          project: {
            name: newProjectName,
            due_date: newDueDate ? format('yyyy-MM-dd', new Date(newDueDate)) : null,
            folder_id: isNil(newFolder) ? null : isEmpty(newFolder.id) ? undefined : newFolder.id,
            folder:
              isNil(newFolder) || !isEmpty(newFolder.id)
                ? undefined
                : {
                    data: {
                      name: newFolder.name,
                    },
                  },
            stages: {
              data: stageData,
            },
            team_members: {
              data: [
                {
                  user_id: user.id,
                  role: Role.Manager,
                },
              ],
            },
          },
        },
      });
      insertActionLog('created a project', { newProjectName, newDueDate });
      handleClose();
      // redirect to new project
      const projectId = get('insert_project_one.id', data);
      history.push(`/projects/${projectId}/dashboard`);
    } catch (error) {
      showErrorToast(error);
    }
  }, [
    newProjectName,
    newDueDate,
    newFolder,
    createProject,
    createForms,
    history,
    i18n,
    workflowStages[StageType.PreliminaryScreening],
    workflowStages[StageType.TitlesAbstractScreening],
    workflowStages[StageType.FullTextScreening],
    workflowStages[StageType.DataExtraction],
    selectedWorkflow,
    updateQuestion,
    user.id,
    handleClose,
    showErrorToast,
    insertActionLog,
  ]);

  const workflowStagesCount = filter(
    identity,
    selectedWorkflow === WorkflowType.Standard
      ? getInitialState(folders).workflowStages
      : workflowStages
  ).length;
  const isCreateButtonDisabled = isEmpty(newProjectName) || workflowStagesCount === 0;

  const fromDateValue = dueDate ? toDate(new Date(dueDate)) : toDate(startOfDay(new Date()));
  const toDateValue = toDate(addYears(9, endOfYear(new Date())));

  const renderFolderSelectOption = useCurrCallback(folderSelectOptionRenderer, []);

  const handlePrepareNewFolderFromQuery = useCallback((query: string): ProjectsFolder | null => {
    return isEmpty(query)
      ? null
      : {
          id: '',
          name: query,
          created_at: '',
          deleted_at: null,
          status: ProjectStatus.Active,
          projects: [],
          projects_aggregate: { aggregate: { count: 0 } },
        };
  }, []);

  const renderCreateNewFolder = useCallback(
    (query, active, handleClick) => (
      <MenuItem
        intent={Intent.PRIMARY}
        icon={IconNames.PLUS}
        active={active}
        text={i18n._(t`Add folder`)}
        onClick={handleClick}
      />
    ),
    []
  );

  useEffect(() => {
    setState(getInitialState(folders, projectName, dueDate, folderId));
  }, [setState, folders, projectName, dueDate, folderId]);

  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={isOpen}
      onClose={onClose}
      title={isNewProject ? <Trans>Create new project</Trans> : <Trans>Project settings</Trans>}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="text-xl my-2">
          <Trans>Project details</Trans>
        </div>
        <FormGroup
          label={<Trans>Project name</Trans>}
          labelFor="projectName"
          labelInfo={<Trans>(required)</Trans>}
        >
          <TextArea
            className="w-full resize-none h-24 mb-4"
            id="projectName"
            name="projectName"
            onChange={handleNameInput}
            value={newProjectName}
            autoFocus
          />
        </FormGroup>
        <FormGroup label={<Trans>Project due date</Trans>} labelFor="projectDueDate">
          <DateInput
            fill
            inputProps={{
              name: 'dueDate',
            }}
            formatDate={(date) => format(DUE_DATE_FORMAT, date)}
            maxDate={toDateValue}
            minDate={fromDateValue}
            onChange={(dueDate) => setState({ dueDate })}
            parseDate={(str) => (str ? parse(new Date(), DUE_DATE_FORMAT, str) : null)}
            placeholder={toUpper(DUE_DATE_FORMAT)}
            popoverProps={{ usePortal: false }}
            value={newDueDate}
          />
        </FormGroup>
        <FormGroup label={<Trans>Folder</Trans>} labelFor="projectFolder">
          <FolderSuggest
            fill
            items={[null, ...folders]}
            itemRenderer={renderFolderSelectOption(newFolder)}
            createNewItemFromQuery={projectId ? undefined : handlePrepareNewFolderFromQuery}
            createNewItemRenderer={renderCreateNewFolder}
            createNewItemPosition="last"
            itemsEqual={(itemA, itemB) => itemA?.name === itemB?.name}
            itemListPredicate={(query, items) =>
              items.filter(
                (item) => (isEmpty(query) && item === null) || item?.name.includes(query)
              )
            }
            inputValueRenderer={(item) => item?.name ?? ''}
            inputProps={{ placeholder: i18n._(t`Type in the folder name`) }}
            popoverProps={{ minimal: true }}
            onItemSelect={(item) =>
              setState({
                folder: item,
              })
            }
            selectedItem={newFolder}
          />
        </FormGroup>
        {isNewProject && (
          <FormGroup
            label={
              <div className="text-xl my-2">
                <Trans>Project configuration</Trans>
              </div>
            }
          >
            {singleStageScreeningVisible ? (
              <WorkflowCard
                onClick={setSelectedWorkflow(WorkflowType.SingleStage)}
                selected={selectedWorkflow === WorkflowType.SingleStage}
                showChildrenWhenNotSelected
                title={<Trans>Single stage workflow</Trans>}
              >
                {/* FIXME: the diagram is in English */}
                <img
                  className="mt-4 w-full"
                  src={TitleAndAbstractWorkflowDiagram}
                  alt={i18n._(t`Title and abstract screening`)}
                />
              </WorkflowCard>
            ) : null}
            <WorkflowCard
              onClick={setSelectedWorkflow(WorkflowType.Standard)}
              selected={selectedWorkflow === WorkflowType.Standard}
              showChildrenWhenNotSelected
              title={<Trans>Standard workflow</Trans>}
            >
              {/* FIXME: the diagram is in English */}
              <img
                className="mt-4 w-full"
                src={StandardWorkflowDiagram}
                alt={i18n._(t`Title and abstract screening, Full-text screening`)}
              />
            </WorkflowCard>
            <WorkflowCard
              onClick={setSelectedWorkflow(WorkflowType.Custom)}
              selected={selectedWorkflow === WorkflowType.Custom}
              title={<Trans>Custom workflow</Trans>}
              showChildrenWhenNotSelected
            >
              <div className="mt-4">
                {selectedWorkflow === WorkflowType.Custom ? (
                  <Fragment>
                    {renderWorkflowCheckbox(
                      StageType.PreliminaryScreening,
                      t`Preliminary screening`
                    )}
                    {renderWorkflowCheckbox(
                      StageType.TitlesAbstractScreening,
                      t`Title and abstract screening`
                    )}
                    {renderWorkflowCheckbox(StageType.FullTextScreening, t`Full-text screening`)}
                    {/* TODO: T5152 - replace the checkbox below with the commented code when Dexter becomes available */}
                    {/* {renderWorkflowCheckbox(StageType.DataExtraction, t`Data extraction`)} */}
                    <Checkbox disabled>{i18n._(t`Data extraction (Laser 2.0)`)}</Checkbox>
                    {workflowStagesCount === 0 && (
                      <span className="text-red-700">
                        <Trans>You need to choose at least one stage.</Trans>
                      </span>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Trans>Configure stages as needed</Trans>.
                  </Fragment>
                )}
              </div>
            </WorkflowCard>
          </FormGroup>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button large text={<Trans>Close</Trans>} onClick={onClose} />
          <Button
            disabled={isCreateButtonDisabled}
            large
            text={isNewProject ? <Trans>Create</Trans> : <Trans>Save</Trans>}
            intent={Intent.SUCCESS}
            loading={creatingProject || creatingForms || updatingQuestions || updatingProject}
            onClick={isNewProject ? handleProjectCreate : handleProjectUpdate}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CreateOrEditProjectDialog;
