/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode, useCallback } from 'react';
import { useCurrCallback } from '../../../lib/utils';
import {
  TActiveAttributeFilters,
  TActiveScreeningTagFilters,
} from '../../../apollo/screening_state';
import { t, Trans } from '@lingui/macro';
import { AttributeFiltersTag } from '../../screening/screening_filters';
import { TDomainReasonsData } from './';
import {
  ftDecisionConflict,
  ftDecisionToReview,
  preliminaryDecisionConflict,
  preliminaryDecisionIn,
  preliminaryDecisionToReview,
  tiabDecisionConflict,
  tiabDecisionIn,
  tiabDecisionToReview,
} from '../../../lib/task_helpers';
import { StageType } from '../../../common/types';
import { TExclusionReason } from '../../screening';
import i18n from '../../../i18n';

function getStageTitle(stageType: StageType) {
  switch (stageType) {
    case StageType.PreliminaryScreening:
      return <Trans>Preliminary screening</Trans>;
    case StageType.TitlesAbstractScreening:
      return <Trans>Title and abstract screening</Trans>;
    case StageType.FullTextScreening:
      return <Trans>Full text screening</Trans>;
    default:
      return null;
  }
}

type TStatusCode = Pick<TExclusionReason, 'id' | 'code'> & {label?: string};

interface IStatusCodes {
  inclusionCodes: TStatusCode[];
  exclusionCodes: TStatusCode[];
  conflictCode: TStatusCode| null;
  toReviewCode: TStatusCode | null;
}

function getStatusCodes(stageType: StageType, domains: TDomainReasonsData): IStatusCodes {
  switch (stageType) {
    case StageType.PreliminaryScreening:
      return {
        inclusionCodes: [
          {
            id: preliminaryDecisionIn,
            code: i18n._(t`In`),
          },
        ],
        exclusionCodes: domains.preliminary,
        conflictCode: {
          id: preliminaryDecisionConflict,
          code: i18n._(t`Conflict`),
        },
        toReviewCode: {
          id: preliminaryDecisionToReview,
          code: i18n._(t`Not evaluated`),
        },
      };
    case StageType.TitlesAbstractScreening:
      return {
        inclusionCodes: [
          {
            id: tiabDecisionIn,
            code: i18n._(t`In`),
          },
        ],
        exclusionCodes: domains.titlesAbstracts,
        conflictCode: {
          id: tiabDecisionConflict,
          code: i18n._(t`Conflict`),
        },
        toReviewCode: {
          id: tiabDecisionToReview,
          code: i18n._(t`Not evaluated`),
        },
      };
    case StageType.FullTextScreening:
      return {
        inclusionCodes: domains.fullText.inclusion,
        exclusionCodes: domains.fullText.exclusion,
        conflictCode: {
          id: ftDecisionConflict,
          code: i18n._(t`Conflict`),
        },
        toReviewCode: {
          id: ftDecisionToReview,
          code: i18n._(t`Not evaluated`),
        },
      };
    default:
      return {
        inclusionCodes: [],
        exclusionCodes: [],
        conflictCode: null,
        toReviewCode: null,
      };
  }
}

interface IScreeningFiltersProps {
  stageType: StageType;
  domains: TDomainReasonsData;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  onActiveAttributeToggle: (data: {
    key: string;
    attribute: string;
    label: string;
    active: boolean;
  }) => void;
  isAttributeActive: (
    filterObject: TActiveAttributeFilters | TActiveScreeningTagFilters,
    key: string
  ) => boolean;
}

const StageStatusFilters: React.FC<IScreeningFiltersProps> = ({
  stageType,
  domains,
  activeDecisionCodeFilters,
  onActiveAttributeToggle,
  isAttributeActive,
}) => {
  const stageTitle: ReactNode = getStageTitle(stageType);
  const { inclusionCodes, exclusionCodes, conflictCode, toReviewCode } = getStatusCodes(
    stageType,
    domains
  );

  const handleToggleActiveAttribute = useCurrCallback(
    (key: string, attribute: string, label: string, active: boolean) => {
      onActiveAttributeToggle({ key, attribute, label, active });
    },
    [onActiveAttributeToggle]
  );

  const renderStatusTag = useCallback(
    ({ id, code, label }: TStatusCode): ReactNode => {
      return (
        <AttributeFiltersTag
          key={id}
          onToggle={handleToggleActiveAttribute('activeDecisionCodeFilters')}
          attribute={id}
          label={code}
          active={isAttributeActive(activeDecisionCodeFilters, id)}
          htmlTitle={label}
        />
      );
    },
    [isAttributeActive, activeDecisionCodeFilters, handleToggleActiveAttribute]
  );

  return (
    <div className="px-4 py-2">
      <div className="rounded border bg-white">
        <div className="border-b p-3">
          <span className="font-bold">{stageTitle}</span>
        </div>
        <div className="flex p-3 flex-wrap">
          {inclusionCodes.map(renderStatusTag)}
          {exclusionCodes.map(renderStatusTag)}
          {conflictCode && renderStatusTag(conflictCode)}
          {toReviewCode && renderStatusTag(toReviewCode)}
        </div>
      </div>
    </div>
  );
};

export default StageStatusFilters;
