/** @jsx jsx */
import { Button, Classes, Icon, IconSize, Tab, TabId, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React, { Fragment, ReactNode } from 'react';
import { screeningListColHeaderHeight } from '../../common/styles';
import {
  ProjectCounts,
  ProjectsListBatchAction,
  IManagerTab,
  ManagerTabIds,
} from '../../common/types';
import { useKeycloak } from '../../keycloak';
import { mapTabIdToProjectStatus } from './helpers';

const managerTabsCss = css`
  .${Classes.TABS} {
    .${Classes.TAB_LIST} {
      height: 100%;
      align-items: center;
      padding: 0 10px;
    }
    .${Classes.TAB_PANEL} {
      height: 100%;
      margin-top: 0;
      display: flex;
      flex-flow: column;
      overflow: auto;
    }
  }
`;

const projectsListHeaderCss = css`
  ${screeningListColHeaderHeight}
  ${managerTabsCss}
`;

const TabTitle = ({ title, iconName }) => (
  <div className="h-full mx-3 flex flex-row items-center">
    <Icon iconSize={IconSize.STANDARD} icon={iconName} />
    <span className="ml-2">{title}</span>
  </div>
);

interface IProjectsListHeaderProps {
  tabs: IManagerTab[];
  selectedTabId: TabId;
  handleChangeSelectedTab: (tabId: TabId) => void;
  projectCounts: ProjectCounts;
  toggleCreatingProject: () => void;
  toggleCreatingFolder: () => void;
  batchActions: ProjectsListBatchAction[];
  batchSize: number;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
}

const ProjectsListHeader: React.FC<IProjectsListHeaderProps> = ({
  tabs,
  selectedTabId,
  handleChangeSelectedTab,
  projectCounts,
  toggleCreatingProject,
  toggleCreatingFolder,
  batchActions,
  batchSize,
  leftElement,
  rightElement,
}) => {
  const { hasAccessToAllProjects: hasFullAccess } = useKeycloak();

  return (
    <div className="flex flex-row border-b border-gray-400" css={projectsListHeaderCss}>
      {leftElement}
      <div className="w-full flex flex-row justify-between">
        <Tabs
          selectedTabId={selectedTabId}
          onChange={handleChangeSelectedTab}
          large
          renderActiveTabPanelOnly
          className="h-full flex flex-col overflow-auto flex-shrink-0"
        >
          <div className="text-base">
            <Trans>Projects</Trans>:
          </div>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              id={tab.id}
              title={<TabTitle title={tab.title} iconName={tab.titleIcon} />}
              className="h-full"
              disabled={projectCounts[mapTabIdToProjectStatus(tab.id)] === 0}
            />
          ))}
          {projectCounts.SCREENING > 0 && (
            <Tab
              id={ManagerTabIds.Screening}
              title={
                <TabTitle
                  iconName={IconNames.DESKTOP}
                  title={<Trans>My screening projects</Trans>}
                />
              }
              className="h-full"
            />
          )}
        </Tabs>
        {hasFullAccess && (
          <div className="flex flex-row py-2">
            {batchSize === 0 ? (
              <Fragment>
                <Button
                  className="ml-2"
                  text={<Trans>Create folder</Trans>}
                  outlined
                  icon={IconNames.FOLDER_NEW}
                  onClick={toggleCreatingFolder}
                />
                <Button
                  className="mx-2"
                  text={<Trans>Create project</Trans>}
                  outlined
                  icon={IconNames.ADD}
                  onClick={toggleCreatingProject}
                />
              </Fragment>
            ) : (
              <Fragment>
                <div className="flex items-center mr-2">
                  <Trans>Selected projects</Trans>:{' '}
                  <span className="ml-1 font-bold">{batchSize}</span>
                </div>
                {batchActions.map((action, key) => (
                  <Button
                    key={key}
                    className="mx-2"
                    text={action.title}
                    icon={action.icon}
                    onClick={action.handler}
                  />
                ))}
              </Fragment>
            )}
          </div>
        )}
        {rightElement}
      </div>
    </div>
  );
};

export default ProjectsListHeader;
