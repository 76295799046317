/** @jsx jsx */
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { get } from 'lodash/fp';
import i18n from '../../i18n';
import { AppContentProps, Role, TeamMember } from '../../common/types';
import { useI18n } from '../../lib/utils';
import gql from 'graphql-tag';
import { useKeycloak } from '../../keycloak';
import { useSubscription } from '@apollo/react-hooks';
import Sidebar, { TAppModule } from '../common/sidebar';

const myTeamMemberSubscription = gql`
  subscription MyTeamMember($projectId: uuid!, $userId: String!) {
    me: team_member(where: { project_id: { _eq: $projectId }, user_id: { _eq: $userId } }) {
      id
      role
    }
  }
`;

const PROJECT_MODULES: TAppModule[] = [
  {
    path: '/dashboard',
    caption: i18n._(t`Project`),
    icon: IconNames.FOLDER_CLOSE,
    moduleType: 'project',
    restricted: true,
  },
  {
    path: '/team-members',
    caption: i18n._(t`Team`),
    icon: IconNames.PEOPLE,
    moduleType: 'project',
    restricted: true,
  },
  {
    path: '/references',
    caption: i18n._(t`References`),
    icon: IconNames.DOCUMENT,
    moduleType: 'project',
  },
  {
    path: '/stages',
    caption: i18n._(t`Review`),
    icon: IconNames.SEARCH_TEMPLATE,
    moduleType: 'project',
    restricted: true,
  },
  {
    path: '/prisma',
    caption: i18n._(t`Reports`),
    icon: IconNames.DIAGRAM_TREE,
    moduleType: 'project',
  },
];

const BOTTOM_SIDEBAR_MODULES: TAppModule[] = [
  {
    path: '/',
    caption: i18n._(t`Go to projects`),
    icon: IconNames.PROJECTS,
    moduleType: 'admin',
  },
];

interface IProjectSidebarProps extends AppContentProps {}

const ProjectSidebar: React.FC<IProjectSidebarProps> = ({ match, location }) => {
  const { projectId } = match.params;
  const i18n = useI18n();
  const { user, hasAccessToAllProjects: hasFullAccess } = useKeycloak();

  const { data } = useSubscription<{ me: Pick<TeamMember, 'id' | 'role'>[] }>(
    myTeamMemberSubscription,
    {
      variables: { projectId, userId: user.id },
    }
  );
  const me: Pick<TeamMember, 'id' | 'role'> | undefined = get('me[0]', data);

  const modulesList = useMemo(() => {
    const modules = [...PROJECT_MODULES].filter(
      ({ restricted }) => (restricted ?? false) === false || hasFullAccess
    );

    if (me && me.role === Role.Screener) {
      modules.unshift({
        path: '/screening',
        caption: i18n._(t`Screening`),
        icon: IconNames.EYE_OPEN,
        moduleType: 'project',
      });
    }
    return modules;
  }, [me, i18n, hasFullAccess]);

  return (
    <Sidebar
      location={location}
      modules={modulesList}
      bottomModules={BOTTOM_SIDEBAR_MODULES}
      projectId={projectId}
    />
  );
};

export default ProjectSidebar;
