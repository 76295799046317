import { Spinner } from '@blueprintjs/core';
import * as Sentry from '@sentry/browser';
import Keycloak, { KeycloakConfig, KeycloakInstance, KeycloakProfile } from 'keycloak-js';
import { first, get, isArray, mapValues, pick } from 'lodash/fp';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { AccountType, Timestamp, User, UserAttributes } from './common/types';
import { useSetState } from './lib/utils';

const HASURA_CLAIMS_KEY = 'https://hasura.io/jwt/claims';
const HASURA_DEFAULT_ROLE_KEY = 'x-hasura-default-role';
const USER_ATTRIBUTES = [
  'salutation',
  'title',
  'phoneAreaCode',
  'phoneNumber',
  'institutionName',
  'institutionWebsite',
  'institutionAreaCode',
  'institutionPhone',
  'institutionAddress',
  'institutionCity',
  'institutionPostalCode',
  'institutionCountry',
];

type HasuraClaims = Record<typeof HASURA_DEFAULT_ROLE_KEY, string | undefined>;
type MaybeHasuraClaims = HasuraClaims | undefined;
type TokenWithHasuraClaims = Record<typeof HASURA_CLAIMS_KEY, MaybeHasuraClaims>;

export interface KeycloakContext {
  keycloak: KeycloakInstance;
  initialized: boolean;
  authenticated: boolean;
  user: User;
  getToken: () => Promise<string>;
  logout: () => Promise<void>;
  isAdmin: boolean;
  isItAdmin: boolean;
  hasAccessToAllProjects: boolean;
}

const config: KeycloakConfig = {
  url:
    process.env.REACT_APP_KEYCLOAK_URL ||
    `${window.location.protocol}//auth.${window.location.host}`,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'gba',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'gba-app',
};

const keycloak = Keycloak(config);
const keycloakContext = createContext<KeycloakContext>({} as KeycloakContext);

async function getToken() {
  if (keycloak.isTokenExpired(5)) {
    try {
      await keycloak.updateToken(5);
    } catch (err) {
      keycloak.login();
      throw err;
    }
  }
  return keycloak.token!;
}

async function logout(): Promise<void> {
  await keycloak.logout({ redirectUri: window.location.origin });
}

function getRoleFromToken(): AccountType | undefined {
  if (!keycloak.authenticated) return undefined;
  const token = (keycloak.tokenParsed || {}) as TokenWithHasuraClaims;
  return token?.[HASURA_CLAIMS_KEY]?.[HASURA_DEFAULT_ROLE_KEY] as AccountType;
}

function parseAttribute(attribute) {
  if (isArray(attribute)) {
    return first(attribute);
  }
  return attribute;
}

interface KeycloakState {
  initialized: boolean;
  authenticated: boolean;
  user: User;
  isAdmin: boolean;
  isItAdmin: boolean;
  hasAccessToAllProjects: boolean;
}

const INITIAL_STATE: KeycloakState = {
  initialized: false,
  authenticated: false,
  user: {} as User,
  isAdmin: false,
  isItAdmin: false,
  hasAccessToAllProjects: false,
};

export const KeycloakProvider: React.FC = ({ children }) => {
  const [state, setState] = useSetState(INITIAL_STATE);
  const { initialized, authenticated, user, isAdmin, isItAdmin, hasAccessToAllProjects } = state;

  const updateUser = useCallback(
    (userProfile: KeycloakProfile) => {
      const token = (keycloak.tokenParsed as object) ?? {};
      const createdAt: number | undefined = token['createdAt'];
      const hasAccessToAllProjects = get('attributes.hasAccessToAllProjects', token) === true;
      const attributes: UserAttributes = mapValues(
        parseAttribute,
        pick(USER_ATTRIBUTES, (token['attributes'] ?? {}) as UserAttributes)
      );
      const role = getRoleFromToken()!;
      setState({
        user: {
          id: keycloak.tokenParsed!.sub!,
          email: userProfile.email!,
          firstName: userProfile.firstName!,
          lastName: userProfile.lastName!,
          name: `${userProfile.firstName!} ${userProfile.lastName!}`,
          role,
          username: userProfile.username,
          createdAt:
            createdAt == null ? undefined : (new Date(createdAt).toISOString() as Timestamp),
          ...attributes,
        },
        isAdmin: role !== AccountType.User,
        isItAdmin: role === AccountType.ItAdmin,
        hasAccessToAllProjects,
      });
    },
    [setState]
  );

  useEffect(() => {
    keycloak.init({}).then((authenticated) => {
      setState({ authenticated });

      if (!authenticated) {
        keycloak.login();
      } else {
        keycloak
          .loadUserProfile()
          .then(updateUser)
          .then(() => {
            keycloak.onAuthLogout = () => {
              keycloak.login();
            };
            keycloak.onAuthRefreshSuccess = () => {
              keycloak
                .loadUserProfile()
                .then(updateUser)
                .catch(() => keycloak.login());
            };
            keycloak.onAuthRefreshError = () => {
              keycloak.login();
            };
            keycloak.onAuthError = (error) => {
              Sentry.captureException(error, { extra: { type: 'auth_error' } });
              keycloak.login();
            };
          })
          .catch((err) => keycloak.login())
          .finally(() => {
            setState({ initialized: true });
          });
      }
    });
  }, [setState, updateUser]);

  const keycloakContextValue: KeycloakContext = {
    initialized,
    authenticated,
    user,
    keycloak,
    getToken,
    logout,
    isAdmin,
    isItAdmin,
    hasAccessToAllProjects,
  };

  return (
    <keycloakContext.Provider value={keycloakContextValue}>
      {initialized ? children : <Spinner className="h-screen" />}
    </keycloakContext.Provider>
  );
};

export const useKeycloak: () => KeycloakContext = () => useContext(keycloakContext);
