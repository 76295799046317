import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { I18nProvider } from '@lingui/react';
import i18n from './i18n';
import { KeycloakProvider } from './keycloak';
import { I18nHookProvider } from './lingui/i18nHookContext';

declare global {
  interface Window {
    REACT_APP_DEFAULT_LOCALE: string;
    REACT_APP_SINGLE_STAGE_SCREENING_VISIBLE: string;
    REACT_APP_DOCTYPE_COLUMN_VISIBLE: string;
    REACT_APP_LANGUAGE_COLUMN_VISIBLE: string;
    REACT_APP_BASE_STAGE_TEMPLATE_PRELIMINARY: string;
    REACT_APP_BASE_STAGE_TEMPLATE_TITLES_ABSTRACT: string;
    REACT_APP_BASE_STAGE_TEMPLATE_FULL_TEXT: string;
    REACT_APP_ALTERNATE_EXCLUSION_HOTKEYS_ENABLED: string;
    REACT_APP_ALTERNATE_INCLUSION_HOTKEY: string;
  }
}

ReactDOM.render(
  <I18nProvider language={window.REACT_APP_DEFAULT_LOCALE} i18n={i18n}>
    <I18nHookProvider>
      <KeycloakProvider>
        <App />
      </KeycloakProvider>
    </I18nHookProvider>
  </I18nProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
