/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactNode } from 'react';
import { Trans } from '@lingui/macro';
import { SearchAndDesiredUndesiredKeywords } from '../../lib/criteria_utils';
import { getShortCitation } from '../references/helpers';
import TextWithHighlights from '../common/text_with_highlights';
import { breakWordCss } from '../../common/styles';
import { Reference } from '../../common/types';
import { get } from 'lodash/fp';
import { Authors } from './title_and_abstract/study_data';

const PreviewSection = ({
  title,
  value,
  link,
}: {
  title: ReactNode;
  value?: ReactNode;
  link?: string;
}) => {
  return (
    <div className="mb-5" style={{ flex: '0 0 50%' }}>
      <div className="mb-2 text-xs text-gray-600">{title}</div>
      <div className="text-base leading-normal">
        {(value ||
          (link && (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          ))) ??
          '-'}
      </div>
    </div>
  );
};

interface IReferencePreviewProps {
  reference: Pick<Reference, 'id' | 'title' | 'attrs'>;
  keywordsData: SearchAndDesiredUndesiredKeywords;
}

const ReferencePreview: React.FC<IReferencePreviewProps> = ({ reference, keywordsData }) => {
  const onlySearchKeywords: SearchAndDesiredUndesiredKeywords = {
    desiredKeywords: [],
    undesiredKeywords: [],
    searchKeywords: keywordsData.searchKeywords,
  };
  const abstract = get('attrs.abstract', reference);
  const authors = get('attrs.authors', reference);

  return (
    <div className="p-4" css={breakWordCss}>
      <PreviewSection
        title={<Trans>Citation</Trans>}
        value={
          <TextWithHighlights
            keywordsData={onlySearchKeywords}
            text={getShortCitation(reference)}
          />
        }
      />
      <PreviewSection
        title={<Trans>Title</Trans>}
        value={
          <TextWithHighlights
            className="font-bold"
            keywordsData={onlySearchKeywords}
            text={get('title', reference)}
          />
        }
      />
      <PreviewSection
        title={<Trans>Abstract</Trans>}
        value={
          abstract ? (
            <TextWithHighlights
              className="whitespace-pre-wrap"
              keywordsData={keywordsData}
              text={abstract}
            />
          ) : undefined
        }
      />
      <PreviewSection
        title={<Trans>Authors</Trans>}
        value={authors ? <Authors authors={authors} /> : undefined}
      />

      <PreviewSection title={<Trans>Reference number</Trans>} value={get('attrs.id', reference)} />

      <PreviewSection title={<Trans>URL</Trans>} link={get('attrs.fullText', reference)} />

      <PreviewSection
        title={<Trans>Reference type</Trans>}
        value={get('attrs.itemType', reference)}
      />

      <PreviewSection title={<Trans>Journal</Trans>} value={get('attrs.venue', reference)} />

      <PreviewSection title={<Trans>Pages</Trans>} value={get('attrs.pages', reference)} />
      <PreviewSection title={<Trans>Volume</Trans>} value={get('attrs.volume', reference)} />

      <PreviewSection title={<Trans>Issue</Trans>} value={get('attrs.issue', reference)} />
      <PreviewSection title={<Trans>DOI</Trans>} value={get('attrs.doi', reference)} />
      <PreviewSection
        title={<Trans>Study identifier</Trans>}
        value={get('attrs.studyIdentifier', reference)}
      />
    </div>
  );
};

export default ReferencePreview;
