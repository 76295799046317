import { Button, Colors, Icon, IconSize, Intent, Tag, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t, Trans } from '@lingui/macro';
import {
  compact,
  compose,
  filter,
  find,
  first,
  flatMap,
  get,
  isEmpty,
  last,
  map,
  orderBy,
  overEvery,
  propEq,
  reject,
  sortBy,
  uniq,
} from 'lodash/fp';
import React, { Fragment, ReactNode } from 'react';
import { TScreeningTag } from '.';
import { alignStartCss } from '../../../common/styles';
import {
  accessionNumberCol,
  commentCol,
  docTypeCol,
  firstAuthorLastNameCol,
  languageCol,
  lastChangedCol,
  referenceNumberCol,
  screeningDecisionCol,
  statusCol,
  tagsCol,
  titleCol,
  yearCol,
} from '../../../common/table_cols';
import {
  Flatten,
  InclusionStatus,
  Reference,
  ReferencesImportTask,
  ResolutionStrategy,
  ScreeningTask,
  ScreeningTaskResult,
  ScreeningTaskType,
  Stage,
  StageType,
  Study,
  StudyPoolStudy,
  TeamMember,
  User,
} from '../../../common/types';
import { ReferenceCommentFragmentType } from '../../../graphql/reference_comment_fragment';
import i18n from '../../../i18n';
import { getResolutionStrategyLabel } from '../../../lib/conflict_resolution_helpers';
import { SearchAndDesiredUndesiredKeywords } from '../../../lib/criteria_utils';
import { IGbaTableProps, RowSelectionColumn, TableCol } from '../../common/gba_table';
import TextWithHighlights from '../../common/text_with_highlights';
import { formatDate } from '../../project/helpers';
import { TExclusionReason } from '../../screening';
import DecisionTag from '../../screening/decision_tag';
import { renderReferenceCommentsIconWithPopover } from '../../screening/references_list';
import ReferenceScreeners from '../reference_screeners';
import ReferenceTags from './reference_tags';

const docTypeColVisible: boolean = window.REACT_APP_DOCTYPE_COLUMN_VISIBLE === 'true' ?? false;
const languageColVisible: boolean = window.REACT_APP_LANGUAGE_COLUMN_VISIBLE === 'true' ?? false;

type TTaskData = Pick<ScreeningTask, 'id' | 'stage_id' | 'task_type' | 'is_draft'> & {
  team_member: Pick<TeamMember, 'id' | 'deleted_at' | 'user_id'> & {
    user: Pick<User, 'id' | 'name'>;
  };
  task_results: Pick<ScreeningTaskResult, 'form_id' | 'task_id' | 'updated_at' | 'result'>[];
};
export type TReferenceSearchData = Pick<
  Reference,
  | 'id'
  | 'title'
  | 'attrs'
  | 'deleted_at'
  | 'deleted_by'
  | 'removal_reason'
  | 'no_report'
  | 'reference_attachments_aggregate'
> & {
  study: Pick<Study, 'id' | 'deleted_at'> & {
    tasks: TTaskData[];
    study_pool_studies: (Pick<
      StudyPoolStudy,
      'inclusion_status' | 'status_reason_codes' | 'comment' | 'tags'
    > & {
      study_pool: {
        id: string;
        stage: Pick<Stage, 'id' | 'order_number' | 'type'>;
      };
    })[];
  };
  import_task: Pick<ReferencesImportTask, 'key' | 'created_at'>;
  reference_comments: ReferenceCommentFragmentType[];
};

export interface IReferenceListLayout {
  columns: TableCol<TReferenceSearchData>[];
  cellContentRenderer: IGbaTableProps<TReferenceSearchData>['cellContentRenderer'];
  numFrozenColumns?: number;
}

type TLayoutFactory<T = {}> = (dependencies: T) => IReferenceListLayout;
type TReferenceDataRenderer = (ref: TReferenceSearchData) => ReactNode;

function getReferenceAttachmentsCount(reference: TReferenceSearchData): number {
  return get('reference_attachments_aggregate.aggregate.count', reference) ?? 0;
}

function prepareLayout(
  cols: [TableCol<TReferenceSearchData>, TReferenceDataRenderer?][],
  openFocusMode: () => void,
  numFrozenColumns?: number
): IReferenceListLayout {
  const { columns, renderersMap } = cols.reduce(
    (acc, [colSpec, renderer]) => {
      acc.columns.push(colSpec);
      if (renderer) {
        acc.renderersMap[colSpec.id] = renderer;
      }
      return acc;
    },
    { columns: [], renderersMap: {} } as {
      columns: IReferenceListLayout['columns'];
      renderersMap: { [colId: string]: TReferenceDataRenderer };
    }
  );

  return {
    columns,
    cellContentRenderer: (colId, reference) => renderersMap[colId]?.(reference) ?? null,
    numFrozenColumns,
  };
}

function getStageTasksFromReference(
  reference: TReferenceSearchData,
  stageId: string,
  taskType = ScreeningTaskType.Screening
): TTaskData[] {
  return reference.study.tasks.filter(
    overEvery([propEq('stage_id', stageId), propEq('task_type', taskType)])
  );
}

function getLatestCompletedStageResultFromReference(
  reference: TReferenceSearchData
): Flatten<TReferenceSearchData['study']['study_pool_studies']> | undefined {
  const completedStudyPoolStudies = reference.study.study_pool_studies.filter(
    (sps) => sps.inclusion_status !== null && sps.inclusion_status !== InclusionStatus.Conflict
  );

  return orderBy('study_pool.stage.order_number', 'desc', completedStudyPoolStudies)[0];
}

export function getReferenceInclusionStatusAndReasonCodes(
  reference: TReferenceSearchData,
  stageType?: StageType
): {
  inclusionStatus?: InclusionStatus;
  reasonCodes: string[];
} {
  // if no stageType is specified return the latest stage's decision
  if (stageType == null) {
    // ordering could've been done on DB level (via setting order_by in query) however 1) there
    // won't be that many study_pool_studies (one per stage) to order on client and 2) it is
    // important to have them ordered desc to be able to show latest stage decision as the resulting
    // decision and doing it here (not in query) helps to keep that detail more explicit
    const studyPoolStudiesOrderer = orderBy(
      get('study_pool.stage.order_number'),
      'desc',
      reference.study.study_pool_studies
    );

    return {
      inclusionStatus: studyPoolStudiesOrderer[0]?.inclusion_status,
      reasonCodes: studyPoolStudiesOrderer[0]?.status_reason_codes ?? [],
    };
  } else {
    const stageStudyPoolStudy = find(
      (sps) => sps.study_pool.stage.type === stageType,
      reference.study.study_pool_studies
    );
    return {
      inclusionStatus: stageStudyPoolStudy?.inclusion_status,
      reasonCodes: stageStudyPoolStudy?.status_reason_codes ?? [],
    };
  }
}

const focusModeToggleRenderer =
  (activeReferenceId: string | undefined, openFocusMode: () => void): TReferenceDataRenderer =>
  (reference) => {
    return activeReferenceId === reference.id ? (
      <Tooltip content={<Trans>Focus mode</Trans>}>
        <Button
          minimal
          icon={
            <Icon icon={IconNames.FULLSCREEN} intent={Intent.PRIMARY} iconSize={IconSize.LARGE} />
          }
          intent={Intent.PRIMARY}
          onClick={openFocusMode}
        />
      </Tooltip>
    ) : null;
  };

export function renderDecisionTags({
  inclusionStatus,
  reasonCodes,
}: {
  inclusionStatus: InclusionStatus;
  reasonCodes: string[];
}) {
  return (
    <Fragment>
      {isEmpty(reasonCodes) ? (
        <DecisionTag className="mr-1" decision={inclusionStatus} />
      ) : (
        reasonCodes.map((reasonCode) => (
          <DecisionTag
            key={reasonCode}
            className="mr-1"
            decision={inclusionStatus}
            decisionReason={reasonCode}
          />
        ))
      )}
    </Fragment>
  );
}

const decisionRenderer =
  (
    activeReferenceId: string | undefined,
    openFocusMode: () => void,
    stageType?: StageType
  ): TReferenceDataRenderer =>
  (reference) => {
    const isActive = activeReferenceId === reference.id;
    const { inclusionStatus, reasonCodes } = getReferenceInclusionStatusAndReasonCodes(
      reference,
      stageType
    );
    const noReport = reference.no_report;

    return (
      <Fragment>
        {isActive && (
          <Tooltip content={<Trans>Focus mode</Trans>}>
            <Button
              minimal
              icon={
                <Icon
                  icon={IconNames.FULLSCREEN}
                  intent={Intent.PRIMARY}
                  iconSize={IconSize.LARGE}
                />
              }
              intent={Intent.PRIMARY}
              onClick={openFocusMode}
            />
          </Tooltip>
        )}
        {noReport && getReferenceAttachmentsCount(reference) === 0 ? (
          <Tag intent={Intent.DANGER}>
            <Trans>No PDF</Trans>
          </Tag>
        ) : inclusionStatus ? (
          renderDecisionTags({
            inclusionStatus,
            reasonCodes,
          })
        ) : null}
      </Fragment>
    );
  };

const firstAuthorLastNameRenderer =
  (highlightedWordsData: SearchAndDesiredUndesiredKeywords): TReferenceDataRenderer =>
  (reference) => {
    return (
      <TextWithHighlights
        keywordsData={highlightedWordsData}
        text={get('attrs.authors[0].lastName', reference) ?? '-'}
      />
    );
  };

const yearRenderer: TReferenceDataRenderer = (reference) => {
  return get('attrs.year', reference) ?? '-';
};

const titleRenderer =
  (highlightedWordsData: SearchAndDesiredUndesiredKeywords): TReferenceDataRenderer =>
  (reference) => {
    const refTitle = get('title', reference);
    return (
      <span title={refTitle}>
        <TextWithHighlights
          className="truncate"
          keywordsData={highlightedWordsData}
          text={refTitle}
        />
      </span>
    );
  };

const referenceNumberRenderer: TReferenceDataRenderer = (reference) => {
  return get('attrs.id', reference) ?? '-';
};

const accessionNumberRenderer: TReferenceDataRenderer = (reference) => {
  return get('attrs.accessionNumber', reference) ?? '-';
};

const docTypeRenderer: TReferenceDataRenderer = (reference) => {
  return get('attrs.documentType', reference) ?? '-';
};

const languageRenderer: TReferenceDataRenderer = (reference) => {
  return get('attrs.language', reference) ?? '-';
};

const stageDecisionRenderer =
  (stageType: StageType): TReferenceDataRenderer =>
  (reference) => {
    const { inclusionStatus, reasonCodes } = getReferenceInclusionStatusAndReasonCodes(
      reference,
      stageType
    );

    return inclusionStatus ? renderDecisionTags({ inclusionStatus, reasonCodes }) : null;
  };

const batchRenderer: TReferenceDataRenderer = (reference) => {
  return get('import_task.label', reference) ?? '-';
};

type ReferenceStudyPoolStudy = Pick<
  StudyPoolStudy,
  'inclusion_status' | 'status_reason_codes' | 'tags' | 'comment'
> & {
  study_pool: {
    id: string;
    stage: Pick<Stage, 'id' | 'order_number' | 'type'>;
  };
};

const tagsRenderer =
  (screeningTags: TScreeningTag[]): TReferenceDataRenderer =>
  (reference) => {
    const tags: TScreeningTag[] = compose(
      reject(isEmpty),
      map((tagId: string) => find(propEq('id', tagId), screeningTags)),
      uniq,
      get('[0].tags'),
      sortBy((sps: ReferenceStudyPoolStudy) => -(sps.study_pool.stage.order_number ?? 0))
    )(reference.study.study_pool_studies);

    return <ReferenceTags tags={tags} />;
  };

const tagsInspectionRenderer =
  (screeningTags: TScreeningTag[], stageId?: string): TReferenceDataRenderer =>
  (reference) => {
    const completedStudyPoolStudies = reference.study.study_pool_studies.filter(
      (sps) => sps.inclusion_status !== null && sps.inclusion_status !== InclusionStatus.Conflict
    );

    // get the desired stage results. fallback to last completed stage if desired stage is not
    // finished
    const stageResults =
      completedStudyPoolStudies.find((sps) => sps.study_pool.stage.id === stageId) ??
      orderBy('study_pool.stage.order_number', 'desc', completedStudyPoolStudies)[0];

    const tags: TScreeningTag[] = compose(
      reject(isEmpty),
      map((tagId: string) => find(propEq('id', tagId), screeningTags))
    )(stageResults?.tags);

    return <ReferenceTags tags={tags} />;
  };

const stageCommentRenderer =
  (stageId: string): TReferenceDataRenderer =>
  (reference) => {
    const stageComments: ReferenceCommentFragmentType[] =
      compose(filter(propEq('stage_id', stageId)), get('reference_comments'))(reference) ?? [];
    return renderReferenceCommentsIconWithPopover(stageComments);
  };

const commentRenderer: TReferenceDataRenderer = (reference) =>
  renderReferenceCommentsIconWithPopover(get('reference_comments', reference) ?? []);

const pdfStatusRenderer: TReferenceDataRenderer = (reference) => {
  return getReferenceAttachmentsCount(reference) > 0 ? (
    <Icon className="ml-1" icon={IconNames.TICK} color={Colors.GRAY1} />
  ) : reference.no_report ? (
    <Icon className="ml-1" icon={IconNames.CROSS} color={Colors.RED3} />
  ) : null;
};

const screenersRenderer =
  (
    stageId: string,
    stageType: StageType,
    formId: string,
    decisionReasons: TExclusionReason[]
  ): TReferenceDataRenderer =>
  (reference) => {
    if (stageId == null) return null;
    const screeningTasks = getStageTasksFromReference(reference, stageId).map(
      ({ id, team_member, task_results }) => ({
        id,
        teamMember: team_member,
        taskResult: task_results.filter(propEq('form_id', formId))[0]?.result,
      })
    );

    return (
      <ReferenceScreeners
        stageType={stageType}
        tasks={screeningTasks}
        decisionReasons={decisionReasons}
      />
    );
  };

const lastChangeRenderer =
  (stageId?: string): TReferenceDataRenderer =>
  (reference) => {
    const resultsByDate = compose(
      sortBy('updated_at'),
      flatMap('task_results')
    )(stageId ? getStageTasksFromReference(reference, stageId) : reference.study.tasks);

    const timestamp =
      get('updated_at', last(resultsByDate)) ?? get('import_task.created_at', reference);
    return timestamp ? formatDate(timestamp, 'dd/MM/yyyy HH:mm') : '-';
  };

const conflictStatusRenderer =
  (stage: Pick<Stage, 'id'>): TReferenceDataRenderer =>
  (reference) => {
    const conflictReconciliationTasks = filter(
      { stage_id: stage.id, task_type: ScreeningTaskType.ConflictResolution, is_draft: false },
      reference.study.tasks
    );

    return isEmpty(conflictReconciliationTasks) ? i18n._(t`Action required`) : i18n._(t`Pending`);
  };

const resolutionMethodRenderer =
  (stageId: string, formId: string): TReferenceDataRenderer =>
  (reference) => {
    const resolutionResult: ScreeningTaskResult | undefined = compose(
      first,
      filter(propEq('form_id', formId)),
      flatMap('task_results')
    )(getStageTasksFromReference(reference, stageId, ScreeningTaskType.ConflictResolution));
    const methodLabel = getResolutionStrategyLabel(
      resolutionResult?.result.conflictResolutionMethod ??
        ResolutionStrategy.DisagreementReconciliation
    );

    return (
      <span className="truncate" title={methodLabel}>
        {methodLabel}
      </span>
    );
  };

export const defaultListLayout: TLayoutFactory<{
  screeningTags: TScreeningTag[];
  openFocusMode: () => void;
  activeReferenceId: string;
  projectStageTypes: StageType[];
  highlightedWordsData: SearchAndDesiredUndesiredKeywords;
  selectable: boolean;
}> = ({
  screeningTags,
  activeReferenceId,
  openFocusMode,
  projectStageTypes,
  highlightedWordsData,
  selectable,
}) =>
  prepareLayout(
    compact([
      selectable && [RowSelectionColumn],
      [screeningDecisionCol(), decisionRenderer(activeReferenceId, openFocusMode)],
      [firstAuthorLastNameCol(), firstAuthorLastNameRenderer(highlightedWordsData)],
      [yearCol(), yearRenderer],
      [titleCol(), titleRenderer(highlightedWordsData)],
      [accessionNumberCol(), accessionNumberRenderer],
      [referenceNumberCol(), referenceNumberRenderer],
      docTypeColVisible && [docTypeCol(), docTypeRenderer],
      languageColVisible && [languageCol(), languageRenderer],
      projectStageTypes.includes(StageType.PreliminaryScreening) && [
        {
          id: 'decisionPreliminary',
          label: i18n._(t`Preliminary screening`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 100,
        },
        stageDecisionRenderer(StageType.PreliminaryScreening),
      ],
      projectStageTypes.includes(StageType.TitlesAbstractScreening) && [
        {
          id: 'decisionTiAb',
          label: i18n._(t`TiAb`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 100,
        },
        stageDecisionRenderer(StageType.TitlesAbstractScreening),
      ],
      projectStageTypes.includes(StageType.FullTextScreening) && [
        {
          id: 'decisionFT',
          label: i18n._(t`Full Text`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 100,
        },
        stageDecisionRenderer(StageType.FullTextScreening),
      ],
      [lastChangedCol(), lastChangeRenderer()],
      [
        {
          id: 'batch',
          label: i18n._(t`Batch`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 128,
        },
        batchRenderer,
      ],
      [
        {
          id: 'tags',
          label: i18n._(t`Structured comments`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 150,
        },
        tagsRenderer(screeningTags),
      ],
      [
        {
          id: 'comment',
          label: i18n._(t`Comment`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 100,
        },
        commentRenderer,
      ],
      [
        {
          id: 'pdf',
          label: i18n._(t`PDF`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 64,
        },
        pdfStatusRenderer,
      ],
    ]),
    openFocusMode,
    selectable ? 5 : 4
  );

export const duplicatesListLayout: TLayoutFactory<{
  openFocusMode: () => void;
  highlightedWordsData: SearchAndDesiredUndesiredKeywords;
}> = ({ openFocusMode, highlightedWordsData }) =>
  prepareLayout(
    [
      [RowSelectionColumn],
      [firstAuthorLastNameCol({ width: 200 }), firstAuthorLastNameRenderer(highlightedWordsData)],
      [yearCol({ width: 200 }), yearRenderer],
      [titleCol(), titleRenderer(highlightedWordsData)],
      [
        {
          id: 'batch',
          label: i18n._(t`Batch`),
          headerCellCss: alignStartCss,
          cellCss: alignStartCss,
          sortable: true,
          width: 200,
        },
        batchRenderer,
      ],
    ],
    openFocusMode
  );

const focusModeCol: TableCol<TReferenceSearchData> = {
  id: 'focusModeToggle',
  headerCellCss: alignStartCss,
  sortable: false,
  width: 80,
};

const screenersCol: TableCol<TReferenceSearchData> = {
  id: 'screeners',
  label: i18n._(t`Assigned screeners`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  width: 400,
};

interface IStageLayoutDeps {
  stage: Pick<Stage, 'id' | 'completed' | 'forms' | 'study_pools' | 'type'>;
  formId: string;
  decisionReasons: TExclusionReason[];
  screeningTags: TScreeningTag[];
  openFocusMode: () => void;
  activeReferenceId: string;
  highlightedWordsData: SearchAndDesiredUndesiredKeywords;
}

export const stageReferencesLayout: TLayoutFactory<IStageLayoutDeps> = ({
  stage,
  decisionReasons,
  openFocusMode,
  screeningTags,
  activeReferenceId,
  highlightedWordsData,
  formId,
}) =>
  prepareLayout(
    compact([
      [focusModeCol, focusModeToggleRenderer(activeReferenceId, openFocusMode)],
      [
        firstAuthorLastNameCol({ label: i18n._(t`Author`), width: 150 }),
        firstAuthorLastNameRenderer(highlightedWordsData),
      ],
      [yearCol({ width: 100 }), yearRenderer],
      [titleCol(), titleRenderer(highlightedWordsData)],
      [screenersCol, screenersRenderer(stage.id, stage.type, formId, decisionReasons)],
      [accessionNumberCol(), accessionNumberRenderer],
      [referenceNumberCol(), referenceNumberRenderer],
      docTypeColVisible && [docTypeCol(), docTypeRenderer],
      languageColVisible && [languageCol(), languageRenderer],
      [commentCol(), stageCommentRenderer(stage.id)],
      [tagsCol(), tagsInspectionRenderer(screeningTags, stage.id)],
      [lastChangedCol(), lastChangeRenderer(stage.id)],
    ]),
    openFocusMode
  );

export const conflictsListLayout: TLayoutFactory<IStageLayoutDeps> = ({
  stage,
  decisionReasons,
  openFocusMode,
  activeReferenceId,
  highlightedWordsData,
  formId,
}) =>
  prepareLayout(
    [
      [focusModeCol, focusModeToggleRenderer(activeReferenceId, openFocusMode)],
      [
        firstAuthorLastNameCol({ label: i18n._(t`Author`), width: 150 }),
        firstAuthorLastNameRenderer(highlightedWordsData),
      ],
      [yearCol({ width: 100 }), yearRenderer],
      [titleCol(), titleRenderer(highlightedWordsData)],
      [screenersCol, screenersRenderer(stage.id, stage.type, formId, decisionReasons)],
      [statusCol({ width: 150, sortable: false }), conflictStatusRenderer(stage)],
      [lastChangedCol(), lastChangeRenderer(stage.id)],
    ],
    openFocusMode
  );

export const resolvedConflictsListLayout: TLayoutFactory<IStageLayoutDeps> = ({
  stage,
  decisionReasons,
  openFocusMode,
  activeReferenceId,
  highlightedWordsData,
  formId,
}) =>
  prepareLayout(
    [
      [focusModeCol, focusModeToggleRenderer(activeReferenceId, openFocusMode)],
      [
        firstAuthorLastNameCol({ label: i18n._(t`Author`), width: 150 }),
        firstAuthorLastNameRenderer(highlightedWordsData),
      ],
      [yearCol({ width: 100 }), yearRenderer],
      [titleCol(), titleRenderer(highlightedWordsData)],
      [screenersCol, screenersRenderer(stage.id, stage.type, formId, decisionReasons)],
      [
        screeningDecisionCol({
          label: i18n._(t`Final decision`),
          width: 150,
        }),
        decisionRenderer(activeReferenceId, openFocusMode, stage.type),
      ],
      [
        {
          id: 'conflictResolutionMethod',
          label: i18n._(t`Method`),
          width: 200,
        },
        resolutionMethodRenderer(stage.id, formId),
      ],
      [lastChangedCol(), lastChangeRenderer(stage.id)],
    ],
    openFocusMode
  );
