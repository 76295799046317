/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { constant, isEmpty, isNil, keyBy } from 'lodash/fp';
import StudiesHeader from './references_list_header';
import BatchControls from '../../common/batch_controls';
import { plural, Trans } from '@lingui/macro';
import { Button, Divider, Intent, MenuItem } from '@blueprintjs/core';
import DuplicatesRemoveConfirmation from './duplicates_remove_confirmation';
import RemoveNoReportStatusConfirmation from './remove_no_report_status_confirmation';
import RestoreRemovedDuplicatesConfirmation from './restore_removed_duplicates_confirmation';
import { TScreeningPanel } from '../../screening/list';
import { IconNames } from '@blueprintjs/icons';
import { DecisionFilter } from '../../../apollo/screening_state';
import CountTag from '../../common/count_tag';
import { gray1ColorCss } from '../../../common/styles';
import useSetDecisionFilter from '../../hooks/use_set_decision_filter';
import { ReferenceRemovalReason, Stage } from '../../../common/types';
import { Select } from '@blueprintjs/select';
import { SetActiveBatchKeyMutation, TBatch } from './index';
import { useCurrCallback, useI18n, useSetState } from '../../../lib/utils';
import { TReferenceSearchData } from './references_list_layouts';
import AppToaster from '../../../lib/toaster';
import useReferenceDetailsLogger, {
  createSimpleReferenceDetailsLogs,
} from '../../hooks/use_reference_details_logger';
import { useKeycloak } from '../../../keycloak';
import { useMutation } from '@apollo/react-hooks';
import { renderFiltersToggle, renderPreviewToggle } from '../../screening/list_header_toggles';
import ExportDialog from '../../project/dashboard/export_dialog';

interface IListHeaderProps {
  projectId: string;
  references: TReferenceSearchData[];
  decisionFilter: DecisionFilter;
  selectedReferences: number[];
  activeReference: number;
  allReferencesCount: number;
  refetchReferences: () => void;
  isDuplicatesList: boolean;
  batches: TBatch[];
  activeBatchKey: string | null;
  openImportReferencesDialog: () => void;
  openImportPDFsDialog: () => void;
  openExportReferencesDialog: () => void;
  openFocusMode: () => void;
  onRemoveReferences: (referenceIds: string[], reason: string) => Promise<any>;
  onRestoreReferences: (referenceIds: string[]) => Promise<any>;
  setSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  onResetReferencesNoReportField: (referenceIds: string[]) => Promise<any>;
  allReferencesSelected: boolean;
  onSelectAllReferences: () => void;
  filtersApplied: boolean;
  activePanel: TScreeningPanel | null;
  togglePanel: (value: React.SetStateAction<TScreeningPanel | null>) => void;
  resolvedConflictsCount?: number;
  searchingReferences?: boolean;
  stage?: Pick<Stage, 'id' | 'completed' | 'forms' | 'study_pools' | 'type'>;
  referencesSearchArgs?: object;
}

interface IConfirmationsState {
  removeAsDuplicateConfirmationOpen: boolean;
  restoreConfirmationOpen: boolean;
  removeNoReportConfirmationOpen: boolean;
  selectedReferencesExportOpen: boolean;
}

const BatchesSelect = Select.ofType<TBatch | null>();

const ListHeader: React.FC<IListHeaderProps> = ({
  projectId,
  references,
  activePanel,
  selectedReferences,
  activeReference,
  togglePanel,
  filtersApplied,
  resolvedConflictsCount,
  decisionFilter,
  allReferencesCount,
  searchingReferences,
  stage,
  refetchReferences,
  isDuplicatesList,
  batches,
  activeBatchKey,
  openImportReferencesDialog,
  openImportPDFsDialog,
  openExportReferencesDialog,
  openFocusMode,
  onRemoveReferences,
  onRestoreReferences,
  setSelectedReferences,
  onResetReferencesNoReportField,
  allReferencesSelected,
  onSelectAllReferences,
  referencesSearchArgs,
}) => {
  const i18n = useI18n();
  const { user, hasAccessToAllProjects: hasFullAccess } = useKeycloak();
  const setDecisionFilter = useSetDecisionFilter();
  const addReferencesDetailsLog = useReferenceDetailsLogger();

  const [setActiveBatchKey] = useMutation(SetActiveBatchKeyMutation);

  const handleBatchChange = useCallback(
    (newActiveBatch: TBatch | null) => {
      setActiveBatchKey({
        variables: {
          activeBatchKey: newActiveBatch?.key || null,
        },
      });
    },
    [setActiveBatchKey]
  );

  const batchesByKey = useMemo(() => {
    return keyBy('key', batches);
  }, [batches]);

  const activeBatch = activeBatchKey ? batchesByKey[activeBatchKey] : null;

  const referencesCount =
    decisionFilter === 'resolved' ? resolvedConflictsCount ?? 0 : allReferencesCount;

  const [confirmationsState, setConfirmationsState] = useSetState<IConfirmationsState>({
    removeAsDuplicateConfirmationOpen: false,
    restoreConfirmationOpen: false,
    removeNoReportConfirmationOpen: false,
    selectedReferencesExportOpen: false,
  });

  const {
    removeAsDuplicateConfirmationOpen,
    restoreConfirmationOpen,
    removeNoReportConfirmationOpen,
    selectedReferencesExportOpen,
  } = confirmationsState;

  const renderSelectOption = useCurrCallback((selected, batch, { handleClick }) => {
    return (
      <MenuItem
        key={batch?.key ?? 'null'}
        onClick={handleClick}
        text={isNil(batch) ? <Trans>All batches</Trans> : batch.label}
        active={selected?.key === batch?.key ?? null}
      />
    );
  }, []);

  const filtersToggle = useMemo(
    () =>
      renderFiltersToggle({
        filtersApplied,
        activePanel,
        togglePanel,
      }),
    [filtersApplied, activePanel, togglePanel]
  );

  const previewToggle = useMemo(
    () =>
      renderPreviewToggle({
        activeReference,
        activePanel,
        togglePanel,
      }),
    [activeReference, activePanel, togglePanel]
  );

  const headerElement = useMemo(
    () => (
      <div className="flex flex-row items-center flex-1">
        {resolvedConflictsCount != null ? (
          <div className="flex-1 flex flex-row">
            <div className="flex-1 px-4">
              <CountTag
                active={decisionFilter === 'to_review'}
                count={allReferencesCount}
                onClick={searchingReferences ? undefined : () => setDecisionFilter('to_review')}
                className="mr-2"
                title={<Trans>Conflicts</Trans>}
                inclusionStatus="conflict"
                loading={searchingReferences}
              />
              <CountTag
                active={decisionFilter === 'resolved'}
                className="mr-2"
                count={resolvedConflictsCount}
                onClick={searchingReferences ? undefined : () => setDecisionFilter('resolved')}
                title={<Trans>Resolved</Trans>}
                loading={searchingReferences}
              />
            </div>
            {/* FIXME: Temporary commented out (T5286)
             <RapidConflictResolutionWidget
              projectId={projectId}
              stageId={stage?.id}
              disabled={referencesCount === 0}
              onResolve={refetchReferences}
            /> */}
          </div>
        ) : (
          <React.Fragment>
            <div className="mx-4 text-xl" css={gray1ColorCss}>
              {isDuplicatesList ? (
                <Trans>Duplicated references: {referencesCount}</Trans>
              ) : (
                <Trans>References: {referencesCount}</Trans>
              )}
            </div>
            {stage == null && !isDuplicatesList && (
              <React.Fragment>
                <div className="flex flex-row justify-end flex-grow ml-4 mr-2 overflow-hidden">
                  <BatchesSelect
                    filterable={false}
                    items={[null, ...batches]}
                    itemRenderer={renderSelectOption(activeBatch)}
                    onItemSelect={handleBatchChange}
                  >
                    <Trans>Batch</Trans>
                    <Button
                      className="ml-1 mr-2"
                      rightIcon={IconNames.CARET_DOWN}
                      text={activeBatch ? activeBatch.created_at : <Trans>All batches</Trans>}
                    />
                  </BatchesSelect>
                  {hasFullAccess && (
                    <React.Fragment>
                      <Button
                        className="ml-2"
                        intent={Intent.NONE}
                        text={<Trans>Import references</Trans>}
                        onClick={openImportReferencesDialog}
                      />
                      <Button
                        className="ml-2"
                        intent={Intent.NONE}
                        text={<Trans>Import PDFs</Trans>}
                        onClick={openImportPDFsDialog}
                      />
                    </React.Fragment>
                  )}
                  <Button
                    className="ml-2"
                    intent={Intent.NONE}
                    text={
                      isEmpty(selectedReferences) && filtersApplied ? (
                        <Trans>Export filtered references ({allReferencesCount})</Trans>
                      ) : isEmpty(selectedReferences) ? (
                        <Trans>Export all references</Trans>
                      ) : (
                        <Trans>Export selected</Trans>
                      )
                    }
                    onClick={openExportReferencesDialog}
                  />
                </div>
                <Divider className="h-full m-0" />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    ),
    [
      resolvedConflictsCount,
      decisionFilter,
      allReferencesCount,
      searchingReferences,
      projectId,
      stage,
      referencesCount,
      refetchReferences,
      isDuplicatesList,
      batches,
      renderSelectOption,
      activeBatch,
      openImportReferencesDialog,
      openImportPDFsDialog,
      selectedReferences,
      openExportReferencesDialog,
      setDecisionFilter,
    ]
  );

  const showRemoveAsDuplicateDialog = useCallback(() => {
    setConfirmationsState({ removeAsDuplicateConfirmationOpen: true });
  }, [setConfirmationsState]);

  const onlyNoReportReferencesSelected = selectedReferences.every(
    (refIdx) => references[refIdx].no_report
  );

  const handleRemoveAsDuplicates = () => {
    const referenceIds = selectedReferences.map((refIdx) => references[refIdx].id);

    const promise = onRemoveReferences(referenceIds, ReferenceRemovalReason.IsDuplicate)
      .then(() => {
        AppToaster.show({
          intent: Intent.WARNING,
          message: i18n._(
            plural({
              value: referenceIds.length,
              one: '# reference has been removed as duplicate',
              other: '# references have been removed as duplicates',
            })
          ),
          icon: IconNames.INFO_SIGN,
          action: {
            text: <Trans>Undo</Trans>,
            onClick: () => {
              onRestoreReferences(referenceIds);
            },
          },
        });
      })
      .catch(() => {
        AppToaster.show({
          message: <Trans>Failed to remove references as duplicates</Trans>,
          intent: Intent.WARNING,
        });
      });

    const referenceDetailsLogs = createSimpleReferenceDetailsLogs(
      'removed_as_duplicate',
      user,
      referenceIds
    );

    addReferencesDetailsLog(promise, constant(referenceDetailsLogs));
    setConfirmationsState({ removeAsDuplicateConfirmationOpen: false });
    setSelectedReferences(constant([]));
  };

  const handleRemoveNoReport = () => {
    const referenceIds = selectedReferences.map((refIdx) => references[refIdx].id);
    const promise = onResetReferencesNoReportField(referenceIds).then(() => {
      AppToaster.show({
        intent: Intent.WARNING,
        message: i18n._(
          plural({
            value: referenceIds.length,
            one: '# reference has been unmarked as having no report',
            other: '# references have been unmarked as having no report',
          })
        ),
        icon: IconNames.INFO_SIGN,
      });
    });
    const referenceDetailsLogs = createSimpleReferenceDetailsLogs(
      'no_report_mark_removed',
      user,
      referenceIds
    );
    addReferencesDetailsLog(promise, constant(referenceDetailsLogs));
    setConfirmationsState({ removeNoReportConfirmationOpen: false });
  };

  const handleRestore = () => {
    const referenceIds = selectedReferences.map((refIdx) => references[refIdx].id);
    const referenceDetailsLogs = createSimpleReferenceDetailsLogs('restored', user, referenceIds);

    const promise = onRestoreReferences(referenceIds)
      .then(() => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: i18n._(
            plural({
              value: referenceIds.length,
              one: '# reference has been restored',
              other: '# references have been restored',
            })
          ),
          icon: IconNames.INFO_SIGN,
        });
      })
      .catch(() => {
        AppToaster.show({
          message: <Trans>Failed to restore duplicated references</Trans>,
          intent: Intent.WARNING,
        });
      });

    addReferencesDetailsLog(promise, constant(referenceDetailsLogs));
    setConfirmationsState({ restoreConfirmationOpen: false });
  };

  return (
    <div>
      {isEmpty(selectedReferences) ? (
        <StudiesHeader
          leftElement={filtersToggle}
          headerElement={headerElement}
          rightElement={previewToggle}
          openFocusMode={openFocusMode}
        />
      ) : (
        <BatchControls
          batchTotal={referencesCount}
          batchSize={selectedReferences.length}
          batchName={<Trans>references</Trans>}
          allReferencesSelected={allReferencesSelected}
          onSelectAll={onSelectAllReferences}
        >
          {isDuplicatesList ? (
            <Button
              text={<Trans>Restore</Trans>}
              className="mr-3"
              onClick={() => setConfirmationsState({ restoreConfirmationOpen: true })}
            />
          ) : (
            <React.Fragment>
              <Button
                text={<Trans>Export selected</Trans>}
                className="mr-3"
                onClick={() => setConfirmationsState({ selectedReferencesExportOpen: true })}
              />
              <Button
                text={<Trans>Remove as duplicate</Trans>}
                className="mr-3"
                onClick={showRemoveAsDuplicateDialog}
              />
              {onlyNoReportReferencesSelected && (
                <Button
                  text={<Trans>Remove "No PDF" status</Trans>}
                  onClick={() => setConfirmationsState({ removeNoReportConfirmationOpen: true })}
                />
              )}
            </React.Fragment>
          )}
          <ExportDialog
            isOpen={selectedReferencesExportOpen}
            onClose={() => setConfirmationsState({ selectedReferencesExportOpen: false })}
            projectId={projectId}
            selectedReferencesIds={selectedReferences.map((refIdx) => references[refIdx].id)}
            referencesSearchArgs={referencesSearchArgs}
            allReferencesCount={allReferencesCount}
          />
          <DuplicatesRemoveConfirmation
            isOpen={removeAsDuplicateConfirmationOpen}
            onClose={() => setConfirmationsState({ removeAsDuplicateConfirmationOpen: false })}
            onConfirm={handleRemoveAsDuplicates}
          />
          <RemoveNoReportStatusConfirmation
            isOpen={removeNoReportConfirmationOpen}
            onClose={() => setConfirmationsState({ removeNoReportConfirmationOpen: false })}
            onConfirm={() => handleRemoveNoReport()}
          />
          <RestoreRemovedDuplicatesConfirmation
            isOpen={restoreConfirmationOpen}
            onClose={() => setConfirmationsState({ restoreConfirmationOpen: false })}
            onConfirm={handleRestore}
          />
        </BatchControls>
      )}
    </div>
  );
};

export default ListHeader;
