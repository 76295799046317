/** @jsx jsx */
import { useMutation, useQuery } from '@apollo/react-hooks';
import { H1, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { compose, every, filter, flatMap, get, isEmpty, map, reject, some } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import {
  AppContentProps,
  GraphQLAggregateData,
  Project,
  Reference,
  ReferenceClaim,
  Role,
  ScreeningForm,
  ScreeningTask,
  ScreeningType,
  Stage,
  StageMatchParams,
  StageType,
  StudyDistribution,
  WorkerStatus,
} from '../../../common/types';
import {
  getStageStudyCounts,
  prepareStudyPoolInsertInput,
  TStudyToDistribute,
} from '../../../lib/study_helpers';
import { isTeamMemberDeleted, isTeamMemberDisabled } from '../../../lib/user_helpers';
import useActionLogger from '../../hooks/use_action_logger';
import { toastify, useI18n, useSetState } from '../../../lib/utils';
import PageContentWrapper from '../../common/page_content_wrapper';
import Reviewers from './reviewers';
import ScreeningStats from './screening_stats';
import ScreeningTypeCard from './screening_type_card';
import DistributionCard from './distribution_card';
import { DashboardCard, DashboardCardWithButton } from './dashboard_card';
import { TScreenerData } from '../../../lib/distribution_helpers';
import FilesUploadDialog from '../../files_upload_dialog';
import ExportDialog, { ExportDialogMode } from './export_dialog';
import { centerItemCss } from '../../../common/styles';
import ConflictsCard from './conflicts_card';

const EMPTY_CONFLICT_TASKS = [];

const ProjectDataQuery = gql`
  query ProjectDataQuery($projectId: uuid!, $stageId: uuid!) {
    project: project_by_pk(id: $projectId) {
      id
      name
      stageStudiesAggregate: studies_aggregate(
        where: {
          deleted_at: { _is_null: true }
          study_pool_studies: { study_pool: { stage_id: { _eq: $stageId } } }
        }
      ) {
        aggregate {
          count
        }
      }
      reference_claims(where: { completed: { _eq: false } }, order_by: { created_at: desc }) {
        id
        project_id
        completed
        status
        claimed_by
        all_claimed_references_aggregate: claimed_references_aggregate {
          aggregate {
            count
          }
        }
        completed_claimed_references_aggregate: claimed_references_aggregate(
          where: { completed: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

type TProjectDataQueryData = {
  project: Pick<Project, 'id' | 'name'> & {
    stageStudiesAggregate: GraphQLAggregateData;
  };
};

const DistributeStudiesMutation = gql`
  mutation DistributeStudies(
    $stageId: uuid!
    $studyPool: study_pool_insert_input!
    $adminScreeners: [uuid!]
  ) {
    update_stage_by_pk(pk_columns: { id: $stageId }, _set: { completed: false }) {
      id
      completed
    }

    insert_study_pool_one(
      object: $studyPool
      on_conflict: {
        constraint: study_pool_pkey
        update_columns: [distribution_type, last_distribution]
      }
    ) {
      id
      distribution_status
    }

    update_team_member(where: { id: { _in: $adminScreeners } }, _set: { role: screener }) {
      returning {
        id
        role
      }
    }
  }
`;

const StageDataQuery = gql`
  query StageDataQuery($stageId: uuid!) {
    stage: stage_by_pk(id: $stageId) {
      id
      name
      completed
      forms(order_by: { created_at: desc }, limit: 1) {
        form
        id
      }
      studiesToDistribute: studies(where: { deleted_at: { _is_null: true } }) {
        id
        study_pool_studies(where: { study_pool: { stage_id: { _eq: $stageId } } }) {
          study_pool {
            id
            stage_id
          }
        }
        tasks(
          where: {
            stage_id: { _eq: $stageId }
            task_type: { _eq: screening }
            deleted_at: { _is_null: true }
          }
        ) {
          id
          team_member_id
        }
        references {
          id
          no_report
          reference_attachments {
            key
          }
        }
      }
      study_pools {
        id
        stage_id
        distribution_status
        study_pool_team_members {
          task_count
          team_member_id
          completed
        }
      }
      study_pools_aggregate {
        aggregate {
          count
        }
      }
      screening_type {
        id
        name
        screeners_no
      }
      studies_by_status {
        inclusion_status
        count
      }
      conflicts_resolution_strategies
      conflict_tasks: tasks(
        where: { task_type: { _eq: conflict_resolution }, stage_id: { _eq: $stageId } }
      ) {
        id
        is_draft
        completed
        study {
          id
          screeningTasks: tasks(
            where: { task_type: { _eq: screening }, stage_id: { _eq: $stageId } }
          ) {
            id
            task_results {
              result
            }
          }
        }
      }
      order_number
      type
    }
  }
`;

type TStageDataQueryData = {
  stage: Pick<
    Stage,
    | 'id'
    | 'name'
    | 'completed'
    | 'study_pools'
    | 'screening_type'
    | 'studies_by_status'
    | 'order_number'
    | 'type'
    | 'conflicts_resolution_strategies'
  > & {
    conflict_tasks: (Pick<ScreeningTask, 'id' | 'is_draft' | 'completed'> & {
      study: {
        id: string;
        screeningTasks: Pick<ScreeningTask, 'id' | 'task_results'>[];
      };
    })[];
    forms: Pick<ScreeningForm, 'id' | 'form'>;
    studiesToDistribute: TStudyToDistribute[];
  };
};

const ScreenersAndScreeningTypesQuery = gql`
  query ScreenersAndScreeningTypes($projectId: uuid!, $stageId: uuid!) {
    screeners: team_member(where: { project_id: { _eq: $projectId } }) {
      id
      deleted_at
      user_id
      user {
        id
        firstName
        lastName
        name
        enabled
        role
        hasAccessToAllProjects
      }
      project {
        id
      }
      role
      tasks_aggregate(
        where: {
          task_type: { _eq: screening }
          deleted_at: { _is_null: true }
          stage_id: { _eq: $stageId }
        }
      ) {
        aggregate {
          count
        }
      }
      tasks_by_status(where: { stage_id: { _eq: $stageId } }) {
        count
        inclusion_status
      }
    }
    screeningTypes: screening_type {
      id
      name
      screeners_no
    }
  }
`;

type TScreenersAndScreeningTypesQueryData = {
  screeners: TScreenerData[];
  screeningTypes: ScreeningType[];
};

const CreateStageMutation = gql`
  mutation CreateStage($stageData: stage_insert_input!) {
    insert_stage_one(object: $stageData) {
      id
    }
  }
`;

const UpdateStageScreeningTypeMutation = gql`
  mutation UpdateStageScreeningType($stageId: uuid!, $screeningTypeId: String!) {
    update_stage_by_pk(
      pk_columns: { id: $stageId }
      _set: { screening_type_id: $screeningTypeId }
    ) {
      id
    }
  }
`;

interface StageDashboardProps extends AppContentProps<StageMatchParams> {
  // regular props
}

const StageDashboardGridCss = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

interface IStageDashboardState {
  importPDFsDialogOpen: boolean;
  exportReferencesDialogOpen: boolean;
  rapidConflictsResolutionVisible: boolean;
}

const STAGE_DASHBOARD_INITIAL_STATE: IStageDashboardState = {
  importPDFsDialogOpen: false,
  exportReferencesDialogOpen: false,
  rapidConflictsResolutionVisible: false,
};

const StageDashboard: React.FC<StageDashboardProps> = ({ match, history }) => {
  const i18n = useI18n();
  const insertActionLog = useActionLogger();
  const { params, url } = match;
  const { projectId, stageId } = params;

  const [state, setState] = useSetState<IStageDashboardState>(STAGE_DASHBOARD_INITIAL_STATE);
  const { importPDFsDialogOpen, exportReferencesDialogOpen, rapidConflictsResolutionVisible } =
    state;

  const [createScreeningStage] = useMutation(CreateStageMutation);
  const [updateScreeningStageScreeningType] = useMutation(UpdateStageScreeningTypeMutation);
  const [distributeStudies] = useMutation(DistributeStudiesMutation);

  const { loading, data: screenersAndScreeningTypesData } =
    useQuery<TScreenersAndScreeningTypesQueryData>(ScreenersAndScreeningTypesQuery, {
      variables: { projectId, stageId },
      pollInterval: 1500,
    });

  const { data: projectData } = useQuery<TProjectDataQueryData>(ProjectDataQuery, {
    pollInterval: 1500,
    variables: { projectId, stageId },
  });
  const { data: stageData } = useQuery<TStageDataQueryData>(StageDataQuery, {
    pollInterval: 1500,
    variables: { stageId },
  });

  const projectName = get('project.name', projectData);
  const referenceClaims: ReferenceClaim[] = get('project.reference_claims', projectData) ?? [];
  const screeningTypes: ScreeningType[] =
    get('screeningTypes', screenersAndScreeningTypesData) ?? [];
  const stage = get('stage', stageData);
  const stageType = get('type', stage);
  const stageOrder = get('order_number', stage);
  const conflictTasks = stage?.conflict_tasks ?? EMPTY_CONFLICT_TASKS;
  const conflictResolutionStrategies = stage?.conflicts_resolution_strategies ?? null;
  const studiesToDistribute = stage?.studiesToDistribute ?? [];
  const stageStudiesCount = projectData?.project.stageStudiesAggregate.aggregate.count ?? 0;
  const screeningType = get('screening_type.id', stage);
  const poolId = get('study_pools[0].id', stage);
  const poolTeamMembers = get('study_pools[0].study_pool_team_members', stage);
  const projectTeamMembersData = get('screeners', screenersAndScreeningTypesData);
  const projectTeamMembers = useMemo(() => {
    if (projectTeamMembersData == null) return [];

    return reject(isTeamMemberDisabled, projectTeamMembersData);
  }, [projectTeamMembersData]);
  const projectTeamMembersWithoutDeleted = useMemo(
    () => reject(isTeamMemberDeleted, projectTeamMembers),
    [projectTeamMembers]
  );
  const stageStudyPoolsCount = get('study_pools_aggregate.aggregate.count', stage) ?? 0;

  const studyCounts = useMemo(() => {
    return stage ? getStageStudyCounts(stage) : null;
  }, [stage]);
  const navToSubmodule = useCallback(
    (submodule) => history.push(`${url}/${submodule}`),
    [history, url]
  );

  const form = get('stage.forms[0].form', stageData);
  const hasCriteria = useMemo(
    () =>
      stageType === StageType.FullTextScreening
        ? // at least one inclusion and exclusion reason exists
          get('inclusion[0].id', form) != null && get('exclusion[0].id', form) != null
        : stageType === StageType.PreliminaryScreening ||
          stageType === StageType.TitlesAbstractScreening
        ? // at least one domain variable exists
          get('domains[0].variables[0].id', form) != null
        : false,
    [stageType, form]
  );
  const hasStudyPoolsDistributionsInProgress = (stage?.study_pools ?? []).some((studyPool) =>
    [WorkerStatus.InProgress, WorkerStatus.New, WorkerStatus.Retry].includes(
      studyPool.distribution_status
    )
  );

  const handleScreeningTypeChange = useCallback(
    (screeningTypeId: string) => {
      // if stage exists, update its screening type. If not - create new stage with provided
      // screening type
      const promise = stage
        ? updateScreeningStageScreeningType({
            variables: {
              stageId: stage.id,
              screeningTypeId,
            },
          })
        : createScreeningStage({
            variables: {
              stageData: {
                project_id: projectId,
                screening_type_id: screeningTypeId,
                name: `${projectId}:main`,
              },
            },
          });

      return toastify(
        promise,
        i18n._(t`Screening type successfully changed`),
        i18n._(t`Error while trying to change screening type`),
        {
          errorToasterProps: {
            icon: IconNames.ERROR,
          },
          successToasterProps: {
            icon: IconNames.SAVED,
          },
        }
      ).then(() =>
        insertActionLog('changed screening type', { projectId, stage, screeningTypeId })
      );
    },
    [
      projectId,
      stage,
      createScreeningStage,
      i18n,
      updateScreeningStageScreeningType,
      insertActionLog,
    ]
  );

  const screenersCount = get('screening_type.screeners_no', stage) ?? 0;

  const referencesWithoutAttachmentsIds: string[] = useMemo(
    () =>
      stageType === StageType.FullTextScreening
        ? compose(
            map('id'),
            filter((ref: Reference) => isEmpty(ref.reference_attachments)),
            flatMap('references')
          )(studiesToDistribute)
        : [],
    [stageType, studiesToDistribute]
  );

  const [newStudiesToDistribute, returnedStudiesToDistribute] = useMemo(() => {
    const filtered =
      stageType === StageType.FullTextScreening
        ? reject((study) => some((ref) => ref.no_report, study.references), studiesToDistribute)
        : studiesToDistribute;

    return filtered.reduce(
      (acc, study) => {
        const [newStudies, returnedStudies] = acc;
        const studyTasksCount = (get('tasks', study) ?? []).length;

        // new studies to distribute
        acc[0] = studyTasksCount === 0 ? [...newStudies, study] : newStudies;

        // returned studies to distribute
        acc[1] =
          studyTasksCount === screenersCount || studyTasksCount === 0
            ? returnedStudies
            : [...returnedStudies, study];

        return acc;
      },
      [[], []] as [TStudyToDistribute[], TStudyToDistribute[]]
    );
  }, [screenersCount, studiesToDistribute, stageType]);

  const handleDistribute = useCallback(
    async (
      studiesPerMember: { [memberId: string]: number },
      distributionType: StudyDistribution,
      studiesToDistribute: TStudyToDistribute[]
    ) => {
      if (stage == null || projectTeamMembersData == null) return;
      const stageId = stage.id;
      const studyPoolInput = prepareStudyPoolInsertInput(
        projectId,
        stageId,
        distributionType,
        studiesPerMember,
        studiesToDistribute,
        projectTeamMembersData,
        poolId,
        poolTeamMembers
      );
      const adminScreeners = compose(
        map('id'),
        filter(({ id, role }) => id in studiesPerMember && role === Role.Manager)
      )(projectTeamMembersWithoutDeleted);

      return toastify(
        distributeStudies({
          variables: { stageId, studyPool: { ...studyPoolInput }, adminScreeners },
        }),
        i18n._(t`Studies successfully distributed`),
        i18n._(t`Error while trying to distribute studies`),
        {
          errorToasterProps: {
            icon: IconNames.ERROR,
          },
          successToasterProps: {
            icon: IconNames.SAVED,
          },
        }
      ).then(() => insertActionLog('distributed studies', { stageId, studyPool: studyPoolInput }));
    },
    [
      stage,
      projectId,
      poolId,
      poolTeamMembers,
      distributeStudies,
      i18n,
      insertActionLog,
      projectTeamMembersData,
      projectTeamMembersWithoutDeleted,
    ]
  );

  const navigateToReferences = useCallback(
    (status: string) => {
      history.push(['/projects', projectId, 'stages', stageId, 'references', status].join('/'));
    },
    [projectId, stageId, history]
  );

  const toggleRapidConflictResolution = useCallback(() => {
    setState((state) => ({
      ...state,
      rapidConflictsResolutionVisible: !state.rapidConflictsResolutionVisible,
    }));
  }, [setState]);

  const openImportPDFsDialog = useCallback(
    () => setState({ importPDFsDialogOpen: true }),
    [setState]
  );

  const closeImportPDFsDialog = useCallback(
    () => setState({ importPDFsDialogOpen: false }),
    [setState]
  );

  const openExportReferencesDialog = useCallback(
    () =>
      setState({
        exportReferencesDialogOpen: true,
      }),
    [setState]
  );

  const closeExportReferencesDialog = useCallback(
    () =>
      setState({
        exportReferencesDialogOpen: false,
      }),
    [setState]
  );

  const referencesExportSearchArgs = {
    searchArgs: {
      project_id: projectId,
    },
    referencesFilter: { removal_reason: { _is_null: true }, import_task_key: { _eq: null } },
  };

  return loading || stageType == null ? (
    <Spinner css={centerItemCss} />
  ) : (
    <PageContentWrapper className="px-2">
      <H1 className="my-5 font-normal">{get('name', stage) ?? projectName}</H1>
      <div className="h-full flex flex-col flex-no-wrap overflow-auto">
        <ScreeningStats
          navigateToReferences={navigateToReferences}
          studyCounts={studyCounts}
          screenersCount={screenersCount}
        />
        <div className="mt-8" css={StageDashboardGridCss}>
          <div>
            <DashboardCardWithButton
              buttonProps={{ text: <Trans>Setup</Trans> }}
              name={<Trans>Instructions and structured comments</Trans>}
              onClick={() => navToSubmodule('instructions')}
            />
            {stageType !== StageType.DataExtraction && (
              <React.Fragment>
                <ScreeningTypeCard
                  onChange={stageStudyPoolsCount === 0 ? handleScreeningTypeChange : undefined}
                  screeningTypes={screeningTypes}
                  selectedTypeId={screeningType}
                />
                {stageStudiesCount === 0 &&
                every(isEmpty, [
                  newStudiesToDistribute,
                  returnedStudiesToDistribute,
                  referencesWithoutAttachmentsIds,
                ]) ? (
                  <DashboardCard name={<Trans>Distribution</Trans>} interactive={false}>
                    <div className="px-6">
                      {stageOrder === 1 &&
                      [StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(
                        stageType
                      ) ? (
                        <Trans>Upload references to distribute them between reviewers</Trans>
                      ) : (
                        <Trans>
                          Please wait until some references are included in previous stage
                        </Trans>
                      )}
                    </div>
                  </DashboardCard>
                ) : (
                  <React.Fragment>
                    <DistributionCard
                      hasCriteria={hasCriteria}
                      isDistributing={hasStudyPoolsDistributionsInProgress}
                      onDistribute={handleDistribute}
                      screeningTypes={screeningTypes}
                      selectedScreeningTypeId={screeningType}
                      projectId={projectId}
                      referenceClaims={referenceClaims}
                      stageId={stageId}
                      stageType={stageType}
                      studiesToDistribute={newStudiesToDistribute}
                      teamMembers={projectTeamMembersWithoutDeleted}
                      studiesWithoutAttachmentsCount={referencesWithoutAttachmentsIds.length}
                      openImportPDFsDialog={openImportPDFsDialog}
                      openExportReferencesDialog={openExportReferencesDialog}
                    />
                    {returnedStudiesToDistribute.length > 0 && (
                      <DistributionCard
                        hasCriteria={hasCriteria}
                        isDistributing={hasStudyPoolsDistributionsInProgress}
                        onDistribute={handleDistribute}
                        returnedReferences
                        projectId={projectId}
                        referenceClaims={referenceClaims}
                        stageId={stageId}
                        screeningTypes={screeningTypes}
                        selectedScreeningTypeId={screeningType}
                        stageType={stageType}
                        studiesToDistribute={returnedStudiesToDistribute}
                        teamMembers={projectTeamMembersWithoutDeleted}
                        openImportPDFsDialog={openImportPDFsDialog}
                        openExportReferencesDialog={openExportReferencesDialog}
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <ConflictsCard
              conflictTasks={conflictTasks}
              conflictResolutionStrategies={conflictResolutionStrategies}
              stageType={stageType}
            />
          </div>
          <div>
            <DashboardCard name={<Trans>Reviewers</Trans>} withDivider={false}>
              <Reviewers
                referencesCount={stageStudiesCount}
                reviewers={projectTeamMembers}
                selectedScreeningTypeId={screeningType}
              />
            </DashboardCard>
          </div>
        </div>
      </div>
      <FilesUploadDialog
        isOpen={importPDFsDialogOpen}
        projectId={projectId}
        continueFn={closeImportPDFsDialog}
      />
      <ExportDialog
        mode={ExportDialogMode.ReferencesWithoutAttachments}
        isOpen={exportReferencesDialogOpen}
        projectId={projectId}
        onClose={closeExportReferencesDialog}
        selectedReferencesIds={referencesWithoutAttachmentsIds}
        allReferencesCount={referencesWithoutAttachmentsIds.length}
        referencesSearchArgs={referencesExportSearchArgs}
      />
    </PageContentWrapper>
  );
};

export default StageDashboard;
