/** @jsx jsx **/
import React, { useMemo } from 'react';
import { Colors, Spinner } from '@blueprintjs/core';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { gql } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';
import { StageType } from '../../common/types';
import { getStageTypesStatuses, ProjectStageStatus, StageStatus } from './helpers';

const GetProjectStagesStatusQuery = gql`
  query GetProjectStagesStatusQuery($projectId: uuid!) {
    stagesStatus: get_all_stages_in_project(args: { project_id_for: $projectId }) {
      completed
      project_id
      stage_id
      stage_order_number
      stage_type
      started
    }
  }
`;

type GetProjectStagesStatusQueryVariables = {
  projectId: string;
};

type GetProjectStagesStatusQueryResult = {
  stagesStatus: ProjectStageStatus[];
};

interface IStagesStatusProps {
  projectId: string;
}

const draftedCss = css`
  background: ${Colors.LIGHT_GRAY3};
`;

const startedCss = css`
  border: 2px solid ${Colors.BLUE3};
`;

const completedCss = css`
  border: none;
  background: ${Colors.BLUE3};
`;

const omittedCss = css`
  background: ${Colors.LIGHT_GRAY3};
  height: 2px;
`;

const STAGE_STATUS_TO_STYLE: Record<StageStatus, SerializedStyles> = {
  [StageStatus.Drafted]: draftedCss,
  [StageStatus.Started]: startedCss,
  [StageStatus.Completed]: completedCss,
  [StageStatus.Omitted]: omittedCss,
};

const STAGE_TYPES_ORDER: StageType[] = [
  StageType.PreliminaryScreening,
  StageType.TitlesAbstractScreening,
  StageType.FullTextScreening,
  StageType.DataExtraction,
];

/* Width is set here (and we are not using `w-full` class) due to the fact that the component
 * is rendered in the BP cell that is wrapped in another class. This causes the stage bars to
 * have width set to `0`. */
const stagesStatusCss = css`
  width: 130px;
  display: grid;
  grid-template-columns: repeat(${STAGE_TYPES_ORDER.length}, 1fr);
  grid-gap: 2px;
  align-items: center;
`;

const spinnerCss = css`
  width: 130px;
`;

/* Setting `rounded-full` on the main container for some reason doesn't work, probably
 * due to the fact that the inner element has its own border. */
const stageTypeCommonCss = css`
  border: 1px solid ${Colors.LIGHT_GRAY3};

  &:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const StagesStatus: React.FC<IStagesStatusProps> = ({ projectId }) => {
  const { data, loading } = useQuery<
    GetProjectStagesStatusQueryResult,
    GetProjectStagesStatusQueryVariables
  >(GetProjectStagesStatusQuery, {
    pollInterval: 1000,
    variables: { projectId },
  });
  const stagesStatus = get('stagesStatus', data) ?? [];
  const stagesStatuses = useMemo(() => getStageTypesStatuses(stagesStatus), [stagesStatus]);

  return loading ? (
    <Spinner css={spinnerCss} />
  ) : (
    <div className="h-2 stages-status" css={stagesStatusCss}>
      {STAGE_TYPES_ORDER.map((stageType) => (
        <div
          className="h-full"
          css={[stageTypeCommonCss, STAGE_STATUS_TO_STYLE[stagesStatuses[stageType]]]}
          key={stageType}
        />
      ))}
    </div>
  );
};

export default StagesStatus;
