import { t } from '@lingui/macro';
import { get } from 'lodash/fp';
import { AccountType } from '../common/types';
import { TUserFields } from '../components/users/edit_user_dialog';
import { UserData } from '../components/users/users_panel';
import i18n from '../i18n';
import { TScreenerData } from './distribution_helpers';

const KEYS_TO_OMIT = ['autoDeleteEnabled', '__typename', 'createdAt', 'id', 'name'];

export function userDataToUserFields(userData: UserData): TUserFields {
  const result = {};

  for (let key in userData) {
    if (KEYS_TO_OMIT.includes(key)) continue;
    const value = userData[key];

    if (value != null) {
      result[key] = value;
    }
  }
  return result as TUserFields;
}

export function isTeamMemberDisabled(teamMember: TScreenerData): boolean {
  return get('user.enabled', teamMember) === false;
}

export function isTeamMemberDeleted(teamMember: TScreenerData): boolean {
  return teamMember.user == null || get('deleted_at', teamMember) != null;
}

export function getAccountTypeLabel(accountType: AccountType): string {
  switch (accountType) {
    case AccountType.User:
      return i18n._(t`User`);
    case AccountType.TechAdmin:
      return i18n._(t`Senior screener`);
    case AccountType.ItAdmin:
      return i18n._(t`IT Administrator`);
  }
}

export function getAccountTypeLabelPlural(accountType: AccountType): string {
  switch (accountType) {
    case AccountType.User:
      return i18n._(t`Users`);
    case AccountType.TechAdmin:
      return i18n._(t`Technical Administrators`);
    case AccountType.ItAdmin:
      return i18n._(t`IT Administrators`);
  }
}
