/** @jsx jsx */
import { useQuery } from '@apollo/react-hooks';
import { Icon, NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { isEmpty, noop } from 'lodash/fp';
import React, { ReactNode, useCallback, useEffect } from 'react';
import {
  GraphQLAggregateData,
  Project,
  ProjectStatus,
  ProjectsFolder,
  Stage,
} from '../../common/types';
import { useKeycloak } from '../../keycloak';
import ErrorScreen from '../common/error_screen';
import ListHeader from './list_header';
import { useCurrCallback } from '../../lib/utils';
import FolderProjectsList from './folder_projects_list';
import StagesStatus from './stages_status';
import { formatDate, getFolderIcon, getTableHeaderOpacityCss } from './helpers';
import { TableCol } from '../common/gba_table';
import { alignStartCss, cursorCss, headerCellBgCss } from '../../common/styles';
import { statusCol } from '../../common/table_cols';
import i18n from '../../i18n';
import { useHistory } from 'react-router-dom';
import PageContentWrapper from '../common/page_content_wrapper';
import ProjectsListFilters from './projects_list_filters';
import useListOfProjects, {
  ORDER_BY_LATEST_FIRST,
  ORDER_BY_NAME_ALPHABETICALLY,
} from '../hooks/use_list_of_projects';

export type ScreenerProject = Pick<
  Project,
  'id' | 'name' | 'status' | 'created_at' | 'updated_at' | 'due_date' | 'completed'
> & {
  team_members_aggregate: {
    aggregate: {
      count: number;
    };
  };
  stages: (Pick<Stage, 'id' | 'completed'> & { conflicts_aggregate: GraphQLAggregateData })[];
  folder?: Pick<ProjectsFolder, 'id' | 'name'>;
};

type TScreenerProjectsFolder = Pick<ProjectsFolder, 'id' | 'name' | 'created_at'> & {
  projects: ScreenerProject[];
};

const EMPTY_PROJECTS: ScreenerProject[] = [];
const EMPTY_FOLDERS: TScreenerProjectsFolder[] = [];
const EMPTY_SELECTED_PROJECTS = [];

const ProjectFragment = loader('../../graphql/project_fragment.gql');

const GetScreenerProjects = gql`
  query GetScreenerProjects(
    $userId: String!
    $searchQuery: String
    $projectsOrderBy: [project_order_by!] = { created_at: desc }
    $foldersOrderBy: [project_folder_order_by!] = { created_at: desc }
  ) {
    folders: project_folder(
      where: {
        status: { _eq: "ACTIVE" }
        projects: {
          status: { _eq: "ACTIVE" }
          team_members: {
            user_id: { _eq: $userId }
            role: { _eq: screener }
            deleted_at: { _is_null: true }
          }
          tasks: { team_member: { user_id: { _eq: $userId } } }
        }
        _or: [{ name: { _ilike: $searchQuery } }, { projects: { name: { _ilike: $searchQuery } } }]
      }
      order_by: $foldersOrderBy
    ) {
      id
      name
      created_at
      projects(
        where: {
          status: { _eq: "ACTIVE" }
          team_members: {
            user_id: { _eq: $userId }
            role: { _eq: screener }
            deleted_at: { _is_null: true }
          }
          tasks: { team_member: { user_id: { _eq: $userId } } }
          _or: [{ name: { _ilike: $searchQuery } }, { folder: { name: { _ilike: $searchQuery } } }]
        }
        order_by: $projectsOrderBy
      ) {
        ...ProjectFragment
        stages {
          id
          completed
          conflicts_aggregate: tasks_aggregate(
            where: { task_type: { _eq: conflict_resolution }, completed: { _eq: false } }
          ) {
            aggregate {
              count
            }
          }
        }
        folder {
          id
          name
        }
      }
    }

    projects: project(
      where: {
        status: { _eq: "ACTIVE" }
        folder_id: { _is_null: true }
        team_members: {
          user_id: { _eq: $userId }
          role: { _eq: screener }
          deleted_at: { _is_null: true }
        }
        tasks: { team_member: { user_id: { _eq: $userId } } }
        _or: [{ name: { _ilike: $searchQuery } }, { folder: { name: { _ilike: $searchQuery } } }]
      }
      order_by: $projectsOrderBy
    ) {
      ...ProjectFragment
      stages {
        id
        completed
        conflicts_aggregate: tasks_aggregate(
          where: { task_type: { _eq: conflict_resolution }, completed: { _eq: false } }
        ) {
          aggregate {
            count
          }
        }
      }
      folder {
        id
        name
      }
    }
  }
  ${ProjectFragment}
`;

interface GetListColumnsProps {
  folderName?: string;
  isCollapsed?: boolean;
  toggleIsCollapsed?: () => void;
}

const getListColumns: (props: GetListColumnsProps) => TableCol<ScreenerProject>[] = ({
  folderName,
  isCollapsed,
  toggleIsCollapsed,
}: GetListColumnsProps) => [
  {
    id: 'project-title',
    cellCss: css(alignStartCss, cursorCss),
    headerCellCss: css([headerCellBgCss, alignStartCss]),
    customHeaderContent: (
      <div
        className="flex flex-row"
        onClick={toggleIsCollapsed ? () => toggleIsCollapsed() : undefined}
      >
        <Icon icon={getFolderIcon(folderName, ProjectStatus.Active, 1, isCollapsed)} />
        <span className="w-full text-left ml-2">{folderName}</span>
      </div>
    ),
  },
  statusCol({
    label: i18n._(t`Status`),
    headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
    cellCss: cursorCss,
    sortable: false,
  }),
  {
    id: 'created',
    label: i18n._(t`Created`),
    width: 130,
    headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
    cellCss: cursorCss,
  },
  {
    id: 'due-date',
    label: i18n._(t`Due date`),
    width: 130,
    headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
    cellCss: cursorCss,
  },
];

export type GetProjectsQueryResult = {
  projects: ScreenerProject[];
  folders: TScreenerProjectsFolder[];
};

interface IListForScreenersProps {}

const ListForScreeners: React.FC<IListForScreenersProps> = () => {
  const {
    user: { id: userId },
    isAdmin,
  } = useKeycloak();
  const history = useHistory();
  const {
    sortOrder: activeListSortOrder,
    setSortOrder,
    searchQuery,
    searchText,
    updateSearchText,
    allCollapsed,
    foldersCollapsed,
    toggleCollapseAllFolders,
    toggleFolderCollapsed,
    populateFoldersCollapsed,
  } = useListOfProjects();

  const { loading, error, data, refetch } = useQuery<GetProjectsQueryResult>(GetScreenerProjects, {
    pollInterval: 1000,
    variables: {
      userId,
      searchQuery,
      projectsOrderBy:
        activeListSortOrder === 'latest_first'
          ? ORDER_BY_LATEST_FIRST
          : ORDER_BY_NAME_ALPHABETICALLY,
      foldersOrderBy:
        activeListSortOrder === 'latest_first'
          ? ORDER_BY_LATEST_FIRST
          : ORDER_BY_NAME_ALPHABETICALLY,
    },
  });
  const projects: ScreenerProject[] = data?.projects ?? EMPTY_PROJECTS;
  const folders: TScreenerProjectsFolder[] = data?.folders ?? EMPTY_FOLDERS;

  const handleTableClick = useCurrCallback(
    (projects: ScreenerProject[], rowIdx: number, _ /*colIdx: number*/) => {
      const project: ScreenerProject = projects[rowIdx];
      history.push(`/projects/${project.id}`);
    },
    [history]
  );

  const renderTableCell = useCallback((colId: string, project: ScreenerProject): ReactNode => {
    switch (colId) {
      case 'project-title':
        return project.name;
      case 'status':
        return <StagesStatus projectId={project.id} />;
      case 'created':
        return project.created_at ? formatDate(project.created_at, 'dd/MM/yyyy') : '-';
      case 'due-date':
        return project.due_date ? formatDate(project.due_date, 'dd/MM/yyyy') : '-';
      default:
        return null;
    }
  }, []);

  // initialize foldersCollapsed after fetching folders
  useEffect(() => {
    if (folders) {
      populateFoldersCollapsed(folders);
    }
  }, [populateFoldersCollapsed, folders]);

  if (error) {
    return <ErrorScreen error={error} retry={() => refetch({ userId })} />;
  }

  if (!loading && isEmpty(projects) && isEmpty(folders) && isEmpty(searchText)) {
    return (
      <NonIdealState
        icon={IconNames.HEART_BROKEN}
        title={<Trans>No projects yet!</Trans>}
        description={<Trans>You have no projects at the moment</Trans>}
      />
    );
  }

  return (
    <div className="w-full flex flex-col overflow-none">
      {isAdmin ? null : (
        <div className="w-full flex flex-col mx-auto overflow-auto">
          <ListHeader title={<Trans>My projects</Trans>} />
        </div>
      )}
      <PageContentWrapper className={`${isAdmin ? '' : 'mt-24'}`}>
        <div className="flex flex-col flex-grow overflow-auto">
          <div>
            <ProjectsListFilters
              searchText={searchText}
              onUpdateSearchText={updateSearchText}
              activeSortOrder={activeListSortOrder}
              updateListSortOrder={setSortOrder}
              allCollapsed={allCollapsed}
              toggleCollapseAllFolders={loading ? undefined : toggleCollapseAllFolders}
            />
          </div>
          <div>
            {loading ? (
              <Spinner className="h-full" size={100} />
            ) : isEmpty(projects) && isEmpty(folders) ? (
              <NonIdealState
                icon={IconNames.HEART_BROKEN}
                title={<Trans>No projects available!</Trans>}
                description={
                  <Trans>Consider clearing or changing your filter before proceeding.</Trans>
                }
              />
            ) : (
              <div className="overflow-auto p-3">
                {projects.length > 0 ? (
                  <FolderProjectsList<ScreenerProject>
                    listColumns={getListColumns({
                      isCollapsed: foldersCollapsed['main'],
                      toggleIsCollapsed: () => toggleFolderCollapsed('main'),
                    })}
                    renderTableCell={renderTableCell}
                    selectedProjects={EMPTY_SELECTED_PROJECTS}
                    updateSelectedProjects={noop}
                    isCollapsed={foldersCollapsed['main']}
                    projects={projects}
                    handleTableClick={handleTableClick(projects)}
                  />
                ) : null}
                {folders.map((folder) => (
                  <FolderProjectsList<ScreenerProject>
                    key={folder.id}
                    listColumns={getListColumns({
                      folderName: folder.name,
                      isCollapsed: foldersCollapsed[folder.id],
                      toggleIsCollapsed: () => toggleFolderCollapsed(folder.id),
                    })}
                    renderTableCell={renderTableCell}
                    selectedProjects={EMPTY_SELECTED_PROJECTS}
                    updateSelectedProjects={noop}
                    isCollapsed={foldersCollapsed[folder.id]}
                    projects={folder.projects}
                    handleTableClick={handleTableClick(folder.projects)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </PageContentWrapper>
    </div>
  );
};

export default ListForScreeners;
