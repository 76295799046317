/** @jsx jsx */
import { useMutation } from '@apollo/react-hooks';
import { Button, Colors, NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { loader } from 'graphql.macro';
import { constant, isEmpty } from 'lodash/fp';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { TScreeningTag } from './';
import { TExclusionReason } from '../../screening';
import {
  DecisionFilter,
  EMPTY_ORDERED_BY,
  TActiveAttributeFilters,
  TActiveKeywordFilters,
  TActiveScreeningTagFilters,
  TPdfFilter,
  YearFilters,
} from '../../../apollo/screening_state';
import ActiveFiltersBar from '../../screening/active_filters_bar';
import ReferencesList from './references_list';
import { Stage, StageType } from '../../../common/types';
import {
  conflictsListLayout,
  defaultListLayout,
  duplicatesListLayout,
  IReferenceListLayout,
  resolvedConflictsListLayout,
  stageReferencesLayout,
  TReferenceSearchData,
} from './references_list_layouts';
import { SearchAndDesiredUndesiredKeywords } from '../../../lib/criteria_utils';
import { useKeycloak } from '../../../keycloak';
import { useScreeningFilters } from '../../hooks/use_screening_filters';

const SetReferencesListOrderedByMutation = loader(
  '../../../graphql/local/set_references_list_ordered_by.gql'
);

interface IReferencesListColumnProps {
  decisionFilter: DecisionFilter;
  references: TReferenceSearchData[];
  activeReference: number;
  selectedReferences: number[];
  setSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  setActiveReference: (number) => void;
  openFocusMode: () => void;
  loadingMoreReferences: boolean;
  decisionReasons: TExclusionReason[];
  screeningTags: TScreeningTag[];
  isDuplicatesList: boolean;
  allReferencesCount: number;
  projectStageTypes: StageType[];
  filtersApplied: boolean;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  activeYearFilters: YearFilters;
  activeOnlyWithCommentsFilter: boolean;
  activePdfFilter: TPdfFilter;
  activeOnlyWithoutAbstractFilter: boolean;
  searchPhraseTokens: string[];
  resetYearsFilter: () => void;
  formId?: string;
  stage?: Pick<Stage, 'id' | 'completed' | 'forms' | 'study_pools' | 'type'>;
  resolvedConflictsCount?: number;
  searchingReferences?: boolean;
  onLoadMoreReferences?: () => void;
}

const ReferencesListColumn: React.FC<IReferencesListColumnProps> = memo(
  ({
    decisionFilter,
    references,
    activeReference,
    selectedReferences,
    setSelectedReferences,
    setActiveReference,
    searchingReferences,
    onLoadMoreReferences,
    openFocusMode,
    loadingMoreReferences,
    decisionReasons,
    screeningTags,
    formId,
    projectStageTypes,
    stage,
    resolvedConflictsCount,
    isDuplicatesList,
    allReferencesCount,
    filtersApplied,
    activeKeywordFilters,
    activeDecisionCodeFilters,
    activeDocumentTypeFilters,
    activeScreeningTagFilters,
    activeYearFilters,
    activeOnlyWithCommentsFilter,
    activePdfFilter,
    activeOnlyWithoutAbstractFilter,
    searchPhraseTokens,
    resetYearsFilter,
  }) => {
    const { clearFilters } = useScreeningFilters();
    const [setListOrderedBy] = useMutation(SetReferencesListOrderedByMutation);
    const [sortedBy, setSortedBy] = useState<undefined | { columnId: string; asc: boolean }>();
    const { hasAccessToAllProjects: hasFullAccess } = useKeycloak();

    const highlightedWordsData: SearchAndDesiredUndesiredKeywords = useMemo(() => {
      return {
        desiredKeywords: [],
        undesiredKeywords: [],
        searchKeywords: searchPhraseTokens,
      };
    }, [searchPhraseTokens]);

    const handleSortBy = useCallback(
      (columnId: string, asc: boolean) => {
        const order = asc ? 'asc' : 'desc';
        let orderedBy: object;

        switch (columnId) {
          case 'decision':
            orderedBy = {
              column: 'latest_decision',
              order,
            };
            break;
          case 'firstAuthorsLastName':
            orderedBy = {
              column: 'first_author',
              order,
            };
            break;
          case 'year':
            orderedBy = {
              column: 'year',
              order,
            };
            break;
          case 'title':
            orderedBy = {
              column: 'title',
              order,
            };
            break;
          case 'referenceNumber':
            orderedBy = {
              column: 'reference_number',
              order,
            };
            break;
          case 'accessionNumber':
            orderedBy = {
              column: 'accession_number',
              order,
            };
            break;
          case 'lastChanged':
            orderedBy = {
              column: 'last_changed',
              order,
            };
            break;
          case 'documentType':
            orderedBy = {
              column: 'document_type',
              order,
            };
            break;
          case 'language':
            orderedBy = {
              column: 'language',
              order,
            };
            break;
          case 'decisionPreliminary':
            orderedBy = {
              column: 'decision_preliminary',
              order,
            };
            break;
          case 'decisionTiAb':
            orderedBy = {
              column: 'decision_tiab',
              order,
            };
            break;
          case 'decisionFT':
            orderedBy = {
              column: 'decision_ft',
              order,
            };
            break;
          case 'batch':
            orderedBy = {
              column: 'batch',
              order,
            };
            break;
          case 'tags':
            orderedBy = {
              column: 'tags',
              order,
            };
            break;
          case 'comment':
            orderedBy = {
              column: 'comment',
              order,
            };
            break;
          case 'pdf':
            orderedBy = {
              column: 'pdf',
              order,
            };
            break;
          default:
            orderedBy = EMPTY_ORDERED_BY;
        }

        setListOrderedBy({ variables: { orderedBy } });
        setSortedBy({ columnId, asc });
      },
      [setListOrderedBy, setSortedBy]
    );

    const handleClearSort = useCallback(() => {
      setListOrderedBy({
        variables: {
          orderedBy: EMPTY_ORDERED_BY,
        },
      });
      setSortedBy(undefined);
    }, [setListOrderedBy, setSortedBy]);

    const referencesListLayout: IReferenceListLayout = useMemo(() => {
      if (isDuplicatesList) {
        return duplicatesListLayout({ openFocusMode, highlightedWordsData });
      }
      const activeReferenceId = references[activeReference]?.id;

      if (stage == null) {
        return defaultListLayout({
          screeningTags,
          activeReferenceId,
          openFocusMode,
          projectStageTypes,
          highlightedWordsData,
          selectable: hasFullAccess,
        });
      }

      if (formId == null)
        return { columns: [], cellContentRenderer: constant(null), numFrozenColumns: 0 };
      const stageLayoutDeps = {
        stage,
        formId,
        decisionReasons,
        openFocusMode,
        screeningTags,
        activeReferenceId,
        highlightedWordsData,
      };

      // resolvedConflictsCount is provided only when conflicts list is rendered
      if (resolvedConflictsCount != null) {
        return decisionFilter === 'to_review'
          ? conflictsListLayout(stageLayoutDeps)
          : resolvedConflictsListLayout(stageLayoutDeps);
      }

      return stageReferencesLayout(stageLayoutDeps);
    }, [
      projectStageTypes,
      stage,
      formId,
      decisionReasons,
      screeningTags,
      resolvedConflictsCount,
      openFocusMode,
      activeReference,
      references,
      decisionFilter,
      isDuplicatesList,
      highlightedWordsData,
      hasFullAccess,
    ]);

    return (
      <div className="h-full flex flex-col overflow-auto">
        {filtersApplied && (
          <ActiveFiltersBar
            phraseTokens={searchPhraseTokens}
            keywordFilters={activeKeywordFilters}
            decisionCodeFilters={activeDecisionCodeFilters}
            documentTypeFilters={activeDocumentTypeFilters}
            screeningTagFilters={activeScreeningTagFilters}
            onlyWithCommentsFilter={activeOnlyWithCommentsFilter}
            pdfFilter={activePdfFilter}
            onlyWithoutAbstractFilter={activeOnlyWithoutAbstractFilter}
            yearsFilters={activeYearFilters}
            screeningTags={screeningTags}
            onYearsFilterReset={resetYearsFilter}
          />
        )}
        {searchingReferences ? (
          <Spinner className="h-full" />
        ) : isEmpty(references) ? (
          filtersApplied ? (
            <NonIdealState
              action={<Button onClick={clearFilters} text={<Trans>Clear all filters</Trans>} />}
              icon={IconNames.DOCUMENT}
              title={
                <Trans>
                  No references found matching your criteria <br />
                  in selected category
                </Trans>
              }
            />
          ) : (
            <NonIdealState
              title={
                <span className="text-xl" css={{ color: Colors.GRAY1 }}>
                  {resolvedConflictsCount != null && decisionFilter === 'to_review' ? (
                    <Trans>All conflicts have been resolved</Trans>
                  ) : (
                    <Trans>There are no references in selected category</Trans>
                  )}
                </span>
              }
            />
          )
        ) : (
          <ReferencesList
            references={references}
            totalReferencesCount={allReferencesCount}
            activeReference={activeReference}
            selectedReferences={selectedReferences}
            onChangeSelectedReferences={setSelectedReferences}
            onChangeActiveReference={setActiveReference}
            onLoadMore={onLoadMoreReferences}
            loadingMore={loadingMoreReferences}
            onSortBy={handleSortBy}
            sortedBy={sortedBy}
            onClearSort={handleClearSort}
            openFocusMode={openFocusMode}
            listLayout={referencesListLayout}
          />
        )}
      </div>
    );
  }
);

export default ReferencesListColumn;
