import React, { useCallback } from 'react';
import { without, includes } from 'lodash/fp';
import { Card, Checkbox, Icon, Colors, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useCurrCallback, useI18n } from '../../../lib/utils';
import { t, Trans } from '@lingui/macro';
import { TScreenerData } from '../../../lib/distribution_helpers';
import { AccountType, Role } from '../../../common/types';

interface IReferencesDistributionProportionalProps {
  teamMembers: TScreenerData[];
  studiesPerMember: { [memberId: string]: number };
  selectedMembers: string[];
  onChangeSelectedMembers: (selectedMembers: string[]) => void;
}

const ReferencesDistributionProportional: React.FC<IReferencesDistributionProportionalProps> = ({
  teamMembers,
  studiesPerMember,
  selectedMembers,
  onChangeSelectedMembers,
}) => {
  const i18n = useI18n();
  const toggleAll = useCallback(
    (evt) => {
      onChangeSelectedMembers(evt.target.checked ? teamMembers.map((m) => m.id) : []);
    },
    [onChangeSelectedMembers, teamMembers]
  );

  const toggleMember = useCurrCallback(
    (memberId, evt) => {
      const checked = evt.target.checked;
      onChangeSelectedMembers(
        checked ? selectedMembers.concat(memberId) : without([memberId], selectedMembers)
      );
    },
    [onChangeSelectedMembers, selectedMembers]
  );

  return (
    <div>
      <div className="px-3 mt-2 mb-6 flex flex-row flex-no-wrap justify-between">
        <Checkbox
          label={i18n._(t`Team member`)}
          checked={selectedMembers.length === teamMembers.length}
          onChange={toggleAll}
          className="mb-0"
        />
        <span>
          <Trans>Task</Trans>
        </span>
      </div>
      <Divider className="m-0" />
      <div className="members-list">
        {teamMembers.map((member) => {
          const memberId = member.id;
          const isTechAdminAccount =
            member.user?.role === AccountType.TechAdmin && member.user.hasAccessToAllProjects;
          const checked = includes(memberId, selectedMembers);

          return (
            <div
              className="px-3 py-6 flex flex-row flex-no-wrap justify-between items-center"
              key={memberId}
            >
              <div className="flex flex-row flex-no-wrap items-center">
                <div className="flex-none">
                  <Checkbox
                    className="mb-0 mr-3"
                    onChange={toggleMember(memberId)}
                    checked={checked}
                    labelElement={
                      <span>
                        {isTechAdminAccount && <Icon className="mr-1" icon={IconNames.CROWN} />}
                        {member.user?.name ?? <Trans>User removed</Trans>}
                      </span>
                    }
                  />
                </div>
              </div>
              <div>
                <Icon icon={IconNames.EYE_OPEN} color={Colors.GRAY4} />
                <span className="mx-2 refs-count">{studiesPerMember[memberId] ?? 0}</span>
                <Icon icon={IconNames.DOCUMENT} color={Colors.GRAY4} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReferencesDistributionProportional;
