import { useCallback, useState } from 'react';
import { IFoldersCollapsed, TProjectsListSortOrder } from '../../common/types';
import { debounce, defaults, keys, reduce, trim, uniq, values } from 'lodash/fp';
import { ProjectsOwnerFilter } from '../../lib/utils';
import { useKeycloak } from '../../keycloak';

export const ORDER_BY_LATEST_FIRST: object = {
  created_at: 'desc_nulls_last',
};

export const ORDER_BY_NAME_ALPHABETICALLY: object = {
  name: 'asc',
};

interface IListOfProjectsState {
  allCollapsed: boolean;
  foldersCollapsed: IFoldersCollapsed;
  sortOrder: TProjectsListSortOrder;
  projectsOwnerFilter: ProjectsOwnerFilter;
  searchQuery: string;
  searchText: string;
}

function getInitialState(hasFullAccess: boolean): IListOfProjectsState {
  return {
    allCollapsed: false,
    foldersCollapsed: { main: false },
    sortOrder: 'latest_first',
    projectsOwnerFilter: hasFullAccess ? ProjectsOwnerFilter.All : ProjectsOwnerFilter.Member,
    searchQuery: '%%',
    searchText: '',
  };
}

export default function useListOfProjects<TFolder>() {
  const { hasAccessToAllProjects: hasFullAccess } = useKeycloak();
  const [state, setState] = useState<IListOfProjectsState>(getInitialState(hasFullAccess));
  const {
    allCollapsed,
    foldersCollapsed,
    sortOrder,
    projectsOwnerFilter,
    searchQuery,
    searchText,
  } = state;

  const setSortOrder = useCallback(
    (newSortOrder: TProjectsListSortOrder) => {
      setState((prevState) => ({ ...prevState, sortOrder: newSortOrder }));
    },
    [setState]
  );

  const populateFoldersCollapsed = useCallback(
    (folders: TFolder[]) => {
      setState((prevState) => ({
        ...prevState,
        foldersCollapsed: reduce<TFolder, IFoldersCollapsed>(
          (acc, curr) => {
            acc[curr['id']] = false;
            return acc;
          },
          prevState.foldersCollapsed,
          folders
        ),
      }));
    },
    [setState]
  );

  const toggleFolderCollapsed = useCallback(
    (folderId: string) => {
      setState((prevState) => {
        const updatedFoldersCollapsed = defaults(prevState.foldersCollapsed, {
          [folderId]: !prevState.foldersCollapsed[folderId],
        });
        return {
          ...prevState,
          foldersCollapsed: updatedFoldersCollapsed,
          // toggle collapse/expand direction if all the folders after update are either collapsed or expanded
          allCollapsed:
            uniq(values(updatedFoldersCollapsed)).length === 1
              ? !prevState.allCollapsed
              : prevState.allCollapsed,
        };
      });
    },
    [setState]
  );

  const toggleCollapseAllFolders = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      allCollapsed: !prevState.allCollapsed,
      foldersCollapsed: reduce<string, IFoldersCollapsed>(
        (acc, curr) => {
          acc[curr] = !prevState.allCollapsed;
          return acc;
        },
        { main: !prevState.allCollapsed },
        keys(prevState.foldersCollapsed)
      ),
    }));
  }, [setState]);

  const setProjectsOwnerFilter = useCallback(
    (projectsOwnerFilter: ProjectsOwnerFilter) =>
      setState((prevState) => ({ ...prevState, projectsOwnerFilter })),
    [setState]
  );

  const updateSearchQuery = useCallback(
    debounce(300, (query: string) => {
      setState((prevState) => ({ ...prevState, searchQuery: `%${trim(query)}%` }));
    }),
    [setState]
  );

  const updateSearchText = useCallback(
    (searchText: string) => {
      setState((prevState) => ({ ...prevState, searchText }));
      updateSearchQuery(searchText);
    },
    [setState, updateSearchQuery]
  );

  return {
    allCollapsed,
    foldersCollapsed,
    toggleFolderCollapsed,
    toggleCollapseAllFolders,
    populateFoldersCollapsed,
    sortOrder,
    setSortOrder,
    projectsOwnerFilter,
    setProjectsOwnerFilter,
    searchQuery,
    searchText,
    updateSearchText,
  };
}
