/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { DomainData, StageType } from '../../common/types';
import { Button, Collapse, InputGroup, NumberRange, RangeSlider, Tag } from '@blueprintjs/core';
import { useState, useMemo, useCallback, Dispatch, SetStateAction, Fragment } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { useCurrCallback, useI18n, useKeywordTagStyles } from '../../lib/utils';
import { get, isEmpty, toSafeInteger, clamp } from 'lodash/fp';
import { getDomainVariablePath } from '../../lib/task_helpers';
import {
  TActiveKeywordFilters,
  TKeywordType,
  TActiveAttributeFilters,
  TSearchOperator,
  TActiveScreeningTagFilters,
} from '../../apollo/screening_state';
import { t, Trans } from '@lingui/macro';
import { Select } from '@blueprintjs/select';
import { IYearsRangeFilterState } from '../references/admin/list';
import { TDomainReasonsData, TScreeningTag } from '../references/admin';
import { isActive } from '../references/admin/references_filters';
import ReferencesSearchWidget, { FiltersTarget } from './references_search_widget';
import { useScreeningFilters } from '../hooks/use_screening_filters';
import { fancyScrollCss } from '../../common/styles';

export const FilterTargetSelect = Select.ofType<FiltersTarget>();

export const searchOperatorTags: TSearchOperator[] = ['and', 'or'];
export const oppositeOperator: { [key: string]: TSearchOperator } = {
  and: 'or',
  or: 'and',
};

export const rangeFilterInputCss = css`
  width: 64px;
`;

export const domainSearchOperatorsCss = css`
  min-width: fit-content;
  max-width: max-content;
`;

interface IKeywordFiltersTagProps {
  onToggle: (data: { keyword: string; active: boolean; type: string }) => void;
  keyword: string;
  active: boolean;
  type: TKeywordType;
}

interface IAttributeFiltersTagProps {
  onToggle: (attribute: string, label: string, active: boolean) => void;
  attribute: string;
  label: string;
  active: boolean;
  htmlTitle?: string;
}

interface IScreeningTagFiltersTagProps {
  onToggle: (attribute: string, active: boolean) => void;
  attribute: string;
  label: string;
  active: boolean;
  disabled?: boolean;
  title?: string;
}

interface ISearchOperatorTagProps {
  onToggle: (variablePath: string, searchOperator: TSearchOperator) => void;
  searchOperator: TSearchOperator;
  active: boolean;
  variablePath: string;
}

export const KeywordFiltersTag: React.FC<IKeywordFiltersTagProps> = ({
  keyword,
  onToggle,
  active,
  type,
}) => {
  const handleClick = useCallback(() => {
    onToggle({ keyword, active: !active, type });
  }, [onToggle, keyword, active, type]);
  const [desiredKeywordCss, undesiredKeywordCss] = useKeywordTagStyles(!active);

  return (
    <Tag
      className="mb-1 mr-1 cursor-pointer"
      css={type === 'included' ? desiredKeywordCss : undesiredKeywordCss}
      onClick={handleClick}
      data-testid="keyword-tag"
    >
      {keyword}
    </Tag>
  );
};

export const ScreeningTagFiltersTag: React.FC<IScreeningTagFiltersTagProps> = ({
  attribute,
  label,
  onToggle,
  active,
  disabled,
  title,
}) => {
  const handleClick = useCallback(() => {
    onToggle(attribute, !active);
  }, [onToggle, attribute, label, active]);
  const [, , neutralKeywordsCss] = useKeywordTagStyles(!active);

  return (
    <Tag
      title={title}
      className={`mb-1 mr-1 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${
        active ? 'active' : ''
      }`}
      css={neutralKeywordsCss}
      onClick={disabled ? undefined : handleClick}
    >
      {label}
    </Tag>
  );
};

export const AttributeFiltersTag: React.FC<IAttributeFiltersTagProps> = ({
  attribute,
  label,
  onToggle,
  active,
  htmlTitle,
}) => {
  const handleClick = useCallback(() => {
    onToggle(attribute, label, !active);
  }, [onToggle, attribute, label, active]);
  const [, , neutralKeywordsCss] = useKeywordTagStyles(!active);

  return (
    <Tag
      className="mb-1 mr-1 cursor-pointer"
      css={neutralKeywordsCss}
      onClick={handleClick}
      htmlTitle={htmlTitle}
    >
      {label}
    </Tag>
  );
};

export const SearchOperatorTag: React.FC<ISearchOperatorTagProps> = ({
  searchOperator,
  variablePath,
  onToggle,
  active,
}) => {
  const handleClick = useCallback(() => {
    onToggle(variablePath, searchOperator);
  }, [onToggle, variablePath, searchOperator]);
  const [, , neutralKeywordsCss] = useKeywordTagStyles(!active);

  return (
    <Tag className="mb-1 mr-1 cursor-pointer" css={neutralKeywordsCss} onClick={handleClick}>
      {searchOperator}
    </Tag>
  );
};

interface ScreeningFiltersState {
  showAttributes: boolean;
  showKeywords: boolean;
  showStatus: boolean;
}

interface IScreeningFiltersProps {
  stageType: StageType;
  domains: DomainData[];
  reasonCodesData: TDomainReasonsData;
  documentTypes: string[];
  yearsFilterData: { min: number; max: number };
  yearsRangeFilterState: IYearsRangeFilterState;
  setYearsRangeFilterState: Dispatch<SetStateAction<IYearsRangeFilterState>>;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  screeningTags: TScreeningTag[];
  activeScreeningTagFilters: TActiveScreeningTagFilters;
}

const ScreeningFilters: React.FC<IScreeningFiltersProps> = ({
  stageType,
  domains,
  reasonCodesData,
  documentTypes,
  yearsFilterData,
  yearsRangeFilterState,
  setYearsRangeFilterState,
  activeKeywordFilters,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  screeningTags,
  activeScreeningTagFilters,
}) => {
  const i18n = useI18n();
  const {
    toggleKeyword,
    toggleAttribute,
    toggleScreeningTag,
    setYearsFilter,
    changeKeywordSearchOperator,
  } = useScreeningFilters();
  const [state, setState] = useState<ScreeningFiltersState>({
    showAttributes: true,
    showKeywords: true,
    showStatus: true,
  });
  const { showAttributes, showKeywords, showStatus } = state;
  const yearsRangeFilterValue: NumberRange = [
    clamp(yearsFilterData.min, yearsFilterData.max, yearsRangeFilterState.min),
    clamp(yearsFilterData.min, yearsFilterData.max, yearsRangeFilterState.max),
  ];

  const domainsWithKeywords: DomainData[] = useMemo(() => {
    return domains.reduce((acc, domain) => {
      const variablesWithKeywords = domain.variables.filter(
        (variable) =>
          !isEmpty(get('keywords.included', variable)) ||
          !isEmpty(get('keywords.excluded', variable))
      );

      if (!isEmpty(variablesWithKeywords)) {
        acc.push({ ...domain, variables: variablesWithKeywords });
      }

      return acc;
    }, [] as DomainData[]);
  }, [domains]);

  const isKeywordActive = useCallback(
    (variablePath: string, keyword: string): boolean => {
      return get([variablePath, 'keywords', keyword, 'active'], activeKeywordFilters) ?? false;
    },
    [activeKeywordFilters]
  );

  const isAttributeActive = useCallback(
    (filterObject: TActiveAttributeFilters | TActiveScreeningTagFilters, key: string): boolean =>
      isActive<TActiveAttributeFilters | TActiveScreeningTagFilters>(filterObject, key),
    []
  );

  const toggleFlag = useCurrCallback(
    (flagName, _evt) => {
      setState((state) => ({
        ...state,
        [flagName]: !state[flagName],
      }));
    },
    [setState]
  );

  const handleToggleKeyword = useCurrCallback(
    (variablePath: string, toggleData: { keyword: string; active: boolean; type: string }) => {
      toggleKeyword(variablePath, toggleData);
    },
    [toggleKeyword]
  );

  const handleToggleActiveAttribute = useCurrCallback(
    (key: string, attribute: string, label: string, active: boolean) => {
      toggleAttribute({ key, attribute, label, active });
    },
    [toggleAttribute]
  );

  const handleToggleActiveScreeningTag = useCallback(
    (id: string, active: boolean) => {
      toggleScreeningTag({ id, active });
    },
    [toggleScreeningTag]
  );

  const handleSetYearsFilter = useCallback(
    (value: NumberRange) => {
      const [min, max] = value;
      setYearsFilter(min, max);
      setYearsRangeFilterState({ min, max });
    },
    [setYearsFilter, setYearsRangeFilterState]
  );

  const handleSetYearsFilterValue = useCallback(
    (evt) => {
      setYearsRangeFilterState((state: IYearsRangeFilterState) => ({
        ...state,
        [evt.target.name]: toSafeInteger(evt.target.value),
      }));
    },
    [setYearsRangeFilterState]
  );

  const handleKeywordSearchOperatorChange = (
    variablePath: string,
    searchOperator: TSearchOperator
  ) => {
    const updatedSearchOperator =
      searchOperator === get([variablePath, 'searchOperator'], activeKeywordFilters)
        ? oppositeOperator[searchOperator]
        : searchOperator;
    changeKeywordSearchOperator(variablePath, updatedSearchOperator);
  };

  return (
    <div className="flex flex-col overflow-auto h-full bg-gray-500" css={fancyScrollCss}>
      <div className="flex flex-col bg-white px-4 py-2">
        <span className="text-xl">
          <Trans>Search</Trans>
        </span>
        <ReferencesSearchWidget className="w-full" />
      </div>
      <div>
        <div className="border flex flex-no-wrap justify-between items-center pl-4 bg-white">
          <span className="text-xl">
            <Trans>Status</Trans>
          </span>
          <Button
            minimal
            large
            icon={showStatus ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
            onClick={toggleFlag('showStatus')}
          />
        </div>
        <Collapse isOpen={showStatus}>
          <div className="px-4 py-2">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>Decision code</Trans>
                </span>
              </div>
              <div className="flex p-3 flex-wrap">
                {[StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(
                  stageType
                ) && (
                  <AttributeFiltersTag
                    onToggle={handleToggleActiveAttribute('activeDecisionCodeFilters')}
                    attribute={'in'}
                    label={i18n._(t`In`)}
                    active={isAttributeActive(activeDecisionCodeFilters, 'in')}
                  />
                )}
                {stageType === StageType.PreliminaryScreening ? (
                  reasonCodesData.preliminary.map(({ id, code, label }) => (
                    <AttributeFiltersTag
                      key={id}
                      onToggle={handleToggleActiveAttribute('activeDecisionCodeFilters')}
                      attribute={id}
                      label={code}
                      htmlTitle={label}
                      active={isAttributeActive(activeDecisionCodeFilters, id)}
                    />
                  ))
                ) : stageType === StageType.TitlesAbstractScreening ? (
                  reasonCodesData.titlesAbstracts.map(({ id, code, label }) => (
                    <AttributeFiltersTag
                      key={id}
                      onToggle={handleToggleActiveAttribute('activeDecisionCodeFilters')}
                      attribute={id}
                      label={code}
                      htmlTitle={label}
                      active={isAttributeActive(activeDecisionCodeFilters, id)}
                    />
                  ))
                ) : stageType === StageType.FullTextScreening ? (
                  <Fragment>
                    {reasonCodesData.fullText.inclusion.map(({ id, code, label }) => (
                      <AttributeFiltersTag
                        key={id}
                        onToggle={handleToggleActiveAttribute('activeDecisionCodeFilters')}
                        attribute={id}
                        label={code}
                        htmlTitle={label}
                        active={isAttributeActive(activeDecisionCodeFilters, id)}
                      />
                    ))}
                    {reasonCodesData.fullText.exclusion.map(({ id, code, label }) => (
                      <AttributeFiltersTag
                        key={id}
                        onToggle={handleToggleActiveAttribute('activeDecisionCodeFilters')}
                        attribute={id}
                        label={code}
                        htmlTitle={label}
                        active={isAttributeActive(activeDecisionCodeFilters, id)}
                      />
                    ))}
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div>
        <div className="border flex flex-no-wrap justify-between items-center pl-4 bg-white">
          <span className="text-xl">
            <Trans>Attributes</Trans>
          </span>
          <Button
            minimal
            large
            icon={showAttributes ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
            onClick={toggleFlag('showAttributes')}
          />
        </div>
        <Collapse isOpen={showAttributes}>
          {/* attribute below (document type) commented out (for now) as mentioned in T4944 */}
          {/* <div className="px-4 py-2">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>Document Type</Trans>
                </span>
              </div>
              <div className="flex p-3">
                {documentTypes.map((documentType) => (
                  <AttributeFiltersTag
                    key={documentType}
                    onToggle={handleToggleActiveAttribute('activeDocumentTypeFilters')}
                    attribute={documentType}
                    label={documentType ?? 'empty'}
                    active={isAttributeActive(activeDocumentTypeFilters, documentType)}
                  />
                ))}
              </div>
            </div>
          </div> */}
          <div className="px-4 py-2" data-testid="year-filter-card">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>Year</Trans>
                </span>
              </div>
              <div className="flex flex-col p-3">
                <div className="flex flex-row justify-between">
                  <InputGroup
                    css={rangeFilterInputCss}
                    name="min"
                    value={yearsRangeFilterState.min.toString()}
                    onChange={handleSetYearsFilterValue}
                    onBlur={() => handleSetYearsFilter(yearsRangeFilterValue)}
                  />
                  <InputGroup
                    css={rangeFilterInputCss}
                    name="max"
                    value={yearsRangeFilterState.max.toString()}
                    onChange={handleSetYearsFilterValue}
                    onBlur={() => handleSetYearsFilter(yearsRangeFilterValue)}
                  />
                </div>
                <RangeSlider
                  className="my-3"
                  min={yearsFilterData.min}
                  max={yearsFilterData.max}
                  stepSize={1}
                  labelStepSize={20}
                  value={yearsRangeFilterValue}
                  onChange={(value: NumberRange) =>
                    setYearsRangeFilterState({ min: value[0], max: value[1] })
                  }
                  onRelease={() => handleSetYearsFilter(yearsRangeFilterValue)}
                  labelRenderer={false}
                  vertical={false}
                />
              </div>
            </div>
          </div>
          {!isEmpty(screeningTags) && (
            <div className="px-4 py-2" data-testid="tags-filter-card">
              <div className="rounded border bg-white">
                <div className="border-b p-3">
                  <span className="font-bold">
                    <Trans>Structured comments</Trans>
                  </span>
                </div>
                <div className="flex p-3 flex-wrap">
                  {screeningTags.map(({ id, tag }) => (
                    <ScreeningTagFiltersTag
                      key={id}
                      onToggle={handleToggleActiveScreeningTag}
                      attribute={id}
                      label={tag}
                      active={isAttributeActive(activeScreeningTagFilters, id)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </Collapse>
      </div>
      {!isEmpty(domainsWithKeywords) && (
        <div>
          <div className="border flex flex-no-wrap justify-between items-center pl-4 bg-white">
            <span className="text-xl">
              <Trans>Keywords</Trans>
            </span>
            <Button
              minimal
              large
              icon={showKeywords ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
              onClick={toggleFlag('showKeywords')}
            />
          </div>
          <Collapse isOpen={showKeywords}>
            {domainsWithKeywords.map((domain) => {
              const { id: domainId, variables, name } = domain;
              return variables.map(({ id: variableId, title, keywords }) => {
                const variablePath = getDomainVariablePath(domainId, variableId);
                const { included, excluded } = keywords!;
                const allKeywords = [
                  ...included.map((keyword) => ({ type: 'included' as TKeywordType, keyword })),
                  ...excluded.map((keyword) => ({ type: 'excluded' as TKeywordType, keyword })),
                ];
                const searchOperator = activeKeywordFilters[variablePath]?.searchOperator ?? 'or';
                return (
                  <div className="px-4 py-2" key={variablePath} data-testid="keywords-filter-card">
                    <div key={variableId} className="rounded border bg-white">
                      <div className="border-b p-3 flex justify-between">
                        <span>
                          <span className="font-bold">{name}</span>
                          {title && ` > ${title}`}
                        </span>
                        <div className="w-full" css={domainSearchOperatorsCss}>
                          {searchOperatorTags.map((searchOperatorTag) => (
                            <SearchOperatorTag
                              key={searchOperatorTag}
                              searchOperator={searchOperatorTag}
                              variablePath={variablePath}
                              active={searchOperator === searchOperatorTag}
                              onToggle={handleKeywordSearchOperatorChange}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-wrap p-3">
                        {allKeywords.map(({ keyword, type }) => (
                          <KeywordFiltersTag
                            key={keyword}
                            type={type}
                            onToggle={handleToggleKeyword(variablePath)}
                            keyword={keyword}
                            active={isKeywordActive(variablePath, keyword)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                );
              });
            })}
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default ScreeningFilters;
