/** @jsx jsx */
import { Button, Card, Checkbox, Divider, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { isEmpty, map, pick, some, propEq, reject } from 'lodash/fp';
import { FC, useCallback, useState } from 'react';
import { isAdminUser } from '../../lib/task_helpers';
import AppToaster from '../../lib/toaster';
import { useCurrCallback, useI18n } from '../../lib/utils';
import OrganizationMembersList, { TUserData } from './organization_members_list';

export type TInvitationUserData = Pick<TUserData, 'id' | 'role' | 'hasAccessToAllProjects'>;

interface IOrganizationUsersProps {
  onInvite: (usersInvitationData: TInvitationUserData[]) => Promise<any>;
  usersToOmit: string[];
  addingMembers?: boolean;
  isProjectCompleted: boolean;
}

const OrganizationUsersChecklist: FC<IOrganizationUsersProps> = ({
  onInvite,
  usersToOmit,
  addingMembers,
  isProjectCompleted,
}) => {
  const [checkedUsers, setCheckedUsers] = useState<TUserData[]>([]);

  const i18n = useI18n();

  const updateCheckedUsers = useCurrCallback(
    (user: TUserData, _evt) => {
      setCheckedUsers((checkedUsers) =>
        some(propEq('id', user.id), checkedUsers)
          ? reject(propEq('id', user.id), checkedUsers)
          : [...checkedUsers, user]
      );
    },
    [setCheckedUsers]
  );

  const handleInvite = useCurrCallback(
    (users: TUserData[], _evt) => {
      onInvite(
        map<TUserData, TInvitationUserData>(pick(['id', 'role', 'hasAccessToAllProjects']), users)
      )
        .then(() => {
          AppToaster.show({
            icon: IconNames.SAVED,
            intent: Intent.SUCCESS,
            message: i18n._(t`Team members added successfully.`),
          });
          setCheckedUsers([]);
        })
        .catch(() =>
          AppToaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: i18n._(t`Could not add team members.`),
          })
        );
    },
    [onInvite]
  );

  const renderUser = useCallback(
    (user) => {
      return (
        <Card key={user.id} className="p-0 my-2 mx-1">
          <Checkbox
            onChange={updateCheckedUsers(user)}
            checked={some(propEq('id', user.id), checkedUsers)}
            className="mx-2 py-3 flex flex-row items-center"
          >
            <span className="flex flex-grow">{user.name}</span>
            {isAdminUser(user) && <Icon icon={IconNames.CROWN} />}
          </Checkbox>
        </Card>
      );
    },
    [checkedUsers, updateCheckedUsers]
  );

  return (
    <div className="flex flex-col overflow-hidden h-full">
      <OrganizationMembersList usersToOmit={usersToOmit} userRenderer={renderUser} />
      <Divider className="m-0" />
      <div className="text-right p-3">
        <Button
          intent={Intent.SUCCESS}
          disabled={isEmpty(checkedUsers) || isProjectCompleted}
          onClick={handleInvite(checkedUsers)}
          loading={addingMembers}
        >
          <Trans>Invite</Trans>
        </Button>
      </div>
    </div>
  );
};

export default OrganizationUsersChecklist;
